import {useState, useEffect} from 'react'

import ResourcesGrid from '../../molecules/ResourcesGrid'
// import NewSearch from '../../components/NewSearch'
import FilterOptions from '../../molecules/FilterOptions';

// import bg_img from '../../assets/img/bg_img.png'
// import chef_header from '../../assets/img/chef_header.png'
import { FilterType } from '../../data/types';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { capitalizeWords } from '../../utils/helpers'
// import { USStatesEnum } from '../../utils/enums'
import { useResource } from '../../contexts/appcontext';
import { useLoadResourceOptions } from '../../hooks/apphooks'





const ResourceOptions = () => {

  useEffect(() => {
    document.title = `HomeCook | Resource Pantry`
  }, [])
  const { globalFilter } = useResource()
  const [filterSetting, setFilterSetting] = useState<boolean>(false)
  // const [searchTerm, changeSearchTerm] = useState<string>('')
  
  const [currentFilter, setCurrentFilter] = useState<FilterType>(globalFilter)

  // const toggleFilterSetting = () => {
  //   setFilterSetting(prev => !prev)
  // }

  


  var { allOptions } = useLoadResourceOptions()

  // if (searchTerm){
  //   const { searchedOptions } = searchOptions(searchTerm)
  //   allOptions = searchedOptions
  // }

  return (
    <>
    {
      (filterSetting) && (
        <div className={`flex font-open-sans h-screen w-11/12 sm:w-3/12 mr-auto overflow-y-scroll fixed top-0 justify-center from-zinc-200 pb-6 pt-8 backdrop-blur-2xl bg-white/90`}>
          <FilterOptions currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} closeFilterSetting={() => setFilterSetting(false)} />
        </div>)
    }
    <div className={`${(filterSetting) ? 'w-full right-0' : 'w-full left-0'} flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-48 md:pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
      {/* <img alt="howto"  className='w-full h-[1480px] xs:h-[2048px] sm:h-[1048px] object-cover absolute opacity-40' src={bg_img} /> */}
      <div className={`flex flex-col w-11/12 sm:w-10/12 h-[2348px] sm:h-full relative items-start py-6 ${(filterSetting) ? 'invisible sm:visible' : ''} bg-white`}>
          <p className='my-4 mx-auto text-center sm:text-[44px] text-xl font-bold font-raleway mt-8 w-auto'>
            <span className='text-primaryDark-300 '>Explore Our </span>
            <span className='text-primaryDark-200/90 '>Culinary Resources</span>
          </p>
          <p className='mx-auto text-[#000000] text-lg text-center sm:w-1/3 sm:text-[16px] text-medium my-2'>
            Find valuable resources and guidance tailored to support your food business journey.
          </p>
   
          <div className="flex flex-col mx-auto">
              <ResourcesGrid resourcesOptions={allOptions} />
          </div>
      </div>
    </div>
    </>
  )
}


export default ResourceOptions