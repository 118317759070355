import React, {ReactNode, useState, useContext, createContext} from 'react'
// import { getAllCommunities,  getCommunityByID, searchCommunities, generateAIResponse } from '../api/crud'
// import { useAuthState } from './membercontext'
// import { useAuthState } from './authcontext'

import { AuthSchema, ResourceStateType, ResourceSchema, FilterType} from "../data/types";

const defaultUSState = ''

const defaultResourceContext = {
  globalFilter: {state: defaultUSState}
}
const ResourceContext = createContext(defaultResourceContext);


const ResourceProvider: React.FC<{children: ReactNode, authUser: AuthSchema}> = props => {
    const [allResources, updateResources] = useState<ResourceSchema[]>([])
    const [currentResource, updateCurrentResource] = useState<ResourceSchema>({})
    const [globalFilter, setGlobalFilter] = useState<FilterType>({state: defaultUSState})

    const [searchQuery, setSearchQuery] = useState("");
    const [nameSort, setNameSort] = useState(false);
    const [idSort, setIDSort] = useState(false);
  

    const handleSearch = (value: string) => {
      setSearchQuery(value);
    };
    const toggleNameSort = () => {
      setNameSort((prevSort) => !prevSort);
    };
  
    const toggleIDSort = () => {
      setIDSort((prevSort) => !prevSort);
    };
  
  
    const clearCurrentResource = () => {
        updateCurrentResource({})
    }
  
  
    let data = { 
      idSort,
      nameSort,
      allResources,
      currentResource,
      searchQuery, 
      globalFilter,
      setGlobalFilter,
      clearCurrentResource, 
      updateResources,
      updateCurrentResource,
      handleSearch,
      toggleNameSort, 
      toggleIDSort
    }
    return <ResourceContext.Provider value={data} {...props} />
}

const useResource = () => {
    const context: ResourceStateType = useContext(ResourceContext)
    if (context === undefined ){
        throw new Error(`useResource must be used within ResourceProvider`)
    }
    return context
}

export { ResourceProvider, useResource }
