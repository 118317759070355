import {useEffect, useState} from 'react'
import {  ResourceBookingSchema, SavedResourceSchema } from '../../data/types';
// import { ReactComponent as OrderIcon } from '../../icons/OrderIcon.svg'
import SavedResourceCard from '../../components/SavedResourceCard';
import BookedResourceCard from '../../components/BookedResourceCard';
import { useCompleteSignIn, useLoadBookings, useUpdateAccountDetails } from '../../hooks/authhooks'
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';



const SavedResourceListing = () => {
const { user } = useCompleteSignIn()
const navigate = useNavigate()
const allSavedResources: SavedResourceSchema[] = (user && user?.saved_resources) ? user.saved_resources : []

  // useEffect(() => {
  //   document.title = `HomeCook | Saved Resources Listing`
  // })

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `HomeCook | Saved Resources Listing`
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1024;

  const loggedin_username = user?.username ? user?.username : ""
  const { removeResource } = useUpdateAccountDetails(loggedin_username)

  const handleRemoveSavedResource = (resource_id: string | undefined) => {
    if (resource_id){
      removeResource(resource_id)
    }
  }

  var { bookings } = useLoadBookings(loggedin_username)
  bookings = bookings ? bookings : []

  const savedResourcePageOptions = [
    {
      id: "saved",
      name: `Saved (${allSavedResources.length})`
    },
    {
      id: "booked",
      name: `Bookings and Reservations (${bookings.length})`
    }
  ]

  const [currentOption, setCurrentOption] = useState("saved")

  if (isMobile){
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-col w-11/12 sm:w-10/12 h-${(allSavedResources && allSavedResources.length) ? `[1348px]`: `full`} overflow-y-scroll relative items-start py-6 bg-white`}>
          <div className="flex flex-col mx-auto sm:h-screen w-11/12 bg-[#FFFFFF] rounded-xl  mt-24">
          <h1 className='font-bold text-xl pt-10'>Saved Resources</h1>
  
              {
                (
                  allSavedResources && allSavedResources.length > 0) ? allSavedResources.map((resource: SavedResourceSchema, i: number) => (<SavedResourceCard key={i} isMobile={isMobile} resource={resource} handleRemoveSavedResource={handleRemoveSavedResource} />)
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No saved resources to show
                  </div>
                )
              }
          </div>
        </div>
      </div>
      </>
    )
  } else {
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-col w-11/12 sm:w-10/12 h-[2348px] sm:h-full relative items-start py-6 bg-white`}>
          <div className="flex flex-col mx-auto sm:h-screen sm:w-[1200px] bg-[#FFFFFF] rounded-xl  mt-10">

          <div className='flex flex-row w-full'>
            <h1 className='font-bold text-xl pt-10 pl-10 mr-auto'>Saved & Booked Resources</h1>
            <h1 className='font-bold text-[16px] pt-10 pl-10 ml-auto text-[#8A8A8A] cursor-pointer' onClick={()=>navigate(-1)}><ArrowBackIos  /> Go Back</h1>
          </div>

          <div className='flex flex-row m-[24px] rounded-lg border-[1px] border-[#E9E9E9] h-[67px]'>  

            {
              savedResourcePageOptions.map(
                (option, i) => (
                  <div 
                    key={i} 
                    className={`${(currentOption === option.id) ? 'border-0 bg-primaryDark-0 text-white' : 'cursor-pointer border-[1px] text-[#4D4D4D] border-[#E9E9E9]'}  p-2 my-auto mx-6 rounded-lg`}
                    onClick={()=> setCurrentOption(option.id)}
                  >
                    {option.name}
                  </div>
                )
              )
            }

          </div>

              {
                (
                  allSavedResources && allSavedResources.length > 0 && currentOption === "saved") ? allSavedResources.map((resource: SavedResourceSchema, i: number) => (
                    <SavedResourceCard 
                      key={i} 
                      isMobile={isMobile} 
                      resource={resource} 
                      handleRemoveSavedResource={handleRemoveSavedResource} 
                    />
                  )
                ) : (
                  (currentOption === "saved") && (<div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No {currentOption} resources to show
                  </div>)
                )
              }


              {
                (
                  bookings && bookings.length > 0 && currentOption === "booked") ? bookings.map((booking: ResourceBookingSchema, i: number) => (
                    <BookedResourceCard 
                      key={i} 
                      isMobile={isMobile} 
                      booking={booking} 
                      // handleRemoveSavedResource={handleRemoveSavedResource} 
                    />
                  )
                ) : (
                  (currentOption === "booked") && (<div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No {currentOption} resources to show
                  </div>)
                )
              }
          </div>
        </div>
      </div>
      </>
    )
  }
}


export default SavedResourceListing