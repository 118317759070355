import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import PaymentForm from './CheckOut'
import config from '../config';

const stripePublishableKey = config?.stripePublishableKey
const stripePromise = loadStripe(stripePublishableKey ? stripePublishableKey : "");

type StripePaymentCheckOutProps = {
    amount?: number
    currency: 'usd' | 'cad' | 'eur' | 'gbp'
    mode: 'setup' | 'payment' | 'subscription'
    setup_future_usage: 'off_session' | 'on_session'
    isAddPayMethod?: boolean
    hasExistingPayMethod?: boolean
    username: string,
    setIsAddingPayMethod: React.Dispatch<React.SetStateAction<boolean>>
}

const StripePaymentCheckOut: React.FC<StripePaymentCheckOutProps> = ({username, mode, amount, currency, isAddPayMethod, setIsAddingPayMethod}) => {
    amount = amount ? amount * 100 : amount // convert $ to cents
    return (
        <Elements stripe={stripePromise} options={{ mode, amount, currency }}>
            <PaymentForm username={username} isAddPayMethod={isAddPayMethod} setIsAddingPayMethod={setIsAddingPayMethod} />
        </Elements>
    )
}

export default StripePaymentCheckOut;