import { useMutation, useQuery } from '@tanstack/react-query'
import { 
    generateAIResponse,
    getAllMessages,
    // getAllKitchens, getKitchenID, 
    getAllResources,
    getResourceByID,
    sendMessageToUser,
    // getCommunityBookings,
    // addKitchen, updateKitchen, updateMembershipPlans, 
    // getMembershipPlan, 
    // deleteMembershipRequirement, addMembershipRequirement, 
    // addMembershipPlans, 
    // getMembershipPlans, 
    // getMembershipRequirements, 
} from '../api/crud'

import { ReactComponent as DocumentationIcon } from '../icons/documentationIcon.svg'
// import { ReactComponent as PreferenceIcon } from '../icons/PreferenceIcon.svg'
import { ReactComponent as FundingIcon } from '../icons/fundingIcon.svg'
import { ReactComponent as KitchenIcon } from '../icons/KitchenIcon.svg'
// import { ReactComponent as NotificationIcon } from '../icons/NotificationIcon.svg'
// import { ReactComponent as AccountIcon } from '../icons/Account.svg'
import { ReactComponent as ExpertIcon } from '../icons/expertIcon.svg'
import { ReactComponent as CredentialIcon } from '../icons/credentialIcon.svg'
import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'

import { ReactComponent as CommunityIcon } from '../icons/Community.svg'

import { ResourceSchema, ResourceType, SettingsOptionsType } from '../data/types'
import { useResource } from '../contexts/appcontext'
// import { useAuthState } from '../contexts/authcontext'
// import { useMessageState } from '../contexts/messagecontext'


const staleTime = 1000
const isEnabled = true

export const resourcesPerPage: number = 8


export const useLoadResourceOptions = () => {
    const {data: allOptions, refetch: reloadOptions,  isLoading: isLoadingOptions} = useQuery({ 
        queryKey: ['options'], 
        queryFn: async () => {
            console.log("fetching resource options")
            const resourcesOptions: SettingsOptionsType[] = [
                {name: "Local Kitchen Spaces", description: "Discover kitchen communities and food incubators in your area.", icon: KitchenIcon, path: "kitchen", tags: []},
                {name: "Required Documentation & Compliance ", description: "Access essential legal documents and compliance guides", icon: DocumentationIcon, path: "documentation", tags: ["servsafe"]},
                {name: "Culinary Training", description: "Enhance your skills with top culinary training programs.", icon: CredentialIcon, path: "training", tags: ["servsafe"]},
                {name: "Funding & Financial Resources", description: "Discover financial options and resources for your business.", icon: FundingIcon, path: "funding", tags: []},
                {name: "Industry Mentors & Experts", description: "Discover organizations that offer mentorship and industry insights.", icon: ExpertIcon, path: "expert", tags: []},
                {name: "Local Fresh Food", description: "Source fresh, local ingredients for your culinary creations.", icon: OrderIcon, path: "farm", tags: []}
              ]
            return resourcesOptions 
        },
        staleTime,
        enabled: isEnabled
    })

    const searchOptions = (searchTerm: string) => {
        const searchedOptions =  allOptions ? allOptions.filter((option: SettingsOptionsType) => (option.name?.toLowerCase().includes(searchTerm.toLowerCase()) || option.tags?.join(" ")?.toLowerCase()?.includes(searchTerm.toLowerCase()))) : []
        // const searchedOptions = new Set(searchedResources.map((resource: ResourceSchema) => resource.resource_type));
        return {searchedOptions, isLoadingOptions, reloadOptions}
    }

    const getResourceIcon = (resourceType: ResourceType | undefined) => {
        switch(resourceType){
            case 'kitchen':
                return KitchenIcon
            case 'documentation':
                return DocumentationIcon
            case 'training':
                return CredentialIcon
            case 'funding':
                return FundingIcon
            case 'expert':
                return ExpertIcon
            case 'farm':
                return OrderIcon
            default:
                return CommunityIcon
        }
    }
    return {allOptions: allOptions ? allOptions : [], isLoadingOptions, reloadOptions, searchOptions, getResourceIcon}
}

export const useLoadMessages = (username: string, currentChatCommunity: 'chatbot' | 'staff') => {

    const {data: messages, refetch: reloadMessages, isLoading: isLoadingMessages} = useQuery({ 
        queryKey: ['messages', username], 
        queryFn: async () => {
            console.log("fetching messages")
            const res = await getAllMessages(username, currentChatCommunity)
            return res
        },
        staleTime,
        enabled: isEnabled
    })

    const switchMember = async (member: string) => {
        if (member !== currentChatCommunity){
            // await changeChatCommunity(member)
            reloadMessages()
        }
      
    }
    return {isLoadingMessages, messages, switchMember, reloadMessages}
}



export const useSendMessage = (username: string, currentChatCommunity: 'chatbot' | 'staff') => {
    var { reloadMessages } = useLoadMessages(username, currentChatCommunity)

    const mutation = useMutation({ 
        mutationFn: async (content: string) => {
            console.log("send message") 
            if (currentChatCommunity === "chatbot"){
                const res = await generateAIResponse(username, content) 
                await reloadMessages()
                return res
            } else {
                const res = await sendMessageToUser(username, currentChatCommunity, content) 
                await reloadMessages()
                return res
            }
    }})

    const sendMessage = (content: string) => {
        mutation.mutate(content)
        return mutation.isSuccess
    }

    // const getAIMessage = (username: string, content: string) => {
    //     mutation.mutate({username, content})
    //     return true
    // }
    const isLoadingMessage = mutation.isPending
    return { sendMessage, isLoadingMessage }
}

export const useLoadResources = (resource_type?: ResourceType) => {
    const { updateResources } = useResource()
    const {data: allResources, refetch: reloadResources,  isLoading: isLoadingResources} = useQuery({ 
        queryKey: ['resources'], 
        queryFn: async () => {
            console.log("fetching resources")
            var res = await getAllResources(resource_type)
            if (res && updateResources!== undefined){
                updateResources(res)
                return res
            } 
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    return {allResources, isLoadingResources, reloadResources}
}

export const useUpdateResources = (searchTerm: string)=>{
    const { updateResources } = useResource()
    const {searchOptions} = useLoadResourceOptions()

    if (searchTerm && updateResources!== undefined){
        const { searchedOptions } = searchOptions(searchTerm);
        updateResources(searchedOptions)
    } 
}


export const useLoadResourcesFilter =  (resourceType: ResourceType, filterState: string) => {
    // const { updateResources } = useResource()
    const {data: allResources, refetch: reloadResources,  isLoading: isLoadingResources} = useQuery({ 
        queryKey: ['resources'], 
        queryFn: async () => {
            console.log("fetching filtered resources")
            const res = await getAllResources(resourceType)
            // console.log("res", res)
            return res ? res : []
        },
        staleTime,
        enabled: isEnabled
    })
    const filteredResources = allResources ?  allResources.filter(resource => resource?.state?.toLowerCase() === filterState.toLowerCase() || resource?.state === "" || filterState === "") : []


    const  filterResourcesBySearchPhrase = (
        items: ResourceSchema[],
        searchPhrase: string,
        attributes: (keyof ResourceSchema)[]
    ) => {

        const lowerSearchPhrase = searchPhrase.toLowerCase();
        return items.filter(item =>
          attributes.some(attr => {
            const value = item[attr];
            return (value && typeof value === 'string') && 
                   value.toLowerCase().includes(lowerSearchPhrase);
                //    new RegExp(lowerSearchPhrase.toLowerCase()).test(value.toLowerCase());
                //    value.indexOf(lowerSearchPhrase) !== -1;
          })
        );
    }

    const searchResources = (searchTerm: string) => {
        const searchAttributes: (keyof ResourceSchema)[] = ["name", "description", "location", "zipcode"]
        const searchedResources = filterResourcesBySearchPhrase(filteredResources, searchTerm, searchAttributes)
        return {searchedResources, isLoadingResources, reloadResources}
    }

    const getPage = (resourcesList: ResourceSchema[], pageNumber:  number) => {
        var numAllResources = resourcesList?.length ? resourcesList.length : 0
        var startIndex = 0
        var endIndex = numAllResources <= resourcesPerPage ? numAllResources : resourcesPerPage
        var numResources = numAllResources
        var numPages = Math.ceil(numAllResources / resourcesPerPage)
        // var paginatedResources = filteredResources.slice(startIndex, endIndex)

        if (resourcesList && pageNumber && pageNumber > 0 && pageNumber <= numPages){
            startIndex = (pageNumber-1) * resourcesPerPage
            endIndex = (numPages === pageNumber) ? numAllResources : (pageNumber * resourcesPerPage)
        }
        var paginatedResources = resourcesList.slice(startIndex, endIndex)
        numResources = paginatedResources?.length ? paginatedResources.length : 0
        return {paginatedResources, numAllResources, numResources, startIndex, endIndex}
    }
    return {filteredResources, isLoadingResources, getPage, reloadResources, searchResources}
}


export const useLoadCurrentResource = (resourceId: string) => {
    let { updateCurrentResource  } = useResource()
    const {data: currentResource, refetch: reloadCurrentResource, isLoading: isLoadingCurrentResource} = useQuery({ 
        queryKey: ['resource', resourceId], 
        queryFn: async () => {
            console.log("fetching resource by id")
            var res = await getResourceByID(resourceId ? resourceId : "")
            if (res && updateCurrentResource!== undefined){
                updateCurrentResource(res)
            }
            return res
        },
        staleTime,
        enabled: isEnabled
    })
    
    return { isLoadingCurrentResource, currentResource, reloadCurrentResource}
}

// export const useFeedPosts = () => {
//     const { data: feedPosts, isLoading, isError, refetch:reloadFeedPosts } = useQuery({
//         queryKey: ['feedPosts'], 
//         queryFn: async () => {
//             console.log("Getting feed posts");
//             const response = await getFeedPosts(); 
//             return response;
//         },
//         staleTime,
//         enabled: isEnabled
//     });
//     return { feedPosts, isLoading, isError, reloadFeedPosts };
// }


// export const usePaginatedResources = (resourcesList: ResourceSchema[], pageNumber: number = 1) => {
//     // // const resourcesPerPage: number = 4
//     // const fetchPaginatedData = async (page: number) => {
//     //     // return resourcesList.slice(0,8)
//     //     // return resourcesList.slice(8,16)
//     //     // return resourcesList.slice(16,24)
//     //     if (resourcesList && page && page > 0 && page <= (resourcesList.length / resourcesPerPage)){
//     //         return resourcesList.slice((page-1) * resourcesPerPage, page * resourcesPerPage)
//     //     } 
//     //     return resourcesList
//     // };

//     // const {data: pageResources, refetch: reloadPageResources,  isPending: isLoadingResources} = useQuery({
//     //     queryKey: ['resources', pageNumber],
//     //     queryFn: () => fetchPaginatedData(pageNumber),
//     //     placeholderData: keepPreviousData,
//     // })


//     // const getPage = ()

//     const {data: pageResources, refetch: reloadPageResources} = useQuery({
//         queryKey: ['resources', pageNumber],
//         queryFn: () => {
//             let numResources = resourcesList?.length ? resourcesList.length : 0
//             let numPages = Math.ceil(numResources / resourcesPerPage)
//             if (resourcesList && pageNumber && pageNumber > 0 && pageNumber <= numPages){
//                 let startIndex = (pageNumber-1) * resourcesPerPage
//                 let endIndex = (numPages == pageNumber) ? (numResources - 1) : (pageNumber * resourcesPerPage)
//                 resourcesList =  resourcesList.slice(startIndex, endIndex)
//             } 
//             return resourcesList
//         },
//         placeholderData: keepPreviousData,
//     })

//     return {pageResources, reloadPageResources}
// }