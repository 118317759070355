import React from 'react'


import {useEffect, useState} from 'react'
import { OrderItemSchema, SavedResourceSchema } from '../../data/types';
// import { ReactComponent as OrderIcon } from '../../icons/OrderIcon.svg'
// import SavedResourceCard from '../../components/SavedResourceCard';
import { useCompleteSignIn } from '../../hooks/authhooks'
// import OrderItemCard from '../../components/OrderItemCard';
import CartItemCard, { EditCartItem } from '../../components/CartItemCard';



const Cart = () => {
  const { user,  cartItems, updateCart } = useCompleteSignIn()
  const allSavedResources: SavedResourceSchema[] = (user && user?.saved_resources) ? user.saved_resources : []

  const [width, setWidth] = useState(window.innerWidth);
  const [isEditingQty, setIsEditingQty] = useState<string>()

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `HomeCook | Cart`
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1024;

  // const loggedin_username = user?.username ? user?.username : ""
  // const { removeResource } = useUpdateAccountDetails(loggedin_username)

  // const handleRemoveSavedResource = (resource_id: string | undefined) => {
  //   if (resource_id){
  //     removeResource(resource_id)
  //   }
  // }

//   const handleAddToCart = (inventoryItem: any, quantity: number = 1)=> {
//     if (inventoryItem && quantity){
//       const orderItem = inventoryItem as OrderItemSchema
//       orderItem.total_quantity = quantity
//       orderItem.inventory_id = inventoryItem.id
//       updateCartItems(prev => ([
//         ...prev,
//         orderItem
//       ]))
//     }
//   }


//   const handleUpdateCartItem = (item: OrderItemSchema, quantity: number = 0)=> {
//     var cartList = [...cartItems]
//     const itemIndex = cartItems.findIndex(i=>i.item_name === item.item_name)
//     if (itemIndex >= 0 && quantity !== item.total_quantity){
//       item.total_quantity = quantity
//       if (quantity === 0){
//         cartList = cartList.filter(i=> i.item_name !== item.item_name)
//       } else {
//         cartList[itemIndex] = item
//       }
//       updateCartItems(cartList)
//     }
//   }

  const handleUpdateCartItem = (item: OrderItemSchema, quantity: number = 0)=> {
    updateCart(item, quantity)
  }


  const cartTotalCost = cartItems.reduce((sum, item) => sum + ((item?.unit_price ? item?.unit_price : 0) * item.total_quantity), 0);


  if (isMobile){
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-col w-11/12 sm:w-10/12 h-${(allSavedResources && allSavedResources.length) ? `[1348px]`: `full`} overflow-y-scroll relative items-start py-6 bg-white`}>
          <div className="flex flex-col mx-auto sm:h-screen w-11/12 bg-[#FFFFFF] rounded-xl  mt-24">
          <h1 className='font-bold text-xl pt-10'>Cart</h1>
  
              {
                (
                    cartItems && cartItems.length > 0) ? cartItems.map((item: any, i: number) => (
                      (
                        (isEditingQty && isEditingQty === item.id) ? (
                          <EditCartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} handleUpdateCartItem={handleUpdateCartItem} />
                        ) : ( 
                          <CartItemCard key={i} index={i} item={item} handleUpdateCartItem={handleUpdateCartItem} />
                        )
                      )
                    )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No cart items to show
                  </div>
                )
              }
          </div>
        </div>
      </div>
      </>
    )
  } else {
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-row w-11/12 sm:w-10/12 h-[2348px] sm:h-full relative items-start py-6 bg-white`}>
          <div className="flex flex-col mx-auto sm:h-screen w-7/12 bg-[#FFFFFF] rounded-xl  mt-10">
              <h1 className='font-semibold text-[18px] text-[#808080] font-raleway mb-1'>Your Cart Items ({(cartItems && cartItems.length) ? cartItems.length : 0})</h1>

              {
                (
                    cartItems && cartItems.length > 0) ? cartItems.map((item: any, i: number) => (
                      (
                        (isEditingQty && isEditingQty === item.id) ? (
                          <EditCartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} handleUpdateCartItem={handleUpdateCartItem} />
                        ) : ( 
                          <CartItemCard key={i} index={i} item={item} handleUpdateCartItem={handleUpdateCartItem} />
                        )
                      )
                    )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No cart items to show
                  </div>
                )
              }
          </div>

          <div className="flex flex-col mx-auto sm:h-screen w-3/12 bg-[#FFFFFF] rounded-xl  mt-10">
              <h1 className='w-full font-semibold text-[18px] text-[#808080] font-raleway'>Cart Summary</h1>

              <div className='flex flex-col w-full h-[140px] text-[14px] font-open-sans border-[#E9E9E9] border-[1px] rounded-md p-2 my-3'>
                <span className='w-11/12 mx-auto flex flex-row'>
                  <p className='text-[#333333] text-[16px]'>
                    Total
                  </p>
                  <p className='text-black font-bold text-[20px] font-raleway ml-auto'>
                    ${cartTotalCost.toFixed(2)}
                  </p>
                </span>
                <button className='bg-primaryDark-0 text-white w-11/12 mx-auto h-[54px] text-[18px] font-raleway font-semibold my-auto'>
                  Checkout
                </button>
              </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}


export default Cart