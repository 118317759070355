import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import {testFirebaseConfig, prodFirebaseConfig} from './firebaseConfig'
import config from '../config';


var appStage = config?.env
const firebaseConfig =  (appStage === "prod") ? prodFirebaseConfig : testFirebaseConfig

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);