import { useState } from "react";
import { ReactComponent as SendIcon } from "../icons/SendIcon.svg";
import { ReactComponent as AttachIcon } from "../icons/AttachIcon.svg";
import { MessageSchema } from "../data/types";
import { useSendMessage, useLoadMessages } from "../hooks/apphooks";

import { useCompleteSignIn } from "../hooks/authhooks";

// Messages view for current user
interface ChatWindowProps {
  currentChatCommunity: 'chatbot' | 'staff'
  username: string
}

// Messages view for current user
const ChatWindow = ({ currentChatCommunity }: ChatWindowProps) => {
  const [currentText, updateCurrentText] = useState("");

  const { user } = useCompleteSignIn();
  const loggedin_username = user && user?.username ? user.username : "";
  
  const {messages} = useLoadMessages(loggedin_username, currentChatCommunity)
  const { sendMessage  } = useSendMessage(loggedin_username, currentChatCommunity)

  const handleSubmit = async () => {
      
      if (currentChatCommunity){
              await sendMessage(currentText)
          // }
          updateCurrentText('')
      }
  }

  return (
    <div className="flex flex-col justify-start align-top w-full  bg-[#fff]  rounded-b-[16px] font-open-sans   ">
      <div className="bg-white  h-[170px] overflow-y-scroll">
        {
            (messages) ? (messages.map((m: MessageSchema, i: number) => {
                return (
                    <div key={i} className={(m?.type === "received") ? "bg-[#555555] text-[#fff] w-[40%] text-[12px] ml-[2%] mr-[55%] p-[2%] m-1 rounded-lg font-thin" : "bg-[#013847] text-[#fff] w-[40%] text-[12px] ml-[58%] p-[2%] m-1 rounded-lg font-thin"}> {m?.content} </div>
                )
            })) : <></>
        }
      </div>


      {/* <Divider /> */}
      <hr className="pb-2 w-full"></hr>
      <div className="flex flex-row h-auto items-center w-full m-2 p-2 ">
        <div className="flex w-[294px] ">
          <textarea
            onChange={(e) => updateCurrentText(e.target.value)}
            value={currentText}
            className="text-[16px] h-[49px] w-full bg-[#FFFFFF] text-gray-700 shadow appearance-none border rounded-lg  py-3 px-3 leading-tight focus:ring-blue-200"
            placeholder="Enter Message..."
          />
        </div>

        <div className="flex flex-row  justify-start gap-2 ml-3">
          <AttachIcon className=" w-[48px] h-[48px] cursor-pointer bg-white border-[1px] p-3 border-gray-400 rounded-[16px] " />

          <SendIcon className=" w-[48px] h-[48px] cursor-pointer bg-primaryDark-0 p-2 rounded-[16px] " onClick={handleSubmit}/>
        </div>
      </div>
    </div>
  )
};

export default ChatWindow;
