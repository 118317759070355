import React from "react";

import moment, { Moment } from "moment";
// import { BookingSchema, ResourceSchema } from "../data/types";
// import { darkenColor, getBookingStatusColor } from "../utils/helpers";


interface TimeBlock {
  start: Moment;
  end: Moment;
}



type TimesViewProps = {
  availableTimeBlocks: TimeBlock[] | undefined,
  selectedDay: Date
  selectedBlock: TimeBlock | undefined
  setSelectedBlock: React.Dispatch<React.SetStateAction<TimeBlock | undefined>>;
  updateEndTime: (startTime: Moment) => void;
  updateStartTime: (startTime: Moment) => void;
}

const TimesView: React.FC<TimesViewProps> = ({availableTimeBlocks, selectedDay,  selectedBlock, setSelectedBlock, updateStartTime, updateEndTime}) => {

  const handleTimeClick = (block: TimeBlock) => {
    setSelectedBlock(block);
    const startTime = moment(selectedDay)
      .clone()
      .hours(block.start.hours())
      .minutes(block.start.minutes());
    const endTime = moment(selectedDay)
      .clone()
      .hours(block.end.hours())
      .minutes(block.end.minutes());
    updateStartTime(startTime);
    updateEndTime(endTime);
  };

  return (
    <div className="w-11/12">

      <p className="text-black font-medium text-left text-[16px] font-raleway mt-3">
      Select an available time for <b>{moment(selectedDay).format("LL")}</b>
      </p>
      <div
        className={`grid sm:grid-cols-2 grid-cols-1 space-y-1 space-x-2 grid-flow-row-dense items-center w-full h-full`}
      >
      {availableTimeBlocks?.map((block, index) => (
        <div
          key={index}
          className={`${
            moment(block.start).isSame(moment(selectedBlock?.start))
              ? " bg-primaryDark-100 text-primaryLight-1000"
              : "text-black hover:bg-primaryDark-400 hover:text-white"
          } font-open-sans text-[16px]  border-primary-400 border-[1px] p-2 rounded-lg hover:cursor-pointer hover:bg-primary-400/60`}
          onClick={() => handleTimeClick(block)}
        >
          {block.start.format("LT")} - {block.end.format("LT")}
          {/* {block === selectedBlock ? (
            <>
              <button
                className={`buttonsPrimary p-2 ml-3 sm:ml-auto m-1 text-primary-200 text-xs h-max`}
                onClick={() => handleConfirmBooking ? handleConfirmBooking() :  null}
              >
                Continue
              </button>
            </>
          ) : (
            <></>
          )} */}
        </div>
      ))}
      </div>
    </div>
  )
}

export default TimesView
