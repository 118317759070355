declare global {
  interface Window {
    pendo: any;
  }
}
  
export const initializePendo = (visitorId: string, accountId: string) => {
  const pendoId: string = `${process.env.REACT_APP_PENDO_ID}`;

  (function(apiKey: any){
      (function(p?: any, e?: any, n?: any, d?: any, o?: any){var v:any,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })(pendoId);

  // Initialize Pendo with visitor and account info
  window.pendo.initialize({
    visitor: {
      id: visitorId, // Required: Unique visitor ID
    },
    account: {
      id: accountId, // Required: Unique account ID
    },
  });
};
  