import { useEffect, useState} from 'react'


import { ReactComponent as VisaCardIcon } from '../../icons/visaCardIcon.svg'
import { ReactComponent as MasterCardIcon } from '../../icons/masterCardIcon.svg'
import { ReactComponent as AmexCardIcon } from '../../icons/amexCardIcon.svg'
import { ReactComponent as DiscoverCardIcon } from '../../icons/discoverCard.svg'
import { ReactComponent as CashappIcon } from '../../icons/cashappIcon.svg'
import { ReactComponent as AmazonPayIcon } from '../../icons/AmazonPayIcon.svg'



import { ReactComponent as AddCircleIcon } from '../../icons/AddCircleIcon.svg'
// import { ReactComponent as EditIcon } from '../../icons/EditIcon.svg'
import DeleteIcon from "@mui/icons-material/Delete";


import { capitalizeString, formatDateString } from '../../utils/helpers'
import { useCompleteSignIn } from '../../hooks/authhooks'

import { ReactComponent as WalletIcon } from "../../icons/WalletIcon.svg";

import { useGetPaymentMethods, useAddPaySetupIntent } from '../../hooks/paymenthooks'
import StripePaymentCheckOut from "../../molecules/PaymentCheckOut"





const Wallet = () => {

  
  // const [isUpdatingPayMethod, setIsUpdatingPayMethod] = useState<boolean>(false)

  const { user } = useCompleteSignIn();
  const loggedin_username = (user && user?.username) ? user.username : ''

  var { paymentMethods } = useGetPaymentMethods(loggedin_username)
  const [isAddingPayMethod, setIsAddingPayMethod] = useState<boolean>((paymentMethods && paymentMethods.length < 1) ? true : false)

  paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : []

  useEffect(() => {
    document.title = `HomeCook | Resource Pantry`
  }, [paymentMethods])

  return (
    <>
    <div className={`'w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
      <div className={`flex flex-col w-11/12 sm:w-10/12 h-[2348px] sm:h-full relative items-start py-6 bg-white`}>
          <div className='w-10/12 mx-auto my-5'>
            <p className='font-raleway text-[20px] text-black font-extrabold my-5'>Wallet</p>
      
            <div className='border-[1px] border-[#E9E9E9] rounded-xl sm:min-h-[520px] my-5 p-10 w-full'>
              
              {
                (isAddingPayMethod && paymentMethods && paymentMethods.length > 0) && (
                  <div 
                    onClick={()=>setIsAddingPayMethod(false)}
                    className='flex flex-row w-full text-gray-300 font-raleway font-bold text-[20px] cursor-pointer'
                  >
                    <p className='ml-auto'>x</p>
                  </div>
                )
              } 
                
              
              {
                (isAddingPayMethod) ? (
                  <div className=''>
                    <p className='text-[18px] font-raleway text-black font-bold'>Add Your Payment Method</p>
                    <p className='text-[14px] font-open-sans text-[#808080] mt-2 mb-4'>
                      Choose your payment method from the available options below.
                    </p>
                    
                    <div className='flex flex-row rounded-md mb-7 sm:h-[40px] w-[90px] bg-primaryDark-0 text-[14px] text-white font-bold font-raleway'>
                      <WalletIcon className='my-auto ml-auto mr-1' />
                      <p className='my-auto mr-auto'>Card</p>
                    </div>

                    <StripePaymentCheckOut username={loggedin_username} mode="setup" currency="usd" setup_future_usage='off_session' isAddPayMethod={isAddingPayMethod} setIsAddingPayMethod={setIsAddingPayMethod} />
                  </div>
                ) : (
                  <div className=''>
                    <p className='text-[18px] font-raleway text-black font-bold'>Your Payment Methods</p>
                    <div className='flex flex-row'>
                      <p className='text-[14px] font-open-sans text-[#808080] mb-6 mt-2'>
                        Manage your saved payment methods below.
                      </p>

                      <button 
                        onClick={()=>setIsAddingPayMethod(true)} 
                        className='flex flex-row bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 py-[8px] px-[18px] ml-auto my-auto'
                      >
                        <AddCircleIcon className='mr-2 fill-white text-primaryDark-0' /> 
                        Add a Payment Method
                      </button>
                    </div>

                    {
                      paymentMethods.map((paymentMethod: any, i: number) => {
                        return (
                          <PayMethodComponent key={i} paymentMethod={paymentMethod} username={loggedin_username} />
                        )
                      })
                    }
                    
                  </div>
                )
              }
              
            </div>
          </div>
      </div>
    </div>
    </>
  )
}



type PayMethodComponentProps = {
  paymentMethod: any
  username: string
}

const PayMethodComponent: React.FC<PayMethodComponentProps> = ({username, paymentMethod}) => {

  const { removePaymentMethod } = useAddPaySetupIntent(username)

  const getCardIcon = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'visa':
        return <VisaCardIcon className='w-8 my-auto' />;
      case 'mastercard':
        return <MasterCardIcon className='w-8 my-auto' />;
      case 'amex':
        return <AmexCardIcon className='w-8 my-auto' />;
      case 'discover':
        return <DiscoverCardIcon className='w-8 my-auto' />
      case 'cashapp':
        return <CashappIcon className='w-8 h-8 my-auto' />
      case 'amazon_pay':
        return <AmazonPayIcon className='w-8 h-6 my-auto' />
      default:
        return <img src="/icons/unknown.svg" alt="Unknown" />;
    }
  };


  const handlePayRemoveMethod = () => {
    removePaymentMethod(paymentMethod.id)
  }

  return (
    <div 
      className='flex flex-row border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#000000] font-normal font-raleway'
    >

      {
        (paymentMethod && paymentMethod.card) ? (
        <>
          <div className='mx-4 my-auto border-[#E9E9E9] border-[1px] p-1 rounded-xl'>
            {getCardIcon(paymentMethod.card.brand)} 
          </div>
          <div className='w-1/4 my-auto'>
            {capitalizeString(paymentMethod.card.brand)}
          </div>
          <div className='w-1/4 my-auto '>
            ****{paymentMethod.card.last4 || ''}
          </div>
          <div className='w-1/4 my-auto'>
            Expires {formatDateString(paymentMethod.card.exp_month, paymentMethod.card.exp_year)}
          </div>
        </>) : (
        <>
          <div className='mx-4 my-auto border-[#E9E9E9] border-[1px] p-1 rounded-xl'>
            {getCardIcon(paymentMethod.type)} 

          </div>
          <div className='w-1/4 my-auto'>
            {capitalizeString(paymentMethod.type)}
          </div>
          <div className='w-1/4 my-auto '>
            {(paymentMethod && paymentMethod?.cashapp && paymentMethod?.cashapp?.cashtag) ? (paymentMethod?.cashapp?.cashtag || '') : ''}
          </div>
          <div className='w-1/4 my-auto'>
            {/* Expires {formatDateString(paymentMethod.card.exp_month, paymentMethod.card.exp_year)} */}
          </div>
        </>)
      }

      <div className='flex flex-row mx-4  my-auto ml-auto'>
        <div className='flex border-[1px] border-[#E9E9E9] hover:bg-slate-100 rounded-lg mr-2 h-[30px] w-[30px]'>
          <DeleteIcon className='text-[#D42620] m-auto cursor-pointer hover:text-red-800 ' onClick={handlePayRemoveMethod} /> 
        </div>
        {/* <div className='flex border-[1px] border-[#E9E9E9)] rounded-lg ml-2 h-[30px] w-[30px] hover:bg-slate-100  cursor-pointer'>
          <EditIcon className='text-primaryDark-0 hover:text-primaryDark-300 m-auto' />
        </div> */}
      </div>

    </div>
  )
}


export default Wallet