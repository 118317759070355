import React, { useState, useEffect } from "react";

import moment from "moment";
import { format, addWeeks, addMonths, startOfWeek } from "date-fns";

import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as RightArrow } from "../icons/ScrollRightArrow.svg";
import { ReactComponent as LeftArrow } from "../icons/ScrollLeftArrow.svg";
import { useGetPaymentMethods } from '../hooks/paymenthooks'
import { maxHours, styleThousandCommas } from '../utils/helpers';


// import { useAuthState } from "../contexts/authcontext";

import {
  // BookingSchema,
  MembershipSchema,
  ResourceSchema,
  ResourceType,
  ResourceBookingSchema,
  // KitchenSpaceSchema,
  PlanSchema,
  TimeBlock,
} from "../data/types";
import ReservationCalendar from "./ReservationCalendar";
import TimesView from "./AvailableTimes";
import { ArrowBackIos } from "@mui/icons-material";
import { useCompleteSignIn, 
        // useLoadBookings 
        } from "../hooks/authhooks";
import { PaymentMethod } from "@stripe/stripe-js";
// import { getMembershipPlans } from "../api/crud";
import StripePaymentCheckOut from "./PaymentCheckOut";

type StartBookingProps = {
  // isMember?: MembershipSchema;
  isOpen?: boolean;
  resourceDetail: ResourceSchema;
  currentMember: MembershipSchema;
  handleClose: () => void;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitRequest: (
    resource: ResourceSchema,
    resourceType: ResourceType,
    booking: ResourceBookingSchema
  ) => void;
  
};

const getNext30MinuteInterval = (dateTime?: any) => {
  dateTime = dateTime ? moment(dateTime) : moment(); // 2023-06-07T08:54 vs 2023-06-07T08:10
  var minutes = dateTime.minutes();
  var remainder = 30 - minutes;
  var next30MinMark = dateTime.minutes(0);
  if (remainder < 0) {
    next30MinMark = dateTime.minutes(minutes + remainder);
    next30MinMark.add(30, "minutes");
  } else if (remainder < 30) {
    next30MinMark.minutes(minutes + remainder);
  }
  return next30MinMark.format("YYYY-MM-DDTHH:mm");
};

// CreateBookingProps> = ({resourceDetail,
const RequestKitchen: React.FC<StartBookingProps> = ({
  isOpen,
  resourceDetail,
  currentMember,
  setIsSubmitting,
  handleClose,
  handleSubmitRequest
}) => {
  const [plans, setCurrentPlans] = useState<PlanSchema[]>();
  const { user } = useCompleteSignIn();
  const loggedin_username = (user && user?.username) ? user.username : ''  
  // const [resourceOptions, setResourceOptions] = useState<any>();
  const [availableTimeBlocks, setAvailableTimeBlocks] = useState<TimeBlock[]>(); 
  const [selectedDay, setSelectedDay] = useState();

  // console.log(resourceDetail);

  // const handleNHoursChange = (currentStart: Date, currentEnd: Date) => {
  //   var duration = moment.duration(
  //     moment(currentEnd)?.diff(moment(currentStart))
  //   );
  //   // calculate hours diff
  //   var hoursDiff = duration.asHours();
  //   if (!isNaN(hoursDiff) && hoursDiff >= 0) {
  //     return hoursDiff;
  //   }
  //   return 0;
  // };

  var view = "week";
  const [currentStep, updateCurrentStep] = useState<number>(1);
  // const [currentBooking, updateCurrentBooking] = useState<ResourceBookingSchema>();
  const [startDate, updateStartDate] = useState(getNext30MinuteInterval());
  const [endDate, updateEndDate] = useState(startDate);

  // const [startDate, updateStartDate] = useState(new Date());
  // const [endDate, updateEndDate] = useState(new Date());
  const [nHours, updateNHours] = useState(0);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  // Helper function above component
  const extractPlanFields = (member: any): PlanSchema => {
    if (!member) return {};
    
    return Object.entries(member)
      .filter(([key]) => key in (member as PlanSchema))
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value
      }), {});
  };

  const currentPlan = extractPlanFields(currentMember);
  const [plan, setPlan] = useState<PlanSchema | undefined>(currentPlan as PlanSchema);
  const [additionalNotes, setAdditionalNotes] = useState("");


  // const loggedin_username = (user && user?.username) ? user.username : '';
  var { paymentMethods } = useGetPaymentMethods(loggedin_username);
  paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : [];
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    (paymentMethods && paymentMethods.length > 0) ? paymentMethods[0] : {} as PaymentMethod
  );
  const [selectedBlock, setSelectedBlock] = useState<TimeBlock>();

  // const [isRepeat, setIsRepeat] = useState<boolean>(false);
  // const [bookingRecurrence, setBookingRecurrence] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  // const [endRecurrence, setEndRecurrence] = useState<string>(moment(endDate).format('YYYY-MM-DD'));

  var beforeBuffer = "0";
  var afterBuffer = "0";
  const [isEditing, setIsEditing] = useState(false);

  
  // console.log("isEditing resourceDetail", isEditing, resourceDetail)

  const resetBookingForm = () => {
    updateStartDate("")
    updateEndDate("")
    updateNHours(0)
    setPlan(undefined)
    setAdditionalNotes("")
    updateCurrentStep(1)
  }

  const handleConfirmBooking = async () => {
    console.log("newBooking resourceDetail", startDate, endDate, resourceDetail)
    if (
      user &&
      resourceDetail &&
      loggedin_username &&
      startDate &&
      endDate &&
      endDate > startDate &&
      startDate !== endDate
    ) {
      var newBooking: ResourceBookingSchema = {
        resource_type: "kitchen",
        community_id: resourceDetail.community_id ? resourceDetail.community_id : "",
        resource_id: resourceDetail.id ? resourceDetail.id : "",
        username: loggedin_username,
        start_time: moment(startDate)
          .set("minutes", moment(startDate).minutes() - Number(beforeBuffer))
          .format("YYYY-MM-DDTHH:mm")
          .toString(),
        end_time: moment(endDate)
          .set("minutes", moment(endDate).minutes() + Number(afterBuffer))
          .format("YYYY-MM-DDTHH:mm")
          .toString(),
        location: resourceDetail.address
          ? resourceDetail.address
          : resourceDetail.country
          ? resourceDetail.country
          : "",
          has_reminder: false
      };


      var duration = newBooking ? moment.duration(
          moment(newBooking?.end_time)?.diff(
            moment(newBooking?.start_time)
          )) : null;
      var hoursDiff = duration ? duration.asHours() : 0;
      // var hourlyCost = (currentMember?.plan_rate && currentMember?.plan_recurrence  === 'hourly') ? currentMember.plan_rate * hoursDiff : currentMember?.plan_rate;
      // let hoursBalance = (currentMember?.plan_recurrence && currentMember?.member_balance && resourceDetail?.capacity) ? maxHours(parseInt(resourceDetail.capacity), currentMember.plan_recurrence, currentMember?.member_balance) - hoursDiff : 0
      // hourlyCost = (hourlyCost && currentMember?.plan_recurrence  === 'hourly') ? hourlyCost : 0

      const cleaningCost = 5
      const serviceCost = 3
      // const equipmentCost = 0
      // const totalCost = hourlyCost + equipmentCost + cleaningCost + serviceCost

      newBooking.notes = additionalNotes
      newBooking.total_hours = hoursDiff
      newBooking.plan_id = plan?.plan_id
      // newBooking.plan_name = plan?.plan_name
      // newBooking.plan_description = plan?.plan_description
      // newBooking.plan_recurrence = plan?.plan_recurrence
      // newBooking.plan_rate = plan?.plan_rate
      newBooking.payment_method = paymentMethod?.id
      newBooking.fees = [{name: 'services', rate: serviceCost}, {name: 'cleaning', rate: cleaningCost}, {name: 'tax', rate: 0}]
  
      // if (isRepeat){
      //   newBooking.booking_recurrence = bookingRecurrence
      //   newBooking.end_recurrence = endRecurrence
      // }

      console.log("handleConfirmBooking newBooking: ", newBooking);
      // console.log("handleConfirmBooking resourceDetail: ", resourceDetail);

      // updateCurrentBooking(newBooking);
      if(newBooking){
        await handleSubmitRequest(resourceDetail, "kitchen", newBooking)
        setIsSubmitting(true);
        resetBookingForm()
        handleClose()
      }
    }
   
  };
  // console.log("startDate: ", startDate);
  // console.log("endDate: ", endDate);

  



  useEffect(() => {
    setCurrentPlans(resourceDetail?.plans)
    setPaymentMethod((paymentMethods && paymentMethods.length > 0) ? paymentMethods[0] : {} as PaymentMethod)  
    // eslint-disable-next-line 
  }, [resourceDetail, resourceDetail.community_id])
 

  if (handleClose && isOpen) {
    handleClose();
  }
  return (
    <div className="contain text-center">
      {/* <div className="flex flex-col items-center font-open-sans text-inherit p-6 col-span-1 rounded-md justify-evenly"> */}
      <div className="flex flex-col justify-evenly items-center h-full">
        <span
          onClick={handleClose}
          className="ml-auto w-full flex cursor-pointer"
        >
          <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>

        <div className="flex flex-row h-[4px] border-0 w-11/12 my-4 mx-auto justify-start ">
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <div
                key={i}
                className={`h-[4px] border-0 w-[110px] mr-2 ${
                  i < currentStep ? "bg-primaryDark-400" : "bg-gray-200"
                }`}
              />
            ))}
        </div>

        <p className="text-black font-medium text-[24px] font-raleway text-left w-11/12 mt-2">
          Book <span className="font-bold">{resourceDetail.name}</span>
        </p>
        <p className="text-left text-[16px] my-2 font-open-sans font-medium text-[#333333] w-11/12">
          {
            currentStep === 6 ? "Booking Summary" : "Fill out the details below to book your kitchen time."
          }
        </p>

        {currentStep === 1 ? (
          <div className="flex w-11/12 mx-auto">
            <SelectHoursView
              view={view}
              nHours={nHours}
              updateNHours={updateNHours}
              resourceDetail={resourceDetail}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              startDate={startDate}
              endDate={endDate}
              updateStartDate={updateStartDate}
              updateEndDate={updateEndDate}
            />
          </div>
        ) : currentStep === 2 ? (
          <div className="flex w-11/12 mx-auto">
            <SelectDateView
              view={view}
              nHours={nHours}
              updateNHours={updateNHours}
              resourceDetail={resourceDetail}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              startDate={startDate}
              endDate={endDate}
              setBlocks={setAvailableTimeBlocks}
              handleConfirmBooking={handleConfirmBooking}
            />
          </div>
        ) : currentStep === 3 ? (
          <>
            <SelectTimeView
              nHours={nHours}
              resourceDetail={resourceDetail}
              // currentDate={currentDate}
              selectedDay={selectedDay}
              availableTimeBlocks={availableTimeBlocks}
              // startDate={startDate}
              // endDate={endDate}
              selectedBlock={selectedBlock}
              setSelectedBlock={setSelectedBlock}
              updateStartDate={updateStartDate}
              updateEndDate={updateEndDate}
            />
          </>
        ) : currentStep === 4 ? (
          <SelectPlanView plan={plan} setPlan={setPlan} plans={plans? plans:[]} resourceDetail={resourceDetail} />
        ) : currentStep === 5 ? (
          <AdditionalNotesView
            setAdditionalNotes = {setAdditionalNotes}
          />
        ) : currentStep === 6 ? (
          <FinalSubmissionView
            view={view}
            nHours={nHours}
            resourceDetail={resourceDetail}
            currentMember={currentMember}
            setCurrentDate={setCurrentDate}
            startDate={startDate}
            selectedDay={selectedDay}
            endDate={endDate}
            updateCurrentStep={updateCurrentStep}
            setIsEditing={setIsEditing}
            plan={plan?plan:{}}
            additionalNotes={additionalNotes}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        ) : (
          <></>
        )}

        {/* AdditionalNotesView */}
        {/* PaymentsView */}

        <div className="flex flex-col w-11/12 mx-auto mt-4">



          <button
            className={`bg-primaryDark-0 py-3 px-2 text-white font-semibold text-[18px] font-raleway  hover:bg-back-300  border-0 w-full my-2 ${
              currentStep === 6 ? "hidden" : "block"
            }`}
            onClick={() => {
              (isEditing && currentStep > 2) ? updateCurrentStep(6) : updateCurrentStep((prev) => (prev <= 6 ? prev + 1 : prev))
            }
            }
            disabled={
              (currentStep === 1 && !(nHours && nHours > 0)) ||
              (currentStep === 2 && !selectedDay) ||
              (currentStep === 3 && !(startDate && endDate && moment(startDate).isBefore(moment(endDate)) ))
            }
          >
            {currentStep === 1 ? 'Proceed' : 'Continue'}
          </button>

          <button
            className={`bg-primaryDark-0 p-2 text-white text-[18px] font-semibold font-raleway  hover:bg-back-300  border-0 w-full my-2 ${
              currentStep === 6 ? "block" : "hidden"
            }`}
            onClick={handleConfirmBooking}
          >
            Submit
          </button>

          <button
            className={`text-primaryDark-0 py-3 px-2 text-[18px] font-semibold font-raleway hover:bg-back-300  border-0 w-full my-2 ${
              currentStep === 1 ? "hidden" : "block"
            }`}
            onClick={() =>
              updateCurrentStep((prev) => (prev >= 1 ? prev - 1 : 1))
            }
          >
            <ArrowBackIos /> Go Back
          </button>

        </div>






        {/* {isEditing && <button
          className={`bg-primaryDark-0 p-2 text-white text-[18px] font-medium font-raleway hover:bg-back-300 border-0 w-11/12 my-2`}
          onClick={() => {
            setIsEditing(!isEditing)
            updateCurrentStep(7)
          }}
        >
          {isEditing ? "Save Changes" : "Edit Booking"}
        </button>} */}


      </div>
    </div>
  );
};

type SelectHoursViewProps = {
  view: string;
  resourceDetail: ResourceSchema;
  currentDate: Date;
  setCurrentDate: any;
  startDate: any;
  endDate: any;
  updateStartDate: any;
  updateEndDate: any;
  nHours: number;
  updateNHours: React.Dispatch<React.SetStateAction<number>>;
  handleConfirmBooking?: () => void;
};
const SelectHoursView: React.FC<SelectHoursViewProps> = ({
  // view,
  // resourceDetail,
  nHours,
  updateNHours,
  // currentDate,
  startDate,
  endDate,
  // setCurrentDate,
  // updateStartDate,
  updateEndDate,
}) => {
  return (
    <div className="w-full mx-auto h-full">
      <div className="flex flex-col w-full mt-3 justify-between ">
        <p className="text-black font-medium text-left text-[16px] font-raleway">
          How many hours do you need?
        </p>
        <div className="flex flex-row gap-x-5 w-full justify-center my-2 font-bold text-gray-300 border-[#E9E9E9] border-[1px] rounded-md p-1">
          <input
            type="number"
            min="0"
            max="10"
            step="0.5"
            className="bg-inherit my-auto text-left border-0  cursor-pointer text-primary-300 rounded-lg w-full"
            value={nHours.toFixed(1)}
            onChange={(e) => {
              e.preventDefault();
              let { value } = e.target;
              let intE = parseFloat(value);
              if (intE === 0 || intE) {
                updateNHours(intE);
              }
            }}
          />

          <div
            onClick={() => {
              if (nHours > 0 && moment(startDate) < moment(endDate)) {
                updateNHours(nHours - 0.5);
                let newEndDate = moment(endDate);
                newEndDate.subtract(30, "minutes");
                // updateEndDate(getNext30MinuteInterval(newEndDate));
              }
            }}
            className="flex rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer m-auto"
          >
            <LeftArrow className=" text-black rounded-full  m-auto" />
          </div>

          <div
            onClick={() => {
              if (nHours < 24) {
                updateNHours(nHours + 0.5);
                let newEndDate = moment(endDate);
                newEndDate.add(30, "minutes");
                // updateEndDate(getNext30MinuteInterval(newEndDate));
              }
            }}
            className="flex rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer m-auto"
          >
            <RightArrow className="text-black rounded-full m-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

type SelectDateViewProps = {
  view: string;
  resourceDetail: ResourceSchema;
  currentDate: Date;
  setCurrentDate: any;
  startDate: any;
  endDate: any;
  selectedDay: any;
  setSelectedDay: any;
  nHours: number;
  setBlocks: React.Dispatch<React.SetStateAction<TimeBlock[] | undefined>>;
  updateNHours: React.Dispatch<React.SetStateAction<number>>;
  handleConfirmBooking?: () => void;
};
const SelectDateView: React.FC<SelectDateViewProps> = ({
  view,
  nHours,
  // updateNHours,
  handleConfirmBooking,
  resourceDetail,
  currentDate,
  selectedDay,
  setSelectedDay,
  setBlocks,
  setCurrentDate,
}) => {
  // const [] = useState<number>(0)
  // const { user } = useCompleteSignIn();
  // const loggedin_username = user && user?.username ? user.username : "";
  // const bookings = useLoadBookings(loggedin_username).bookings;
  // console.log("bookings ", bookings);
  
  const next = () => {
    setCurrentDate(
      view === "month" ? addMonths(currentDate, 1) : addWeeks(currentDate, 4)
    );
  };

  const prev = () => {
    setCurrentDate(
      view === "month" ? addMonths(currentDate, -1) : addWeeks(currentDate, -4)
    );
  };

  return (
    <div className="w-full flex flex-col">
      <p className="text-black font-medium text-left text-[16px] font-raleway mt-3">
        Choose your date
      </p>
    
      <div className="flex flex-col w-full mx-auto my-2 justify-between p-4 border-[1px] border-[#E9E9E9] rounded-lg">
        <div className="flex flex-row mb-4">
          <h2 className="text-[16px] text-[#4D4D4D] font-raleway font-medium text-center my-auto text-primary-400">
            {view === "month"
              ? format(currentDate, "MMMM yyyy")
              : `${format(startOfWeek(currentDate), " MMMM yyyy")}`}
          </h2>

          <div className="flex-row justify-between items-center text-slate-400 my-auto ml-auto">
            <button 
              onClick={prev}
              className="mr-4 rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer m-auto"
            >
              <LeftArrow className=" text-black rounded-full  m-auto" />
            </button>
            <button 
              onClick={next}
              className="mr-4 rounded-full h-[32px] w-[32px] border-[1px] border-[#E9E9E9] p-2 hover:bg-gray-200 cursor-pointer m-auto"
            >
                <RightArrow className="text-black rounded-full m-auto" />
            </button>
          </div>
        </div>
      

        

        <div className="flex justify-center items-center w-full">
          <ReservationCalendar
            currentDate={currentDate}
            userBookings={[]}
            resourceBookings={[]}
            setBlocks={setBlocks}
            nHours={nHours}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            handleConfirmBooking={handleConfirmBooking}
            currentKitchen={resourceDetail}
          />
        </div>
      </div>
    </div>
  );
};

type SelectTimeViewProps = {
  resourceDetail: ResourceSchema;
  selectedDay: any
  nHours: number;
  updateStartDate: any;
  updateEndDate: any;
  availableTimeBlocks: TimeBlock[] | undefined;
  selectedBlock: TimeBlock | undefined
  setSelectedBlock: React.Dispatch<React.SetStateAction<TimeBlock | undefined>>;
};

const SelectTimeView: React.FC<SelectTimeViewProps> = ({
  updateEndDate,
  updateStartDate,
  selectedDay,
  availableTimeBlocks,
  selectedBlock,
  setSelectedBlock
  
}) => {
  
  return (
    <TimesView
      selectedDay={selectedDay}
      updateEndTime={updateEndDate}
      updateStartTime={updateStartDate}
      availableTimeBlocks={availableTimeBlocks}
      selectedBlock={selectedBlock}
      setSelectedBlock={setSelectedBlock}
    />
  );
};

type SelectPlanViewProp = {
  resourceDetail: ResourceSchema;
  plans: PlanSchema[];
  plan: PlanSchema | undefined
  setPlan: React.Dispatch<React.SetStateAction<PlanSchema | undefined>>;
};

const SelectPlanView: React.FC<SelectPlanViewProp> = ({ plan, plans, setPlan }) => {
  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMethod = plans.find((plan:any) => plan.plan_id === e.target.value);
    setPlan(selectedMethod);
  };

  const [isChangePlan, setIsChangePlan] = useState(false);
  return (
    <div className="flex flex-col w-11/12 h-full text-left mx-auto">
      {
        (isChangePlan) ? (
          <>
            <p className="font-raleway text-[16px] text-black">Choose Plan</p>
            <select value={plan?.plan_id} onChange={handlePlanChange} className="border-[1px] border-[#E9E9E9] rounded-lg text-[16px] text-[#808080] font-open-sans">
              <option value="">Choose a plan</option>
              {plans.map((p: PlanSchema) => (
                <option key={p.plan_id} value={p.plan_id}>
                  {p.plan_name}
                </option>
              ))}
            </select>
          </>
        ) : (
          <>
            <p className="font-raleway text-[16px] text-black">
              Current Plan &nbsp;
              <span 
                className="text-primaryDark-300 text-[14px] cursor-pointer"
                onClick={() => setIsChangePlan(true)}
              >
                Change
              </span>
            </p>
            <p className="font-raleway text-[16px] text-[#808080]">{plan?.plan_name} - ${(plan?.plan_rate) && styleThousandCommas(plan?.plan_rate)} ({plan?.plan_recurrence})</p>
          </>
        )
      }
    </div>
  );
};
type AdditionalNotesViewProps = {
  setAdditionalNotes: React.Dispatch<React.SetStateAction<string>>;
};
const AdditionalNotesView: React.FC<AdditionalNotesViewProps> = ({
  setAdditionalNotes
}) => {
  return (
    <div className="flex flex-col w-11/12 h-full text-left mx-auto">
      <p className="font-raleway text-[16px] text-black">Additional Notes</p>
      <textarea onChange={(e) => setAdditionalNotes(e.target.value)}
        placeholder="Additional Notes"
        className="border-[1px] border-[#E9E9E9] rounded-lg text-[16px] text-[#808080] font-open-sans"
      />
    </div>
  );
};
type FinalSubmissionViewProps = {
  view: string;
  resourceDetail: ResourceSchema;
  currentMember: MembershipSchema;
  setCurrentDate: any;
  startDate: any;
  setIsEditing: any;
  updateCurrentStep: any;
  endDate: any;
  plan: PlanSchema;
  selectedDay:any;
  nHours: number;
  additionalNotes: string;
  paymentMethod: PaymentMethod;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
};


const FinalSubmissionView: React.FC<FinalSubmissionViewProps> = ({
  resourceDetail,
  startDate,
  endDate,
  additionalNotes,
  setIsEditing,
  updateCurrentStep,
  nHours,
  plan,
  selectedDay,
  currentMember,
  paymentMethod,
  setPaymentMethod

}) => {
  const handleEditBooking = (step: number) => {
      setIsEditing(true);
      updateCurrentStep(step);
    }

  const [isEditPay, setIsEditPay] = useState(false);
  
  return (
    <div className="flex flex-row w-11/12 h-full text-left mx-auto gap-y-4">
      <div className="flex flex-col mr-auto w-1/2">
        {/* <div className="flex flex-col gap-2 ">
          <div className="flex flex-col justify-between">
            <h1 className="text-gray-500">Kitchen Name</h1>
            <div className="flex flex-row justify-between">
              <p className="font-semibold">
                {resourceDetail.name}
              </p>
              <h1 className='cursor-pointer text-primaryDark-400' onClick={() => handleEditBooking(0)}>Edit</h1>
            </div>
          </div>

          <hr />
        </div> */}
        <div className="flex flex-col gap-2">
          <div className="flex flex-col justify-between">
            <h1 className="text-gray-500">Total Hour(s)</h1>
            <div className="flex flex-row justify-between">
              <p className="font-semibold">
                {nHours}
              </p>
              <h1 className='cursor-pointer text-primaryDark-400'  onClick={() => handleEditBooking(1)}>Edit</h1>
            </div>
          </div>

          <hr />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col justify-between">
            <h1 className="text-gray-500">Date</h1>
            <div className="flex flex-row justify-between">
              <p className="font-semibold">
              {moment(selectedDay).format("MM/DD/YYYY")}

              </p>
              <h1 className='cursor-pointer text-primaryDark-400' onClick={() => handleEditBooking(2)}>Edit</h1>
            </div>
          </div>

          <hr />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col justify-between">
            <h1 className="text-gray-500">Time</h1>
            <div className="flex flex-row justify-between">
              <p className="font-semibold">
              {moment(startDate).format("hh:mm A") + " - " + moment(endDate).format("hh:mm A")}
              </p>
              <h1 className='cursor-pointer text-primaryDark-400' onClick={() => handleEditBooking(3)}>Edit</h1>
            </div>
          </div>

          <hr />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col justify-between">
              <h1 className="text-gray-500">Plan</h1>
              <div className="flex flex-row justify-between">
                <p className="font-normal">
                  <b>{plan.plan_name}</b>&nbsp; <i className="text-[14px] text-gray-400">${plan.plan_rate} ({plan.plan_recurrence})</i>
                </p>
                <h1 className='cursor-pointer text-primaryDark-400' onClick={() => handleEditBooking(4)}>Edit</h1>
              </div>
            </div>

            <hr />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col justify-between">
              <h1 className="text-gray-500">Additional Notes</h1>
              <div className="flex flex-row justify-between">
                {
                  (additionalNotes) ? (
                    <p className="font-semibold">{additionalNotes}</p>
                  ) : (
                    <p className="text-[14px] text-gray-300">-</p>
                  )
                }
                <h1 className='cursor-pointer text-primaryDark-400'onClick={() => handleEditBooking(5)}>Edit</h1>
              </div>
            </div>

            <hr />
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <div className="flex flex-col justify-between">
              <h1 className="text-gray-500">Payment Method</h1>
              {
                (!isEditPay && (paymentMethod && paymentMethod?.card)) ? (
                <div className="flex flex-row justify-between">
                  {
                    <p className="font-semibold">
                      {`${paymentMethod?.card?.brand?.toUpperCase()} **** ${paymentMethod?.card?.last4} ${paymentMethod?.card?.exp_month?.toString().padStart(2, '0')}/${paymentMethod?.card?.exp_year?.toString().slice(-2)}`}
                    </p>
                  }
                  <h1 className='cursor-pointer text-primaryDark-400 ml-auto' onClick={() => setIsEditPay(true)}>Edit</h1>
                </div>
                ) : (
                  <PaymentsView 
                    paymentMethod={paymentMethod} 
                    setPaymentMethod={setPaymentMethod}
                    setIsEditPay={setIsEditPay} 
                  />
                )
              }
            </div>
          </div>
        </div>

        <div className="ml-auto w-1/3">
          <PriceDetails plan={plan} currentKitchen={resourceDetail} member={currentMember} hoursDiff={nHours} />
        </div>
      </div>
  );
};

type PaymentsViewProps = {
  paymentMethod: PaymentMethod;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  setIsEditPay: React.Dispatch<React.SetStateAction<boolean>>;
};

const PaymentsView: React.FC<PaymentsViewProps> = ({
  paymentMethod,
  setIsEditPay,
  setPaymentMethod
}) => {
  const { user } = useCompleteSignIn();
  const loggedin_username = (user && user?.username) ? user.username : '';
  var { paymentMethods } = useGetPaymentMethods(loggedin_username);
  paymentMethods = (paymentMethods && Array.isArray(paymentMethods)) ? paymentMethods : [];

  const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMethod = paymentMethods.find((pm:any) => pm.id === e.target.value);
    setPaymentMethod(selectedMethod);
    setIsEditPay(false);
  };

  const [isAddPay, setIsAddPay] = useState(false);

  return (
    <div className="flex flex-col w-full h-full text-left my-2">
      <h1 className='cursor-pointer text-primaryDark-400 ml-auto'  onClick={() => setIsAddPay(prev => !prev)}>
        {((!isAddPay) && (paymentMethods && paymentMethods.length > 0)) ? '+ Add New Card' : 'Select Existing Card'}
      </h1>

      {
        (!isAddPay) ? ((paymentMethods && paymentMethods.length > 0) ? 
        (
          <select 
            value={paymentMethod?.id}
            onChange={handlePaymentMethodChange}
            className="border-[1px] border-[#E9E9E9] rounded-lg text-[16px] text-[#808080] font-open-sans p-2"
          >
            <option value="">Choose a payment method</option>
            {paymentMethods.filter((p: any) => p && p?.card && p?.card?.brand).map((pm: any) => (
              <option key={pm.id} value={pm.id}>
                {
                  (pm && pm?.card && pm?.card?.brand ) && (`${pm.card.brand.toUpperCase()} **** ${pm.card.last4} ${pm.card.exp_month.toString().padStart(2, '0')}/${pm.card.exp_year.toString().slice(-2)}`)
                }
              </option>
            ))}
          </select>
        ) : (
        <p className="font-open-sans font-light text-gray-500 text-sm">No Existing Payment Method</p>
        )) : (
          <StripePaymentCheckOut setIsAddingPayMethod={setIsAddPay} username="" mode="setup" currency="usd" setup_future_usage='off_session' isAddPayMethod={true} hasExistingPayMethod={paymentMethods && paymentMethods.length > 0} />
        )
      }
    </div>
  );
};
export const useDate = () => {
  const locale = "en";
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};



type PriceDetailsProps = {
  member: MembershipSchema;
  plan: PlanSchema;
  hoursDiff: number;
  currentKitchen: ResourceSchema;
  // handleClose: () => void;
  // toggleUpdatePlan: (value: boolean) => void;
}

const PriceDetails: React.FC<PriceDetailsProps> = ({member, plan, hoursDiff, currentKitchen}) => {
  var hourlyCost = (plan?.plan_rate && plan?.plan_recurrence  === 'hourly') ? plan.plan_rate * hoursDiff : plan?.plan_rate;
  hourlyCost = (hourlyCost && plan?.plan_recurrence  === 'hourly') ? hourlyCost : 0;
  var hoursBalance = (plan?.plan_recurrence && member?.member_balance && currentKitchen?.capacity) ? maxHours(parseInt(currentKitchen.capacity), plan.plan_recurrence, member?.member_balance) - hoursDiff : 0;

  const cleaningCost = 5
  const serviceCost = 3
  const equipmentCost = 0
  const totalCost = hourlyCost + equipmentCost + cleaningCost + serviceCost

  return (
  <>
    <h1 className="text-gray-500">Price Details</h1>
    
    <div className="flex flex-col font-work-sans text-[#D9D9D9] p-1 border-[1px] border-[#D9D9D9] col-span-1 rounded-md w-full">
      {plan?.plan_rate && plan?.plan_name ? (
        <div className="flex flex-row w-full justify-between p-1 m-1">
          <div className="flex flex-col font-light text-black text-left w-full">
            <span className="text-[11px] text-black/60 ">
              <b>PLAN RATE:</b>{" "}
              &nbsp;
            </span>
          </div>
          <p className=" font-light text-black/80 text-[13px] text-right w-full">
            ${(plan?.plan_rate) && styleThousandCommas(plan?.plan_rate)} ({plan?.plan_recurrence})
          </p>
        </div>
      ) : (<></>)}

      <div className="flex flex-row w-full justify-between p-2 m-1">
        <div className="flex flex-col font-light text-black ">
          Kitchen Rental{" "}
          <span className="text-[11px] text-black/60 ">
            {hoursDiff
              ? "(" +
                hoursDiff.toString() +
                ` hour${hoursDiff > 1 ? "s" : ""})`
              : ""}
          </span>
        </div>
        {/* <p className=" font-light text-black/80 ">${styleThousandCommas(hourlyCost)}</p> */}
        <p className=" font-light text-black/80 text-sm">{!hourlyCost && hoursBalance ? `${hoursBalance} hours left` : `$${styleThousandCommas(hourlyCost)}`}</p>
        
      </div>

      <div className="flex flex-row w-full justify-between p-2 m-1">
        <p className=" font-light text-black ">Equipment</p>
        <p className=" font-light text-black/80 ">{equipmentCost}</p>
      </div>

      <div className="flex flex-row w-full justify-between p-2 m-1">
        <p className=" font-light text-black ">Cleaning Fee</p>
        <p className=" font-light text-black/80 ">{cleaningCost}</p>
      </div>

      <div className="flex flex-row w-full justify-between p-2 m-1">
        <p className=" font-light text-black ">Service Fee</p>
        <p className=" font-light text-black/80 ">{serviceCost}</p>
      </div>

      <div className="flex flex-row w-full justify-between p-2 m-1">
        <p className=" font-light text-[#5DB6CE] ">Member Discount</p>
        <p className=" font-light text-[#5DB6CE]/80 ">- 0</p>
      </div>

      <div className="flex flex-row w-full justify-between p-2 m-1 border-t-[1px] border-white/60">
        <p className=" font-light text-black ">Total (USD)</p>
        <p className=" font-light text-black/80 ">
          $ {styleThousandCommas(totalCost)}
        </p>
      </div>
    </div>
  </>
  )
}

export default RequestKitchen;



