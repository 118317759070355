import { useNavigate } from "react-router-dom";
import moment from "moment";
import homecook_logo from "../assets/img/homecook_logo.png";
import config from '../config';


var webUrl = config?.webUrl

const Footer = () => {
  const navigate = useNavigate();
  const openInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  const currentYear = moment().year();
  return (
    <footer
      className=" bottom-0 flex flex-row  bg-primaryDark-200  w-full z-50 font-open-sans cursor-pointer"
      onClick={() => navigate("/")}
    >
      <div className=" w-full py-2 lg:py-8">
        <div className="md:flex md:justify-between px-20 pt-10 pb-20">
          <div className="mb-6 md:mb-0 flex flex-col">
            <a href="/" className="flex items-center pb-2">
              <img src={homecook_logo} className="h-8 " alt="Homecook Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-primaryLight-1000">
                HomeCook
              </span>
            </a>
            <div className="text-primaryLight-1000 pl-2 text-sm">
              Expand beyond your home kitchen
            </div>
          </div>
          <div className="grid grid-cols-2 gap-10 sm:gap-60 sm:grid-cols-3 font-raleway">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-primaryLight-1000 uppercase ">
                Products
              </h2>
              <ul className=" text-primaryLight-1000 font-medium text-sm ">
                <li className="mb-4">
                  <a href={webUrl} className="underline">
                    Resource Pantry
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-primaryLight-1000 uppercase ">
                Socials
              </h2>
              <ul className=" text-primaryLight-1000 font-mediumt text-sm">
                <li>
                  <span onClick={()=>openInNewTab("https://www.instagram.com/homecook.app/")} className="underline">
                    Instagram
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-primaryLight-1000 uppercase ">
                Legals
              </h2>
              <ul className=" text-primaryLight-1000 font-medium text-sm">
                <li className="mb-4">
                  <span onClick={()=>openInNewTab("/terms")} className="underline">
                    Terms of Service
                  </span>
                </li>
                <li className="mb-4">
                  <span onClick={()=>openInNewTab("/privacy")} className="underline">
                    Privacy Policy
                  </span>
                </li>
                <li className="mb-4">
                  <span onClick={()=>openInNewTab("/disclaimer")} className="underline">
                    Disclaimer
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-[#5DB6CE] h-[75px] w-full pt-4 ">
          <div className="bg-primaryLight-1000 h-[59px]  container text-center m-auto pt-4  text-sm">
            We are not affiliated with all organizations / businesses listed,
            these resources are offered for informational purposes only based on
            publicly sourced data
          </div>
        </div>
        <div className="items-center justify-center text-center">
          <span className="text-sm  sm:text-center text-primaryLight-1000 ">
            © {currentYear} Resource Pantry from HomeCook LLC
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
