import { useEffect } from 'react'
import Policy from '../../components/Policy'

const Disclaimer = () => {

  useEffect(() => {
    document.title = `HomeCook | Disclaimer`
  }, [])

  return (
    <div className='flex flex-col h-[900px] sm:h-screen justify-evenly bg-[#D9D9D9]'>
      <p className='text-md mx-auto mt-20 text-primaryDark-200 font-raleway'>Disclaimer</p>
      <Policy policyKey={process.env.REACT_APP_DISCLAIMER_KEY} />
    </div>
  )
}

export default Disclaimer