import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import homecook_logo from "../assets/img/homecook_logo.png";
import NewSearch from "./NewSearch";
import { SearchFilterOptionType } from "../data/types";
import { ReactComponent as Filter } from "../icons/filter.svg";
import { ReactComponent as User } from "../icons/user.svg";
import { LoginModal, FilterModal } from "../molecules/AppModal";
import LogoutIcon from "@mui/icons-material/Logout";
import { useCompleteSignIn } from "../hooks/authhooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { ReactComponent as UserIcon } from "../icons/user.svg";
import { ReactComponent as ProfileIcon } from "../icons/ProfileIcon.svg";
import { ReactComponent as HeartIcon } from "../icons/HeartIcon.svg";
import NavbarSkeleton from "./NavbarSkeleton";
import { ReactComponent as WalletIcon } from "../icons/WalletIcon.svg";

import { ReactComponent as NotificationIcon } from "../icons/Notification.svg";
import { ReactComponent as ClickedNotificationIcon } from "../icons/ClickedNotificationIcon.svg";
import { ReactComponent as CartIcon } from "../icons/cart icons outlined - 24px.svg";
import { ReactComponent as ClickedCartIcon } from "../icons/cart icon filled - 20px.svg";
import { ReactComponent as FeedIcon } from '../icons/FeedIcon.svg';
import { ReactComponent as ClickedFeedIcon } from '../icons/FilledFeedIcon.svg'; 


const profileMenuList = [
  {
    name: "profile",
    label: "My profile",
    Icon: UserIcon,
    color: "#000000",
  },
  {
    name: "saved",
    label: "Saved Resources",
    Icon: HeartIcon,
    color: "#000000",
  },
  {
    name: "wallet",
    label: "Wallet",
    Icon: WalletIcon,
    color: "#000000",
  },
  {
    name: "logout",
    label: "Logout",
    Icon: LogoutIcon,
    color: "#D42620",
  },
];

type NavbarProps = {
  searchFilterOptions: SearchFilterOptionType;
  loading: boolean;
};

const Navbar: React.FC<NavbarProps> = (props) => {
  const navigate = useNavigate();

  const { user } = useCompleteSignIn();
  const loggedin_username = user && user?.username ? user.username : "";
  const { searchFilterOptions, loading } = props;
  const {
    currentFilter,
    searchTerm,
    signInModalIsOpen,
    setCurrentFilter,
    changeSearchTerm,
    setSignInModalIsOpen,
  } = searchFilterOptions;
  const [filterSetting, setFilterSetting] = useState<boolean>(false);
  const [userOptions, toggleUserOptions] = useState<boolean>(false);
  const [notificationClicked, setNotificationClicked] = useState(false);
  // const [feedClicked, setFeedClicked] = useState(false);
  // const [showCart, setShowCart] = useState(false);
  // const [cartClicked, setCartClicked] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation()
  const currentLocation = location.pathname

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 1024;
  const notifications: any[] = []
  const cart: any[] = []
  const notificationCount = notifications.length;
  const cartCount = cart.length;

  const toggleFilterSetting = () => {
    setFilterSetting((prev) => !prev);
  };

  const toggleNotifications = () => {
    setNotificationClicked((prev) => !prev);
  };

  const goToFeed = () => {
    navigate(`/feed`)
  };

  const goToCart = () => {
    navigate(`/cart`)
  };


  return !isMobile ? (
    <div className="flex flex-row justify-center bg-primaryLight-1000 fixed w-full   z-10 h-[52px] font-open-sans cursor-pointer border-b-2 ">
      <div className="flex flex-row  bg-primaryLight-1000 fixed w-full z-10 h-16 font-open-sans cursor-pointer border-b-2 items-center place-items-center justify-center">
        {/* 1024 × 768 */}
        <div className="flex flex-row ml-20 items-center w-11/12 sm:w-10/12 ">
          <img
            src={homecook_logo}
            alt="Kitchen Logo"
            className="w-22 h-12 my-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
          <h1
            className="text-primaryDark-0 text-xl mr-auto my-auto font-bold cursor-pointer"
            onClick={() => navigate("/")}
          >
            HomeCook
          </h1>




          {
            (loading) ? (
              <NavbarSkeleton />
            ) : (
              (loggedin_username) ? (
              <div className="relative">
                  <div
                    className={`w-auto relative m-auto flex items-center ${
                      ((currentLocation === `/feed`)) ? "border-b-2 border-[#013847]" : ""  
                    }`}
                    onClick={goToFeed}
                    
                  >
                    {
                      ((currentLocation === `/feed`)) ? (
                        <ClickedFeedIcon className="absolute mt-[2px] ml-[-16px]" />
                      ) : (
                        <FeedIcon className="absolute mt-[2px] ml-[-16px]" />
                      
                      )
                    }
                  
                    <p
                      className={`font-raleway text-[14px] mt-1 ml-[7px] ${
                        (currentLocation === `/feed`) ? "text-[#013847] font-extrabold" : "text-[#4D4D4D]"
                      }`} 
                    > 
                      &nbsp;Feed
                    </p>
                  </div>
                </div>
              ) : (<></>)
            )
          }



          <div className="flex flex-row ">
            <div className="flex flex-row">
              <div className="flex flex-row items-center m-auto w-3/4">
                <NewSearch
                  searchTerm={searchTerm}
                  toggleFilterSetting={toggleFilterSetting}
                  filterSetting={filterSetting}
                  changeSearchTerm={changeSearchTerm}
                  searchType={"resources"}
                />
              
                <div
                  onClick={() => setFilterSetting(true)}
                  className={`border-2 rounded-lg ml-[-70px] w-10 h-10 place-self-center hover:cursor-pointer ${
                    filterSetting
                      ? "bg-primaryDark-200 text-white "
                      : "bg-primaryLIght-1000 text-black"
                  } `}
                >
                  <Filter
                    className={`m-auto mt-2 ${
                      filterSetting ? " fill-black" : "fill-white"
                    } `}
                  />
                  
                </div>
              </div>

              {
                (loading) ? (
                  <NavbarSkeleton />
                ) : (
                  (loggedin_username) ? (
                  <div className="flex flex-row m-8 items-center">
                      <div
                        className={`relative flex items-center ${
                          notificationClicked ? "border-b-2 border-[#013847]" : ""  
                        }`}
                        onClick={toggleNotifications}
                      >
                        {
                          notificationClicked ? (
                            <ClickedNotificationIcon className="mt-1 text-[#013847] h-[22px] w-[22px]" />
                          ) : (
                            <NotificationIcon className="mt-1 text-[#333333] h-[22px] w-[22px]" />
                          
                          )
                        }
                        {
                          (notificationCount > 0) && (
                            <div className="text-white text-[9px] flex items-center justify-center font-extrabold text-center absolute top-0 w-4 h-4 bg-red-500 rounded-full border-2 border-red-500">
                              {notificationCount}
                            </div>
                          )
                        }
                      
                        <p
                          className={`font-raleway text-[14px] mt-1 ${
                            notificationClicked ? "text-[#013847] font-extrabold" : "text-[#4D4D4D]"
                          }`} 
                        > 
                          &nbsp;Notifications
                        </p>
                      </div>
        
                      {
                        notificationClicked && (
                          <div 
                            className="absolute top-11 right-[200px] mt-4 h-48 w-80 bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-20"
                            onMouseLeave={() => setNotificationClicked(false)}
                          >
                            <h1 className="font-extrabold mx-1">Notifications</h1>
                            {
                              (notificationCount && notificationCount > 0) ? (
                                <>
                                </>
                              ) : (
                                <p className="font-open-sans text-gray-400 text-xs m-1">No Notifications to show</p>
                              )
                            }
                          </div>
                        )
                      }

                      <div
                        className={`relative left-3 m-auto flex items-center ${
                          ((currentLocation === `/cart`)) ? "border-b-2 border-[#013847]" : ""  
                        }`}
                        onClick={goToCart}
                      >
                        {
                          ((currentLocation === `/cart`)) ? (
                            <ClickedCartIcon className="mt-1 text-[#013847]" />
                          ) : (
                            <CartIcon className="mt-1 text-[#333333]" />
                          )
                        }
                        {
                          (cartCount > 0) && (
                            <div className="text-white text-[9px] flex items-center justify-center font-extrabold text-center absolute top-0 w-4 h-4 bg-red-500 rounded-full border-2 border-red-500">
                              {cartCount}
                              </div>
                          )
                        }
                      
                        <p
                          className={`font-raleway text-[14px] mt-1 ${
                            (currentLocation === `/cart`) ? "text-[#013847] font-extrabold" : "text-[#4D4D4D]"
                          }`} 
                        > 
                          &nbsp;Cart
                        </p>
                      </div>
                    </div>
                  ) : (<></>)
                )
              }
              </div>
              
            </div>{" "}
          </div>

         

          {loading?<NavbarSkeleton/>:((loggedin_username) ? (
            <>
              <div className="text-xs flex flex-row mx-auto text-black w-auto m-4 h-6 my-auto items-center">
                {user && user?.avatar ? (
                  <div
                    className="flex border-[#E9E9E9] border-[1px] rounded-full h-9 w-9 mr-2"
                    onClick={() => toggleUserOptions(true)}
                  >
                    <img
                      id="image-input-avatar"
                      src={user.avatar}
                      alt="User Avatar"
                      className="h-8 w-8  m-auto rounded-full cursor-pointer"
                    />
                  </div>
                ) : (
                  <ProfileIcon
                    onClick={() => toggleUserOptions(true)}
                    className="ml-auto text-[#E6E6E6] w-auto m-auto h-8 my-auto cursor-pointer hover:text-primary-400 mr-2"
                  />
                )}
                <div
                  className="flex flex-col cursor-pointer"
                  onClick={() => toggleUserOptions(true)}
                >
                  <p className="text-black">
                    Hi, {user && user?.first_name ? user.first_name : ""}
                  </p>
                  <p className="my-auto  text-[#4D4D4D] hidden sm:block mr-2">
                    {loggedin_username}
                  </p>
                </div>

                {userOptions ? (
                  <KeyboardArrowUpIcon
                    className="text-black hover:text-primaryDark-300"
                    onClick={() => toggleUserOptions(false)}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    className="text-black hover:text-primaryDark-300"
                    onClick={() => toggleUserOptions(true)}
                  />
                )}

                {userOptions ? (
                  <div
                    onMouseLeave={() => toggleUserOptions(false)}
                    className="absolute bg-[#fff] p-1 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] container mt-56 sm:px-4 h-auto right-0 z-10 mr-2 sm:w-[276px] origin-top-right bg-white/95 sm:bg-white ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <ProfileMenuList />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <>
              <button
                onClick={() => setSignInModalIsOpen(true)}
                className="bg-primaryDark-100 h-[42px] w-[154px] mx-auto pt-2 text-sm font-bold text-primaryLight-1000 p-2 pl-4 flex flex-row border-0 cursor-pointer bg-primary-300 text-gray-100/70 rounded-lg "
              >
                <div className="flex flex-row mx-auto">
                  <User className="mr-2" />
                  Sign Up
                </div>
              </button>
            </>
          ))}
        </div>
        <LoginModal
          isOpen={signInModalIsOpen}
          setIsOpen={setSignInModalIsOpen}
          closeAppModal={() => {
            setSignInModalIsOpen(false);
          }}
        />
        <FilterModal
          isOpen={filterSetting}
          setIsOpen={setFilterSetting}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          closeAppModal={() => setFilterSetting(false)}
        />
      </div>
    
  ) : (
    <div className="flex flex-col justify-center bg-primaryLight-1000 fixed w-full z-10 h-[182px]  font-open-sans cursor-pointer border-b-2">
        <div className="flex flex-col  bg-primaryLight-1000  w-full z-10  font-open-sans cursor-pointer items-baseline place-items-baseline justify-center border-b-2 h-[122px] align-baseline ">
            <div className="flex flex-row items-center w-11/12 sm:w-full m-auto justify-between ">
              <div className="flex flex-row   w-[168px] h-[62px] justify-between ">
                <img
                  src={homecook_logo}
                  alt="Kitchen Logo"
                  className="w-22 h-12 my-auto cursor-pointer"
                  onClick={() => navigate("/")}
                />
                <h1
                  className="text-primaryDark-0 text-xl mr-auto my-auto font-bold cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  HomeCook
                </h1>
              </div>
              {loggedin_username ? (
                <>
                  <div className="text-xs flex flex-row ml-auto text-black w-auto m-4 h-6 my-auto items-center ">
                    {/* <ProfileIcon className="ml-auto text-[#E6E6E6] w-auto m-auto h-8 my-auto cursor-pointer hover:text-primary-400 mr-2" /> */}
                    {user && user?.avatar ? (
                      <div className="flex border-[#E9E9E9] border-[1px] rounded-full h-9 w-9 mr-2">
                        <img
                          id="image-input-avatar"
                          src={user.avatar}
                          alt="User Avatar"
                          className="h-8 w-8  m-auto rounded-full"
                        />
                      </div>
                    ) : (
                      <ProfileIcon className="ml-auto text-[#E6E6E6] w-auto m-auto h-8 my-auto cursor-pointer hover:text-primary-400 mr-2" />
                    )}
                    <div className="flex flex-col">
                      <p className="text-black hidden sm:block">
                        Hi, {user && user?.first_name ? user.first_name : ""}
                      </p>
                      <p className="my-auto  text-[#4D4D4D] hidden sm:block mr-2">
                        {loggedin_username}
                      </p>
                    </div>
        
                    {userOptions ? (
                      <KeyboardArrowUpIcon
                        className="text-black hover:text-primaryDark-300"
                        onClick={() => toggleUserOptions(false)}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        className="text-black hover:text-primaryDark-300"
                        onClick={() => toggleUserOptions(true)}
                      />
                    )}
        
                    {userOptions ? (
                      <div
                        onMouseLeave={() => toggleUserOptions(false)}
                        className="absolute bg-[#fff] p-1 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)] container mt-56 sm:px-4 h-auto right-0 z-10 mr-40 sm:w-[276px] origin-top-right bg-white/95 sm:bg-white ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <ProfileMenuList />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <button
                    onClick={() => setSignInModalIsOpen(true)}
                    className="bg-primaryDark-100 h-[42px] w-[154px]  pt-2 text-sm font-bold text-primaryLight-1000 p-2 pl-4 flex flex-row border-0 cursor-pointer bg-primary-300 text-gray-100/70 rounded-lg "
                  >
                    <div className="flex flex-row mx-auto">
                      <User className="mr-2" />
                      Sign Up
                    </div>
                  </button>
                </>
              )}
            </div>
            
          </div>
          <div className="flex flex-row justify-center w-full gap-2">
              <NewSearch
                searchTerm={searchTerm}
                toggleFilterSetting={toggleFilterSetting}
                filterSetting={filterSetting}
                changeSearchTerm={changeSearchTerm}
                searchType={"resources"}
              />
              <div
                onClick={() => setFilterSetting(true)}
                className={`border-2 rounded-2xl w-10 h-10 flex items-center place-self-center hover:cursor-pointer ${
                  filterSetting
                    ? "bg-primaryDark-200 text-white "
                    : "bg-primaryLIght-1000 text-black"
                } `}
              >
                <Filter
                  className={`m-auto mt-2  ${
                    filterSetting ? " fill-black" : "fill-white"
                  } `}
                />
              </div>
            </div>{" "}
        <LoginModal
          isOpen={signInModalIsOpen}
          setIsOpen={setSignInModalIsOpen}
          closeAppModal={() => {
            setSignInModalIsOpen(false);
          }}
        />
        <FilterModal
          isOpen={filterSetting}
          setIsOpen={setFilterSetting}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          closeAppModal={() => setFilterSetting(false)}
        />
    </div>
  );
};

const ProfileMenuList = () => {
  const navigate = useNavigate();
  const { signOut } = useCompleteSignIn();

  return (
    <div className="py-1 text-center transition cursor-default w-auto my-auto mx-2">
      {profileMenuList &&
        profileMenuList.map((item, i) => {
          return (
            <div
              key={i}
              className={`flex flex-row text-[${item.color}] w-full my-4 font-raleway text-[14px] cursor-pointer hover:bg-slate-50 rounded-md`}
              style={{ color: item.color }}
              onClick={() => {
                if (item.name === "logout") {
                  signOut();
                  navigate("/");
                } else {
                  navigate(`/${item.name}`);
                }
              }}
            >
              <div className={`w-1/12 text-left mr-2 my-auto ${(item.name === 'logout') ? '' : ' text-primaryDark-0'}`}>
                <item.Icon />
              </div>

              <div className={`w-11/12 text-left my-auto font-semibold`}>
                {item.label}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Navbar;



