// import { useState } from "react"
import CloseIcon from '@mui/icons-material/Close';
import { usStates, USStatesEnum, USStatesCounties, businessStages } from "../utils/enums"
// import GoogleMaps from "../components/GoogleMaps";
import { FilterType } from "../data/types";
import { useResource } from '../contexts/appcontext';




type FilterOptionsProps = {
    currentFilter: FilterType
    setCurrentFilter: React.Dispatch<React.SetStateAction<FilterType>> | undefined
    closeFilterSetting: () => void
}

const FilterOptions: React.FC<FilterOptionsProps> = ({currentFilter, setCurrentFilter, closeFilterSetting}) => {

const { setGlobalFilter } = useResource()
//   const navigate = useNavigate();

// const [currentUSState, setCurrentUSState] = useState<string>(currentFilter && currentFilter.state ? currentFilter.state : 'DC')
// const [currentCounty, setCurrentCounty] = useState<string>(USStatesCounties[currentUSState] ? USStatesCounties[currentUSState][0] : '')
// const [currentCounty, setCurrentCounty] = useState<string>('')

const handleSubmitFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    // setCurrentUSState(e.target.value)
    // setCurrentCounty(USStatesCounties[e.target.value] ? USStatesCounties[e.target.value][0] : '')
    // setCurrentCounty('')
    const {name, value} = e.target
    if (name === 'county' && value && setCurrentFilter){
        setCurrentFilter(prev => ({...prev, county: value === 'statewide' ? '' : value}))
        if (setGlobalFilter){
            setGlobalFilter(prev => ({...prev, county: value === 'statewide' ? '' : value}))
        }
    } else if (name === 'state' && value && setCurrentFilter){
        // setCurrentFilter({state: value, county: USStatesCounties[value] ? USStatesCounties[value][0] : ''})
        setCurrentFilter({state: value === 'nationwide' ? '' : value, county: ''})
        if (setGlobalFilter){
            // setGlobalFilter({state: value, county: USStatesCounties[value] ? USStatesCounties[value][0] : ''})
            setGlobalFilter({state: value === 'nationwide' ? '' : value, county: ''})
        }
    } else if (name === 'stage' && value && setCurrentFilter){
        setCurrentFilter(prev => ({...prev, stage: value}))
        if (setGlobalFilter){
            setGlobalFilter(prev => ({...prev, stage: value}))
        }
    }
    
}
  
  return (
        <div className='flex flex-col justify-start w-full'>
            <div className="ml-auto text-gray-300 mt-10 mr-2 cursor-pointer" ><CloseIcon className='cursor-pointer' onClick={() => closeFilterSetting()} /></div>
            <p className="sm:text-xl text-md text-primaryDark-300 font-open-sans mr-auto m-5 w-full">Filter By</p>
            
{/* 
            <label className="flex flex-col w-10/12 mx-auto text-primaryDark-200 text-sm">
                Country
                <select className="formInput my-1" disabled>
                    <option>United States</option>
                </select>
            </label> */}

            {/* <div className="w-10/12 mx-auto">
                <GoogleMaps />
            </div>
             */}
            {
                (currentFilter?.state) && (<div>
                    <img alt={USStatesEnum[currentFilter.state].abbreviation} src={USStatesEnum[currentFilter.state]?.flagIcon} className='h-14 m-auto rounded-md' />
                </div>)
            }
            <label className="flex flex-col w-10/12 mx-auto text-primaryDark-200 text-sm">
                State
                <select name="state" value={currentFilter.state} onChange={handleSubmitFilter} className="formInput text-sm my-1">
                    <option value="">-- Select State --</option>
                    <option value="nationwide">All</option>
                    {
                        (usStates && usStates.length > 0) && usStates.map((state, i) => (
                            <option value={state} key={i}>{USStatesEnum[state]?.fullName}</option>
                        ))
                    }
                </select>
            </label>

            {
                ((USStatesCounties[currentFilter.state] && USStatesCounties[currentFilter.state].length > 0)) && (
                <label className="flex flex-col w-10/12 mx-auto text-primaryDark-200 text-sm my-3">
                    {['DC'].includes(currentFilter.state) ? 'Ward' : 'County'}
                    <select name='county' value={currentFilter.county} onChange={handleSubmitFilter} className="formInput text-sm my-1">
                        <option value="">-- Select {['DC'].includes(currentFilter.state) ? 'Ward' : 'County'} --</option>
                        <option value="statewide">All</option>
                        {
                             USStatesCounties[currentFilter.state].map((county, i) => (
                                <option value={county} key={i}>{county}</option>
                            ))
                        }
                    </select>
                </label>)
            }

            {/* {
                (!['DC'].includes(currentUSState) && (USStatesCounties[currentUSState] && USStatesCounties[currentUSState].length > 0)) && (
                <label className="flex flex-col w-10/12 mx-auto text-primaryDark-200 text-sm">
                    City
                    <select className="formInput my-1">
                        <option>-- Select City --</option>
                    </select>
                </label>)
            } */}
          <label className="flex flex-col w-10/12 mx-auto text-primaryDark-200 text-sm">
                Business Stage
                <select name="stage" value={currentFilter.stage} onChange={handleSubmitFilter} className="formInput text-sm my-1">
                    <option value="">-- Select Stage --</option>
                    {
                        (businessStages && businessStages.length > 0) && businessStages.map((stage, i) => (
                            <option value={stage.name} key={i}>{stage.description}</option>
                        ))
                    }
                </select>
            </label>
        </div>
    )
}

export default FilterOptions