import { useEffect } from 'react'
import Policy from '../../components/Policy'

const Eula = () => {
  useEffect(() => {
    document.title = `HomeCook | EULA`
  }, [])
  return (
    <div className='flex flex-col h-[900px] sm:h-screen justify-evenly bg-[#D9D9D9]'>
      <p className='text-md mx-auto mt-20 text-primaryDark-200 font-raleway'>End User License Agreement (EULA)</p>
      <Policy policyKey={process.env.REACT_APP_EULA_KEY} />
    </div>
  )
}

export default Eula