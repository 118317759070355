import { useNavigate } from "react-router-dom";

type NotFoundProps = {
  previousPath?: string
  dataName?: string
}

const NotFound:React.FC<NotFoundProps> = props => {
  var navigate = useNavigate();
  var { dataName, previousPath } = props
  return (
    <div className="flex flex-col align-middle text-center justify-center pt-40">
        <div className="flex flex-col space-y-5">
          <h1 className="text-6xl">404</h1>
          <h1 className="text-4xl">{dataName ? dataName : ''} Not Found</h1>
          <span className="cursor-pointer" onClick={() => {previousPath ? navigate(previousPath) : navigate(`/kitchens`)}}>
            <h2 className="text-2xl hover:text-primaryDark-300">Go Back Home</h2>
          </span>
        </div>
    </div>
  );
}


export default NotFound
