import React, { useState } from "react";
import NewSearch from "../components/NewSearch";
import {ReactComponent as Chatbot} from "../icons/Chatbot.svg"
import {ReactComponent as ArrowRight } from "../icons/RightArrow.svg";
import {ReactComponent as Staff } from "../icons/Staff.svg";

const capitalizeString = (string: string) =>
  string?.replace(/\w/, (c) => c?.toUpperCase());

type UserListWindowProps = {
   username: string
   choosingChat:boolean
   setChoosingChat: () => void;
   selectedChat: string
   setSelectedChat: React.Dispatch<React.SetStateAction<'chatbot' | 'staff'>>
}

const UserListWindow: React.FC<UserListWindowProps> = ({username, setChoosingChat, setSelectedChat}) => {
  const [searchTerm, changeSearchTerm] = useState("");
  const [filterSetting, setFilterSetting] = useState<boolean>(false);
  

  const toggleFilterSetting = () => {
    setFilterSetting(!filterSetting);
  }


  return (
    <div className="">
      <div className="flex flex-row z-0">
        <NewSearch
          searchTerm={searchTerm}
          toggleFilterSetting={toggleFilterSetting}
          filterSetting={filterSetting}
          changeSearchTerm={changeSearchTerm}
          searchType="messages"
        />
      </div>

      <div className="flex flex-col gap-3 p-1">
        <ChatUser
          currentChatCommunity="chatbot"
          member={username}
          allThreadsByCommunity={""}
          setSelectedChat = {setSelectedChat}
          setMessageOpen = {() => setChoosingChat()}
        />
        <ChatUser
        currentChatCommunity="staff"
        member={username}
        allThreadsByCommunity={""}
        setSelectedChat = {setSelectedChat}
        setMessageOpen = {() => setChoosingChat()}
        />
      </div>
    </div>
  );
};

type ChatUserProps = {
    member: string
    currentChatCommunity: 'chatbot' | 'staff'
    setSelectedChat: React.Dispatch<React.SetStateAction<'chatbot' | 'staff'>>
    allThreadsByCommunity: any
    setMessageOpen:any;

}

const ChatUser: React.FC<ChatUserProps> = ({member, currentChatCommunity, setSelectedChat,setMessageOpen}) => { 

    const handleClick = () => {
        if (setSelectedChat) {
          setSelectedChat(currentChatCommunity) 
        }
        setMessageOpen();
    }

    const get_primary_text = (member: string) => {
        return (
            <p className="font-open-sans font-extrabold text-[14px] text-[#2D3748]">{(member === 'chatbot') ? capitalizeString(member) : member}</p>
        )
    }

    const get_secondary_text = (message: string) => {
        return (
             <p className='text-[#7A7A7A] font-normal text-[10px]'>
                
                       <>
                           <span style={{color: '#000'}}>{message}</span>
                       </>
                   
               
             </p>
        )
    }

    if (currentChatCommunity !== 'chatbot'){
        return (
            <div style={{backgroundColor: '#fff'}} className={`flex  m-1 p-5 cursor-pointer h-[80px] rounded-[8px] border-2 justify-between`}
                    onClick={()=> {
                    handleClick()
                }}>
                    <div className="flex flex-row">
                        <Staff className={`h-[40px] w-[40px] ${(member === 'chatbot') ? 'text-[#51a0b5]/90' : 'text-[#013847]/90'}`} />
                        <div className="flex flex-col mx-4">
                            <div>{get_primary_text("Chat with a staff member")}</div>
                            <div>{get_secondary_text("Start a conversation")}</div>
                        </div>
                    </div>
                    <ArrowRight className="my-auto"/>
            </div>
        )
    } else {
        return(
        <div style={{backgroundColor: '#fff'}} className={`flex justify-between m-1 p-5 cursor-pointer h-[80px] rounded-[8px] border-2`}
            onClick={()=> {
                handleClick()
            }}>
            <div className="flex flex-row">
                <Chatbot className={`h-[40px] w-[40px] text-[#51a0b5]/90`} />
                <div className="flex flex-col mx-4">
                    <div>{get_primary_text('Chat with our chatbot')}</div>
                    <div>{get_secondary_text('Start a conversation')}</div>
                </div>
            </div>
            <ArrowRight className="my-auto"/>
        </div>
        )
    }
}

export default UserListWindow;
