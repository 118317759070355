import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

import { MembershipSchema, MessageSchema, GroupedMessageListType, CredentialSchema, CurrentRequirementsSchema, FilterOptionSchema, ResourceType } from '../data/types'
import { ReactComponent as VisaCardIcon } from '../icons/visaCardIcon.svg'
import { ReactComponent as MasterCardIcon } from '../icons/masterCardIcon.svg'
import { ReactComponent as AmexCardIcon } from '../icons/amexCardIcon.svg'

import inventoryCatalog from '../data/inventoryCatalog.json';


export const getActionButtonLabel = (resourceType: ResourceType) => {
  var actionButtonLabel = "Book"
  switch(resourceType){
    case 'kitchen':
      actionButtonLabel = 'Book'
      break
    case 'documentation':
      actionButtonLabel = 'Obtain'
      break
    case 'training':
      actionButtonLabel = 'Register'
      break
    case 'funding':
      actionButtonLabel = 'Apply'
      break
    case 'expert':
      actionButtonLabel = 'Schedule'
      break
    case 'farm':
      actionButtonLabel = 'Place Order'
      break
    default:
      actionButtonLabel = 'Book'
      break
  }
  return actionButtonLabel
}


export const formatDateString = (monthString?: number, yearString?: number): string => {
  if (monthString && yearString && yearString.toString().length === 4){
    return `${(monthString.toString().length < 2) ? `0${monthString}` : monthString}/${yearString.toString().slice(-2)}`
  } else if (yearString && yearString.toString().length === 4) {
    return yearString.toString().slice(-2);
  } else if (monthString && monthString.toString().length === 1){
    return `0${monthString}`
  }
  return ''
}


export const filterItemsByCategoryAndName = (data: FilterOptionSchema[], category: string, itemName: string): string[] => {
  const categoryData = data.find(cat => cat.category.toLowerCase() === category.toLowerCase());
  if (!categoryData) {
    return [];
  }
  return categoryData.items.filter(item => item.toLowerCase().includes(itemName.toLowerCase()));
}

export const searchInventoryCatalog = (category: string, query: string): string[] => {
  const inventoryItems: FilterOptionSchema[] = inventoryCatalog as FilterOptionSchema[];
  return filterItemsByCategoryAndName(inventoryItems, category, query)
}

export const getItemsByCategory = (data: FilterOptionSchema[], category: string): string[] => {
  const categoryData = data.find(cat => cat.category.toLowerCase() === category.toLowerCase());
  if (!categoryData) {
    return [];
  }
  return categoryData.items
}

export const listInventoriesByCategory = (category: string): string[] => {
  const inventoryItems: FilterOptionSchema[] = inventoryCatalog as FilterOptionSchema[];
  return getItemsByCategory(inventoryItems, category)
}

export const requirementCodes: string[] = [
  "FMP",
  "MFRST",
  "CLNRY",
  "PSTRT",
  "SSA",
  "SSALRGNS",
  "SSFHNDLR",
  "SSPRC",
  "SSI",
  "PIC Ohio",
  "SSWP",
  "SSMGR",
  "SMMR",
  "TXFG",
  "ID",
  "PID",
  "GLI",
  "HACCP",
];


export const fetchRequirementsLookUp = (memberCredentials: CredentialSchema[], paymentMethods: any[]): CurrentRequirementsSchema[] => {
  return [
      {
        requirementCode: "FMP",
        requirementName: "Foodservice Management Professional Certification",
        description: "",
        isMet: memberCredentials.some((item) => (item["category"] === "FMP" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "MFRST", 
        requirementName: "ManageFirst", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "MFRST" && item['is_valid'])) ,
        isRequired: false
      },
      {
        requirementCode: "CLNRY",
        requirementName: "National Restaurant Association Culinary Exams",
        description: "",
        isMet: memberCredentials.some((item) => (item["category"] === "CLNRY" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "PSTRT", 
        requirementName: "ProStart",  
        description: "",
        isMet: memberCredentials.some((item) => (item["category"] === "PSTRT" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "SSA", 
        requirementName: "ServSafe Alcohol", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSA" && item['is_valid'])),
        isRequired: false
      },
      {
        requirementCode: "SSALRGNS",
        requirementName: "ServSafe Allergens Online Course", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSALRGNS" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "SSFHNDLR", 
        requirementName: "ServSafe Food Handler", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSFHNDLR" && item['is_valid'])),
        isRequired: false
      },
      {
        requirementCode: "SSPRC",
        requirementName: "ServSafe Instructor and Proctor Certificates", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSPRC" && item['is_valid'])),
        isRequired: false
      },
      {
        requirementCode: "SSI",
        requirementName: "ServSafe International Food Safety", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSI" && item['is_valid'])),
        isRequired: false
      },
      {
        requirementCode: "PIC Ohio",
        requirementName: "ServSafe Person in Charge – Ohio",
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "PIC Ohio" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "SSWP", 
        requirementName: "ServSafe Workplace", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSWP" && item['is_valid'])),
        isRequired: false
      },
      {
        requirementCode: "SSMGR",
        requirementName: "ServSafe: Food Protection Manager Certification", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SSMGR" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "SMMR", 
        requirementName: "Summer Institutes", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "SMMR" && item['is_valid'])),
        isRequired: false
      },
      { 
        requirementCode: "TXFG", 
        requirementName: "Texas FoodGuard", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "TXFG" && item['is_valid'])), 
        isRequired: false
      },
      {
        requirementCode: "GLI", 
        requirementName: "General Liability Insurance", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "GLI" && item['is_valid'])), 
        isRequired: false
      },
      { 
        requirementCode: "HACCP", 
        requirementName: "HACCP Certificate", 
        description: "", 
        isMet: memberCredentials.some((item) => (item["category"] === "HACCP" && item['is_valid'])), 
        isRequired: false
      },
      { 
        requirementCode: "ID", 
        requirementName: "Government ID", 
        description: "Upload a government ID with your photo to verify your identity.", 
        isMet: memberCredentials.some((item) => (item["category"] === "ID" && item['is_valid'])), 
        isRequired: false  
      },
      { 
        requirementCode: "PID", 
        requirementName: "Photo ID", 
        description: "Upload a legal document with your photo to verify your identity.", 
        isMet: memberCredentials.some((item) => (item["category"] === "PID" && item['is_valid'])),
        isRequired: true
      },
      { 
        requirementCode: "PAY",
        requirementName: "Add your payment method", 
        description: "Add your preferred payment method for easy transactions.", 
        isMet: (paymentMethods && paymentMethods.length > 0) ? true : false, 
        isRequired: true
      }
  ]
}

// export const getDirectLinkFromGoogleDriveSharedLink = (sharedLink: string | undefined): string => {
//   // Regular expression to match Google Drive shared links
//   const regex = /https:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)\/view\?usp=sharing/i;
//   if (sharedLink){
//     const match = regex.exec(sharedLink);
//     if (!match) {
//       return ""; // Not a valid Google Drive shared link
//     }
//     const fileId = match[1]; // Extract the file ID from the match
//     // console.log("fileId", fileId)
//     // Potential direct link format (replace with your actual generation logic)
//     // You might need to use a Google Drive API or a third-party service to obtain the actual direct link based on the file ID.
//     // https://drive.usercontent.google.com/download?id=1EqddZcRTk9btkxAhWu3Y7osd0KrFdsYk&export=view&authuser=0
//     // const potentialDirectLink = `https://drive.google.com/uc?export=view&id=${fileId}`;
//     // https://drive.usercontent.google.com/download?id=1EqddZcRTk9btkxAhWu3Y7osd0KrFdsYk&export=view&authuser=0
//     // https://drive.usercontent.google.com/download?id=1EqddZcRTk9btkxAhWu3Y7osd0KrFdsYk&export=view
//     const potentialDirectLink = `https://drive.usercontent.google.com/download?id=${fileId}&export=view`;
  
//     return potentialDirectLink;
//   }
//   return ""
// }

export const handleGroupMessages = (allMessages: MessageSchema[], groupByField: string): GroupedMessageListType => {
  const groupMessages = allMessages.reduce((result: GroupedMessageListType, message: MessageSchema) => {
      // const { community_id } = message; // Destructure the object, excluding the 'username' property

      const groupByValue = message[groupByField as keyof MessageSchema] as string
      if (!result[groupByValue]) {
          result[groupByValue] = [message]; // Create an empty array for the username if it doesn't exist
      }
      result[groupByValue].push(message); // Add the object to the corresponding username array
      return result;
  }, {})
  return groupMessages
}

export const getDirectLinkFromGoogleDriveSharedLink = (link: string | undefined): string => {
  // Regular expressions to match S3 and Google Drive shared links
  // const s3Regex = /^https:\/w\/s3\.amazonaws\.com\/.+\/.+$/;
  const googleDriveRegex = /^https:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)\/view\?usp=sharing$/;
  // console.log("link", link)
  if (link && link.includes('s3')) {
    // If it matches the S3 regex, return the link as is
    // console.log("s3 link", link)
    return link;
  } else if (link && googleDriveRegex.test(link)) {
    // If it matches the Google Drive regex, convert to direct link format
    const match = link.match(googleDriveRegex);
    if (match) {
      const fileId = match[1];
      return `https://drive.google.com/uc?export=view&id=${fileId}`;
    }
  }

  

  // If neither regex matches, return an empty string
  return '';
}

const getDayName = (dateString: string): string => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day name
  return date.format('dddd');
}


const getDayOfMonth = (dateString: string): number  => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day of the month
  return date.date();
}


const getDayOfMonthWithSuffix = (dateString: string): string => {
  // Parse the date string
  const date = moment(dateString, 'YYYY-MM-DD');
  // Get the day of the month with ordinal suffix
  return date.format('Do');
}


const addDaysToToday = (days: number): string => {
  // Get today's date and add the specified number of days
  const newDate = moment().add(days, 'days');
  // Format the new date as YYYY-MM-DD
  return newDate.format('YYYY-MM-DD');
}

const calculateDateDifference = (fromDate: string, toDate: string, booking_recurrence: string) => {
  var nOccurrences = 0
  if (fromDate && toDate){
    // Parse the dates
    const start = moment(fromDate, 'YYYY-MM-DD');
    const end = moment(toDate, 'YYYY-MM-DD');
    switch(booking_recurrence){
      case 'daily':
        nOccurrences = end.diff(start, 'days') + 1;
        return nOccurrences
      case 'weekly':
        nOccurrences = end.diff(start, 'weeks');
        let startDay = getDayOfMonth(fromDate)
        let endDay = getDayOfMonth(toDate)
        if (endDay >= startDay){
          nOccurrences += 1
        }
        return nOccurrences
      case 'monthly':
        nOccurrences = end.diff(start, 'months') + 1;
        return nOccurrences
      default:
        return 1
    }
  }
  return nOccurrences
}


const maxHours = (capacity: number, recurrence: string | 'daily' | 'weekly' | 'monthly' | 'annual',  hours_left: number) => {
  if (recurrence && capacity){
    switch (recurrence){
      case 'daily':
        // 24 hrs
        hours_left = hours_left ? hours_left : Math.round(16 / capacity)
        return hours_left
      case 'weekly':
        // 168 hrs
        hours_left = hours_left ? hours_left : Math.round(115 / capacity)
        return hours_left
      case 'monthly':
        // 730 hrs
        hours_left = hours_left ? hours_left : Math.round(500 / capacity)
        return hours_left
      case 'yearly':
        // 8760 hrs
        hours_left = hours_left ? hours_left : Math.round(6000 / capacity)
        return hours_left
      default:
        return 0
    }
  }
  return 0
}


const objectToFormData = (obj: { [key: string]: any }): FormData =>  {
  const formData = new FormData();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      formData.append(key, value);
    }
  }
  return formData;
}

 // Define a function to find member by attribute value
 const findMemberByAttribute = (objects: MembershipSchema[], attribute: keyof MembershipSchema, value: any) => {
  for (const obj of objects) {
      if (obj[attribute] === value) {
          return obj;
      }
  }
  return {};
}


 // Define a function to find an object by attribute value
 export const isResourceSaved = <T>(resourceId: string, objects: T[]) => {
  for (const obj of objects) {
      const attribute = "resource_id" as keyof T 
      if (obj && obj[attribute] === resourceId) {
          return true
      }
  }
  return false
}


 // Define a function to find an object by attribute value
 const findObjectByAttribute = <T>(objects: T[], attribute: keyof T, value: string) => {
  for (const obj of objects) {
      if (obj[attribute] === value) {
          return obj;
      }
  }
  return {};
}


const getMembershipStatusColor = (status: string): {bgColor: string, primaryTextColor: string, secondaryTextColor: string} => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "active":
        return {bgColor: "#b9dc8d", primaryTextColor: '#4a5838', secondaryTextColor: '#819a62'};
      case "approved":
        return {bgColor: "#399f48", primaryTextColor: '#163f1c', secondaryTextColor: '#afd8b5'};
      case "verified":
        return {bgColor: "#F69E35", primaryTextColor: '#623f15', secondaryTextColor: '#fce1c2'};
      case "pending":
        return {bgColor: "#51a0b5", primaryTextColor: '#204048', secondaryTextColor: '#a8cfda'};
      case "declined":
        return {bgColor: "#CE5D5D", primaryTextColor: '#522525', secondaryTextColor: '#e6aeae'};
      case "inactive":
        return {bgColor: "#9a9a9a", primaryTextColor: '#3d3d3d', secondaryTextColor: '#d6d6d6'};
      // case "removed":
      //   return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
      default:
        return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
    }
  }
  return {bgColor: "#bbb", primaryTextColor: '#202124', secondaryTextColor: '#696969'};
};

// pending, declined, approved, verified, active, inactive, removed


const getBookingStatusColor = (status: string) => {
  if (status) {
    status = status.toLowerCase();
    switch (status) {
      case "pending":
        return {primaryTextColor: "#F69E35", bgColor: '#fef5ea', secondaryTextColor: '#fce1c2'};
      case "approved":
        return {primaryTextColor: "#399f48", bgColor: '#ebf5ec', secondaryTextColor: '#afd8b5'};
      case "in_progress":
        return {primaryTextColor: "#51a0b5", bgColor: '#edf5f7', secondaryTextColor: '#a8cfda'};
      case "canceled":
        return {primaryTextColor: "#CE5D5D", bgColor: '#faeeee', secondaryTextColor: '#e6aeae'};
      case "completed":
        return {primaryTextColor: "#013847", bgColor: '#e5ebec', secondaryTextColor: '#51a0b5'};
      default:
        return {primaryTextColor: "#202124", bgColor: '#e8e8e9', secondaryTextColor: '#696969'};
    }
  }
  return {primaryTextColor: "#202124", bgColor: '#e8e8e9', secondaryTextColor: '#696969'};
};


const lightenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) + percent);
  const G = (((color >> 8) & 0x00FF) + percent);
  const B = ((color & 0x0000FF) + percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


const darkenColor = (hex: string, percent: number): string => {
  const color = parseInt(hex.replace(/^#/, ''), 16);
  const R = ((color >> 16) - percent);
  const G = (((color >> 8) & 0x00FF) - percent);
  const B = ((color & 0x0000FF) - percent);
  const newColor = ((R << 16) | (G << 8) | B).toString(16);
  return `#${newColor.padStart(6, '0')}`;
}


const servSafeLookUp: {
  requirementCode: string;
  requirementName: string;
}[] = [
  {
    requirementCode: "FMP",
    requirementName: "Foodservice Management Professional Certification",
  },
  { requirementCode: "MFRST", requirementName: "ManageFirst" },
  {
    requirementCode: "CLNRY",
    requirementName: "National Restaurant Association Culinary Exams",
  },
  { requirementCode: "PSTRT", requirementName: "ProStart" },
  { requirementCode: "SSA", requirementName: "ServSafe Alcohol" },
  {
    requirementCode: "SSALRGNS",
    requirementName: "ServSafe Allergens Online Course",
  },
  { requirementCode: "SSFHNDLR", requirementName: "ServSafe Food Handler" },
  {
    requirementCode: "SSPRC",
    requirementName: "ServSafe Instructor and Proctor Certificates",
  },
  {
    requirementCode: "SSI",
    requirementName: "ServSafe International Food Safety",
  },
  {
    requirementCode: "PIC Ohio",
    requirementName: "ServSafe Person in Charge – Ohio",
  },
  { requirementCode: "SSWP", requirementName: "ServSafe Workplace" },
  {
    requirementCode: "SSMGR",
    requirementName: "ServSafe: Food Protection Manager Certification",
  },
  { requirementCode: "SMMR", requirementName: "Summer Institutes" },
  { requirementCode: "TXFG", requirementName: "Texas FoodGuard" },
  { requirementCode: "ID", requirementName: "Government ID" },
  { requirementCode: "PID", requirementName: "Photo ID" },
  { requirementCode: "GLI", requirementName: "General Liability Insurance" },
  { requirementCode: "HACCP", requirementName: "HACCP Certificate" },
];


type AnyObject = Record<string, any>; // A type for any object with string keys

const getChangedAttributes = <T extends AnyObject>(
  originalObj: T,
  newObj: T
): T => {
  const changedAttributes: T = {} as T;
  for (const key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      if (!originalObj.hasOwnProperty(key) || newObj[key] !== originalObj[key]) {
        changedAttributes[key] = newObj[key];
      }
    }
  }
  return changedAttributes;
}

const styleThousandCommas = (number: string | number) => {
  if (typeof number === 'number'){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if (typeof number === 'string'){
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return number
}

const getCreditCardIcon = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'visa':
      return VisaCardIcon
    case 'mastercard':
      return MasterCardIcon
    case 'amex':
      return AmexCardIcon
    // Add cases for other card types
    default:
      return null
  }
};


export const capitalizeString = (string: string) => string?.replace(/\w/, (c) => c?.toUpperCase());

export const capitalizeWords = (str: string): string => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const getUIDFromToken = (token: string) => {
  if (token){
      var user = JSON.parse(atob(token.split('.')[1]))
      return user.hasOwnProperty('uid') && user.hasOwnProperty('exp') ? user.uid : ""
  }
  return ""
}

export const getEmailFromToken = (token: string | null) => {
  if (token){
      var user = JSON.parse(atob(token.split('.')[1]))
      return user.hasOwnProperty('email') && user.hasOwnProperty('exp') ? user.email : ""
  }
  return ""
}

export const generateUUID = (): string => {
  return uuidv4();
}

export {
  servSafeLookUp,
  addDaysToToday,
  getDayName,
  getDayOfMonth,
  getDayOfMonthWithSuffix,
  calculateDateDifference,
  maxHours,
  // capitalizeString,
  getCreditCardIcon,
  findObjectByAttribute,
  findMemberByAttribute,
  objectToFormData,
  lightenColor,
  darkenColor,
  styleThousandCommas,
  getBookingStatusColor,
  getMembershipStatusColor,
  getChangedAttributes
}