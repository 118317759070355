import { useState } from "react";
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useGetPaymentMethods, useAddPaySetupIntent } from '../hooks/paymenthooks'
import config from '../config';


var webUrl = config?.webUrl

type PaymentFormProps = {
  isAddPayMethod?: boolean
  username: string
  setIsAddingPayMethod: React.Dispatch<React.SetStateAction<boolean>>
}

const PaymentForm: React.FC<PaymentFormProps> = ({isAddPayMethod, username, setIsAddingPayMethod}) => {
  const { reloadPaymentMethods } = useGetPaymentMethods(username)
  const { addSetupIntent } = useAddPaySetupIntent(username)
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [preferredMethod, setPreferredMethod] = useState(false)


  const handleSubmitPay = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError?.message) {
      setError(submitError.message);
      return;
    }
    const clientSecret = await addSetupIntent()
    if (clientSecret){
        // Use the clientSecret and Elements instance to confirm the setup
        const {error: confirmError} = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${webUrl}/wallet`,
          }
        });
        await reloadPaymentMethods()
        if (confirmError?.message) {
          setError(confirmError.message);
        }
    }
  };



  const handleAddPaymentMethod = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError?.message) {
      setError(submitError.message);
      return;
    }

    // Use the clientSecret and Elements instance to confirm the setup
    const clientSecret = await addSetupIntent()
    try {
      if (clientSecret){
        const {error: confirmError} = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${webUrl}/wallet`,
          },
          // Uncomment below if you only want redirect for redirect-based payments
          redirect: "if_required",
        });      
        if (confirmError?.message) {
          setError(confirmError.message);
        }
        await reloadPaymentMethods()
        setIsAddingPayMethod(false)
      } else {
        setError("Please click on submit button again");
      }

    } catch(err){
      console.log(err)
    }

  };

  return (
    <div className='flex flex-col w-full'>
        <PaymentElement className="text-[14px] text-[#4D4D4D] font-raleway" />
        {isAddPayMethod ? <span onClick={()=> setPreferredMethod(prev=> !prev)} className='w-full m-1 mt-6 text-[14px] font-raleway text-[#4D4D4D] cursor-pointer'>{preferredMethod ? (<CheckBox />): (<CheckBoxOutlineBlankIcon />)} Set as preferred payment method</span> : <></>}
        {
          (isAddPayMethod) ? (
            <button onClick={handleAddPaymentMethod} className="bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 py-[8px] px-[18px] w-[200px] my-4" disabled={!stripe}>Add Payment Method</button>
          ) : (
            <button onClick={handleSubmitPay} className={`buttonsPrimary border-0 mt-2 m-1 p-2 "order-1 !bg-primary-200 !text-back-400 text-sm`} disabled={!stripe}>Add Card</button>
          )
        }
        {error && <div className="flex w-full h-10 bg-red-100/90 text-red-800 text-xs font-open-sans rounded-sm p-1 m-1">{error}</div>}
        
    </div>
  )
};

export default PaymentForm;
