import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { ResourceSchema, ResourceType, ResourceBookingSchema, PlanSchema, MembershipSchema, BookingRecurrenceType  } from "../data/types";
import { usStates, USStatesEnum } from '../utils/enums';
import { useAddMembership, useCompleteSignIn, useLoadMember } from '../hooks/authhooks';
import { styleThousandCommas } from '../utils/helpers';
import { CheckCircle } from '@mui/icons-material';
// import { cp } from 'fs';




const phoneNumberStyling = (phoneNumber: string) => {
  if (phoneNumber) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '')
    var match = cleaned.match(/^(0-1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        if (phoneNumber.length === 10){
          phoneNumber = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        } else if (phoneNumber.length > 3 && phoneNumber.length < 7){
          phoneNumber = [intlCode, '(', match[2], ') ', match[3]].join('');
        } else if (phoneNumber.length < 4){
          phoneNumber = [intlCode, '(', match[2], ')'].join('');
        }
    }
  }
  return phoneNumber
}


// const MEMBERSHIP_KEYS = Object.keys({} as MembershipSchema) as (keyof MembershipSchema)[];
// const filterMembershipFields = (data: any): Partial<MembershipSchema> => {
//   if (data === null || typeof data !== 'object') {
//     return [] as Partial<MembershipSchema>
//   }
//   const membershipKeys = Object.keys(data).filter(key => 
//     MEMBERSHIP_KEYS.includes(key as typeof MEMBERSHIP_KEYS[number])
//   );
//   return membershipKeys.reduce((acc, key) => ({
//     ...acc,
//     [key]: data[key]
//   }), {});
// };


type RequestMembershipFormProps = {
  resourceDetail: ResourceSchema
  // plans: any[]
  handleClose: () => void;
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
};
  
const RequestMembershipForm: React.FC<RequestMembershipFormProps> = (props) => {

  const navigate = useNavigate();
    var { resourceDetail, handleClose } = props;
    const { user } = useCompleteSignIn();
    // const existingBusinesses: any[] = []
    const currentResourcePlans = resourceDetail?.plans ? resourceDetail.plans : []
    // const [selectedBusiness, setSelectedBusinesses] = useState<string>("");

    const [submitted, setSubmitted] = useState<boolean>(false)

    const defaultMembership: MembershipSchema = {
      first_name: user?.first_name ? user.first_name : "",
      last_name: user?.last_name ? user.last_name : "",
      email: user?.email ? user.email : "",
      phone: user?.phone ? user.phone : "",
      state: user?.state ? user.state : "",
      zipcode: user?.zipcode ? user.zipcode : ""
    }

    const [newMembership, setNewMembership] = useState<MembershipSchema>(defaultMembership);

    const communityId = resourceDetail?.community_id ? resourceDetail.community_id : ""
    const resourceId = resourceDetail?.id ? resourceDetail.id : ""
    const { addMembership } = useAddMembership(communityId)

    const handleSubmitNewMembership = async () => {    
      if (
        newMembership?.first_name && newMembership?.last_name && 
        newMembership?.email && newMembership?.zipcode && 
        newMembership?.plan_id && communityId
      ){
        if (newMembership?.phone){
          newMembership.phone = (newMembership.phone.length === 10 && !newMembership.phone.includes('+1')) ? `+1${newMembership.phone}` : ""
        }
        newMembership.resource_id = resourceId
        addMembership(newMembership)
        setNewMembership(defaultMembership)
        setSubmitted(true)
        // handleClose()
      }
    }  

    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   const { name, value } = event.target;
    //   if (name && value) {
    //     setApplication((prev => ({
    //       ...prev,
    //       [name]: value
    //     })));
    //   }
    // };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (name && (value || value === "")) {
        if (name === 'phone'){
          const phone = ('' + value).replace(/\D/g, '')
          if ((!isNaN(parseInt(phone)) && phone.length <= 10) || phone === ""){
            // setMemberPhone(value)  
            setNewMembership((prev) => ({
              ...prev,
              [name]: value
            }))
          } 
        } else {
          if (name === 'first_name'){
            // setFirstName(value)
          } else if (name === 'last_name'){
            // setLastName(value)
          } else if (name === 'email'){
            // setMemberEmail(value)
          } else if (name === 'zipcode'){
            // setUserZipCode(value)
          }
          
          setNewMembership((prev) => ({
            ...prev,
            [name]: value
          }))
        }
      }
    };



    const handleSelectInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault()
      const {name, value} = e.target
      if (name && value){
        if (name === 'state'){
          // setUserUSState(value)
          setNewMembership((prev) => ({
            ...prev,
            [name]: value
          }))
        } else if (name === 'plan'){
          let plan = currentResourcePlans.find(plan => (plan?.plan_id && (plan.plan_id === value))) as PlanSchema
          var { plan_id,  plan_name, plan_description, plan_recurrence, plan_rate } = plan
          // setSelectedPlan(plan)
          setNewMembership((prev) => ({
            ...prev,
            plan_id,  
            plan_name, 
            plan_description, 
            plan_recurrence: plan_recurrence as BookingRecurrenceType, 
            plan_rate: (plan_rate || plan_rate === 0) ? plan_rate : 0
          }))
        }
         // else if (name === 'businessId'){
        //   setSelectedBusinesses(value)
        // } 
      }
    }


    const { reloadCurrentMember } = useLoadMember(communityId)

    const handleViewCurrentStatus = () => {
      reloadCurrentMember()
      setSubmitted(false)
    }


    if (submitted){
      return (
        <SubmittedLogin
          resourceName={resourceDetail?.name as string} 
          handleViewCurrentStatus={handleViewCurrentStatus}
          handleClose={handleClose}
        />
      )
    } else {
      return (
        <div className="flex flex-col justify-evenly items-center h-full">
          <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
              <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
          </span>

          {/* <div className='h-[4px] border-0 bg-primaryDark-400 w-11/12 my-4' /> */}
          <img src={resourceDetail?.logo ? resourceDetail.logo : "/logo192.png"} alt="homecook-logo" className="h-[60px] self-center mb-4 m-1 rounded-lg"/>
          
          <p className='text-black font-medium text-[24px] font-raleway text-center w-11/12'>
            Request Access to <span className='font-bold'>{resourceDetail.name}</span>
          </p>
          <p className="text-center text-[16px] my-2 font-open-sans font-medium text-[#333333] w-11/12">Fill out the details below to began your membership application.</p>

          <div className='border-[1px] border-[#E9E9E9] p-1 rounded-lg w-11/12'>
            <div className="flex flex-col mx-auto w-11/12 my-4">
              <div className='flex flex-row w-full'>
                <div className='w-1/2 mr-1'>
                  <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                    First Name
                  </label>
                  <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                      <input
                          type="text"
                          placeholder="Enter First Name"
                          className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                          name="first_name"
                          value={newMembership?.first_name ? newMembership.first_name : ""}
                          disabled={(user?.first_name) ? true : false}
                          onChange={handleInputChange}
                      />
                  </div>
                </div>

                <div className='w-1/2 ml-1'>
                  <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                    Last Name
                  </label>
                  <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                      <input
                          type="text"
                          placeholder="Enter Last Name"
                          className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                          name="last_name"
                          value={newMembership?.last_name ? newMembership.last_name : ""}
                          disabled={user?.last_name ? true : false}
                          onChange={handleInputChange}
                      />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mx-auto w-11/12 my-4">
                <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                  Email
                </label>
                <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                    <input
                        type="text"
                        placeholder="Enter Email Address"
                        className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                        name="email"
                        value={newMembership?.email ? newMembership.email : ""}
                        disabled={(user?.email) ? true : false}
                        onChange={handleInputChange}
                    />
                </div>
            </div>


            <div className="flex flex-col mx-auto w-11/12 my-4">
                <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                  Phone
                </label>
                <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                    <input
                        type="tel"
                        placeholder="Enter Phone Number"
                        className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                        name="phone"
                        // value={memberPhone}
                        value={phoneNumberStyling(newMembership?.phone ? newMembership.phone : "")}
                        disabled={(user?.phone) ? true : false}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="flex flex-col mx-auto w-11/12 my-4">
              <div className='flex flex-row w-full'>
                <div className='w-1/2 mr-1'>
                  <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                    State
                  </label>
                  <div className='my-2'>
                      <select 
                        name="state" 
                        value={newMembership?.state ? newMembership.state : ""}  
                        onChange={handleSelectInput}
                        disabled={(user?.state) ? true : false}
                        className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-[#E9E9E9] border-[1px] rounded-md "
                      >
                          <option value="">-- Select your state --</option>
                          {
                              (usStates && usStates.length > 0) && usStates.map((state: string, i: number) => (
                                  <option value={USStatesEnum[state]?.abbreviation} key={i}>{USStatesEnum[state]?.fullName}</option>
                              ))
                          }
                      </select>
                  </div>
                </div>

                <div className='w-1/2 ml-1'>
                  <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                    Zipcode
                  </label>
                  <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                      <input
                          type="text"
                          placeholder="Enter Zipcode"
                          className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                          name="zipcode"
                          value={newMembership?.zipcode ? newMembership.zipcode : ""}
                          disabled={(user?.zipcode) ? true : false}
                          onChange={handleInputChange}
                      />
                  </div>
                </div>
              </div>

              <p onClick={()=>navigate('/profile')} className='text-primaryDark-300 cursor-pointer ml-auto'>Update Profile Information</p>
            </div>


            {/* <div className="flex flex-col mx-auto w-11/12 my-4">
                <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                  Registered Business
                </label>
                <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                    <select
                        className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                        name="businessId"
                        value={selectedBusiness}
                        onChange={handleSelectInput}
                    >
                      <option value="">
                          -- Select Existing Business --
                      </option>

                      {
                        existingBusinesses.map((data: any, i: number)=>
                          (
                            <option value={i} key={i}>
                                {data.category}
                            </option>
                          )
                        )
                      }
                    </select>
                </div>
                <p className='text-primaryDark-300 cursor-pointer ml-auto'>+ Add New Business</p>
            </div> */}

            <div className="flex flex-col mx-auto w-11/12 my-4">
                <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                  Membership Plan
                </label>
                <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                    <select
                        className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                        name="plan"
                        value={newMembership?.plan_id ? newMembership.plan_id : ""}
                        onChange={handleSelectInput}
                    >

                      <option value="">
                          -- Select Plan --
                      </option>

                      {
                        currentResourcePlans.map((plan, i)=>
                        (
                          <option value={plan.plan_id} key={i}>
                              {plan.plan_name}
                          </option>
                        ))
                      }
                    </select>
                </div>

                <div className="flex justify-center p-1 m-1 text-[16px]">
                  {
                    (newMembership && newMembership.hasOwnProperty("plan_name") && newMembership.plan_name) ? (
                      <>
                      <b>{newMembership.plan_name.toUpperCase()}:</b> &nbsp; ${styleThousandCommas(newMembership?.plan_rate ? newMembership.plan_rate : "")} ({newMembership.plan_recurrence})
                      </>
                    ) : (
                      <></>
                    )
                  }
                </div>
            </div>

        
          </div>
          <div className='flex flex-row my-2 w-11/12'>
            <button
                className="py-2 w-[217px] mr-auto font-bold text-[18px] font-raleway text-primaryDark-0 border-[#E9E9E9] border-[1px]"
                data-testid="cancel-button"
                onClick={handleClose}
              > 
                    Cancel  
            </button>
            <button
                className="py-2 w-[217px] ml-auto font-bold text-[18px] font-raleway border-0 bg-primaryDark-0 text-white"
                onClick={handleSubmitNewMembership}
                data-testid="submit-button"> 
                    Submit  
            </button>
          </div>
    
        </div>
      );
    }
  };

  export default RequestMembershipForm



  type SubmittedLoginProps = {
    resourceName: string
    handleClose: () => void
    handleViewCurrentStatus: () => void
  }

  const SubmittedLogin: React.FC<SubmittedLoginProps> = ({resourceName, handleClose, handleViewCurrentStatus}) => {  
    return (
      <div className="flex flex-col justify-evenly items-center h-full my-2">
        <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
            <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>
      <div className="flex items-center justify-center rounded-full p-3 border-[#E6E6E6] border-[1px] w-[64px] h-[64px] mx-auto">
          <CheckCircle style={{fontSize: 32}} className="m-auto" />
        </div>
        
        <h1 className="text-md text-primaryDark-200 font-bold my-2 font-raleway text-[24px] text-center w-11/12">Membership Request Successful!</h1>
        <p className="text-[16px] text-center my-2 text-[#333333] font-open-sans w-11/12">
          Thank you for choosing to use <b>{resourceName}</b>! Your request has been successfully received. You will receive an email with the details and next steps shortly.
        </p>
        <button 
          onClick={handleViewCurrentStatus}
          className={`w-11/12 h-[54px] bg-[#013847] hover:bg-[#E6E6E6] text-white font-bold border-0 text-[18px] my-4`}
        >
          
          Check Membership Status
        </button>
      </div>
    )
  }
  

