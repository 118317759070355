import React, { useState } from "react";
import {
  Box,
  Typography,
  // FormGroup,
  // FormControlLabel,
  // Button,
  Stack,
  // Checkbox,
  // TextField,
} from "@mui/material";

import CancelIcon from '@mui/icons-material/Cancel';
import { useLoadAuthUser } from "../hooks/authhooks";
import CustomTextField from "../components/CustomTextField";
import { AuthSchema } from "../data/types"
import { ReactComponent as SentEmailIcon } from "../icons/SentEmailIcon.svg";

type LoginProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Login: React.FC<LoginProps> = ({ setIsOpen }) => {

  const { registerUser } =  useLoadAuthUser()
  const [email, setEmail] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  return (
    <div
      // onMouseLeave={() => setIsOpen(false)}
      className="py-1 text-center transition cursor-default mx-2"
    >
      <span onClick={() => setIsOpen(false)} className="ml-auto w-full flex cursor-pointer">
        <CancelIcon className="text-[#A1A1A1] hover:text-[#777] my-2 h-[18px] w-[18px] ml-auto" />
      </span>
      {
        (submitted) ? (
        <SubmittedLogin submitted={submitted} email={email} setEmail={setEmail} registerUser={registerUser} setSubmitted={setSubmitted} />
      ) : (
        <UnSubmittedLogin submitted={submitted} email={email} setEmail={setEmail} registerUser={registerUser} setSubmitted={setSubmitted} />
      )
      }
  </div>
  );
};



type UnSubmittedLoginProps = {
  email: string
  submitted: boolean
  setEmail: React.Dispatch<React.SetStateAction<string>>
  registerUser: (user: AuthSchema) => boolean
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

const UnSubmittedLogin: React.FC<UnSubmittedLoginProps> = ({email, submitted, setEmail, registerUser, setSubmitted}) => {
  return (
    <>
    <h1 className="text-md text-primaryDark-200 font-bold my-2 font-raleway text-[24px] text-left">Sign Up</h1>
      <Stack className="w-full">
        {
          submitted ? (
            <p className="text-green-700 text-sm">Check Your Email for Login Link</p>
          ) : (<></>)
        }

        <p className="text-[14px] text-left mb-2 text-[#333333] font-open-sans">
          Enter your email below, and we'll send you a link to complete your sign-up and access more features.
        </p>

        <p className="text-[16px] text-left my-1 text-[#000000] font-open-sans">
          Email Address
        </p>
        <Box className="h-15 w-full">
          <Typography
            variant="subtitle2"
            fontWeight={600}
            component="label"
            htmlFor="email"
            mb="5px"
          >
            {/* Email */}
          </Typography>
          <CustomTextField disabled={submitted} size="small" type="email" variant="outlined" className="w-full border-[#E9E9E9]" placeholder="Enter your email address" fullWidth onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}  />
        </Box>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          my={1}
        >
        </Stack>
      </Stack>
      <Box>
      <button 
        onClick={() => {
          registerUser({email})
          setSubmitted(true)
        }}
        disabled={submitted} 
        className={`!mt-0 w-full h-[54px] ${(submitted) ? 'bg-[#E6E6E6]' : 'bg-[#013847] hover:bg-[#E6E6E6]' } text-white font-bold border-0 text-[18px]`}
      >
        Send Email Link
      </button>
    </Box>
    </>
  )
}



const SubmittedLogin: React.FC<UnSubmittedLoginProps> = ({email, registerUser}) => {
  const [submitted, setSubmitted] = useState<boolean>(false)

  return (
    <>
    <div className="rounded-full p-3 border-[#E6E6E6] border-[1px] w-[64px] h-[64px] mx-auto">
      <SentEmailIcon className="m-auto h-[32px] w-[32px]" />
    </div>
    
    <h1 className="text-md text-primaryDark-200 font-bold my-2 font-raleway text-[24px] text-center">You're Almost There!</h1>
      <p className="text-[16px] text-left my-2 text-[#333333] font-open-sans">
        Check your email for the sign-up link. If you don’t see it in your inbox, be sure to check your spam or junk folder.
      </p>
      <button 
        onClick={() => {
          registerUser({email})
          setSubmitted(true)
        }}
        disabled={submitted} 
        className={`!mt-0 w-full h-[54px] ${(submitted) ? 'bg-[#E6E6E6]' : 'bg-[#013847] hover:bg-[#E6E6E6]' } text-white font-bold border-0 text-[18px]`}
      >
        
        {
          (submitted) ? 'Resent Link' : 'Resend Link'
        }
      </button>
    </>
  )
}

export default Login;
