import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { ResourceSchema, ResourceType, ResourceBookingSchema  } from "../data/types";
import { foodBusinessCategories } from '../utils/enums';




  type ObtainDocumentationFormProps = {
    resourceDetail: ResourceSchema
    handleClose: () => void;
    handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
};
  
const ObtainDocumentationForm: React.FC<ObtainDocumentationFormProps> = (props) => {
    var { resourceDetail, handleSubmitRequest, handleClose } = props;
  
    const [application, setApplication] = useState<ResourceBookingSchema>({} as ResourceBookingSchema);
    const [businessCategory, setBusinessCategory] = useState<number>(0);
    const [businessTypes, setBusinessTypes] = useState<string[]>(foodBusinessCategories[businessCategory].types);
    const [businessType, setbusinessType] = useState<string>(businessTypes[0]);
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (name && value) {
        setApplication((prev => ({
          ...prev,
          [name]: value
        })));
      }
    };

    const selectBusinessCategories = (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault()
      const {name, value} = e.target
      if (name === 'category' && value){
        const categoryIndex = parseInt(value)
        const categoryObject = foodBusinessCategories[categoryIndex]
        setBusinessCategory(categoryIndex)
        setApplication((prev => ({
          ...prev,
          business_category: categoryObject.category,
        })));
        setBusinessTypes(categoryObject.types)
      } else if (name === 'business_type' && value){
        setbusinessType(value)
        setApplication((prev => ({
          ...prev,
          business_type: value,
        })));
      }
      
    }
  
    const handleSubmit = () => {
      if (application && resourceDetail && resourceDetail.resource_type) {
        handleSubmitRequest(resourceDetail, resourceDetail.resource_type, application);
        handleClose();
      }
    };
  
    return (
      <div className="flex flex-col justify-evenly items-center h-full">
        <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
            <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>

        <div className='h-[4px] border-0 bg-primaryDark-400 w-11/12 my-4' />
        
        <p className='text-black font-medium text-[24px] font-raleway text-left w-11/12'>
        Obtain your <span className='font-bold'>{resourceDetail.name}</span>
        </p>
        <p className="text-left text-[16px] my-2 font-open-sans font-medium text-[#333333] w-11/12">Fill out the details below to obtain documentation for your business.</p>

        <div className='border-[1px] border-[#E9E9E9] p-1 rounded-lg w-11/12'>
          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                Business Category
              </label>
              <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                  <select
                      className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                      name="category"
                      value={businessCategory}
                      onChange={e => {
                        selectBusinessCategories(e)
                      }}
    
                  >

                    {
                      foodBusinessCategories.map((data: any, i: number)=>
                        (
                          <option value={i} key={i}>
                              {data.category}
                          </option>
                        )
                      )
                    }
                  </select>
              </div>
          </div>

          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                Type of Business
              </label>
              <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                  <select
                      className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                      name="business_type"
                      value={businessType}
                      onChange={selectBusinessCategories}
                  >

                    {
                      businessTypes.map((type: any, i: number)=>
                      (
                        <option value={type} key={i}>
                            {type}
                        </option>
                      ))
                    }
                  </select>
              </div>
          </div>

          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                Business Name
              </label>
              <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                  <input
                      type="text"
                      placeholder="Business Name"
                      className="formInput m-auto w-[430px] h-[59px] mx-auto bg-inherit p-3 border-0"
                      name="business_name"
                      onChange={handleFileChange}
                  />
              </div>
          </div>


          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                Address
              </label>
              <div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                  <input
                      type="text"
                      placeholder="Business Address"
                      className="formInput m-auto w-[430px] h-[59px] mx-auto bg-inherit p-3 border-0"
                      name="location"
                      onChange={handleFileChange}
                  />
              </div>
          </div>
        </div>
        <div className='flex flex-row my-2 w-11/12'>
          <button
              className="py-2 w-[217px] mr-auto font-bold text-[18px] font-raleway text-primaryDark-0 border-[#E9E9E9] border-[1px]"
              data-testid="cancel-button"
              onClick={handleClose}
            > 
                  Cancel  
          </button>
          <button
              className="py-2 w-[217px] ml-auto font-bold text-[18px] font-raleway border-0 bg-primaryDark-0 text-white"
              onClick={handleSubmit}
              data-testid="submit-button"> 
                  Submit  
          </button>
        </div>
   
      </div>
    );
  };

  export default ObtainDocumentationForm

