import React from "react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as KitchenImage } from "../icons/kitchenImage.svg";
import { ReactComponent as LocationImage } from "../icons/locationIcon.svg";

import { ReactComponent as KitchenIcon } from '../icons/KitchenIcon.svg'
import { ReactComponent as CredentialIcon } from '../icons/credentialIcon.svg'
import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'
import { ReactComponent as DocumentationIcon } from '../icons/documentationIcon.svg'
import { ReactComponent as FundingIcon } from '../icons/fundingIcon.svg'
import { ReactComponent as ExpertIcon } from '../icons/expertIcon.svg'
import { ReactComponent as HeartIcon } from "../icons/HeartIcon.svg";
import { ReactComponent as LocationIcon } from "../icons/locationIcon.svg";

// import { useUpdateAccountDetails } from "../hooks/authhooks";

import { SavedResourceCardProps } from "../data/types";
import { capitalizeString, getDirectLinkFromGoogleDriveSharedLink } from '../utils/helpers';



const SavedResourceCard: React.FC<SavedResourceCardProps> = ({
  resource,
  handleRemoveSavedResource,
  isMobile
  // resourceIcon: ResourceIcon,
}) => {

  const navigate = useNavigate();


  var ResourceIcon = OrderIcon
  const resourceType = resource?.resource_type ? resource.resource_type : ""

  switch(resourceType){
    case 'kitchen':
      ResourceIcon = KitchenIcon
      break;

    case 'documentation':
      ResourceIcon = DocumentationIcon
      break;

    case 'training':
      ResourceIcon = CredentialIcon
      break;

    case 'funding':
      ResourceIcon = FundingIcon
      break;

    case 'expert':
      ResourceIcon = ExpertIcon
      break;

    case 'farm':
      ResourceIcon = OrderIcon
      break;

    default:
      ResourceIcon = OrderIcon
  }


  const getBgColor = () => {
    return "#fff";
  };

  const getPrimaryTextColor = () => {
    return "#202124";
  };

  const getSecondaryTextColor = () => {
    return "#8A8A8A";
  };


  const handleCardClick = async () => {
    if (resourceType && resource?.resource_id){
      navigate(`/${resourceType}/${resource.resource_id}`)
    }
  }

  const openInNewTab = (url: string) => {
    if (url){
      window.open(url, "_blank");
    }
  };

  if (isMobile){
    return (
        <div 
          className={`flex flex-col min-h-[200px] object-cover rounded-xl border-[1px] border-[#E9E9E9] w-full shadow-sm items-center text-start p-4 cursor-pointer bg-gradient-to-a hover:bg-gradient-to-b from-[#f9f9f9] mt-5`} 
          style={{backgroundColor: getBgColor(), opacity: 0.9}} 
        >

            <div className='flex flex-row '>
              {
                resource.active && (<div className='flex my-2 w-[86px] rounded-2xl bg-[#E7F5EC]'>
                  <p className='m-auto text-[#0F973D] text-[14px] font-raleway font-normal'>Available</p>
                </div>)
              }
            </div>

            <div className='flex flex-col m-2 w-full'>
              <div className='flex flex-row'>
                <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md mr-auto my-2'>
                  {
                    getDirectLinkFromGoogleDriveSharedLink(resource?.logo) ? (
                      <img className=' h-10 aspect-auto object-contain' src={getDirectLinkFromGoogleDriveSharedLink(resource.logo)} alt={resource?.id} /> 
                      ) : (
                        <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                        )
                  }
                </div>
                <div className='flex border-0  bg-[#5DB6CE] text-[14px] h-[44px] ml-auto rounded-xl text-white'>
                  <div className='flex flex-row text-white px-3 cursor-pointer' onClick={() => handleRemoveSavedResource(resource.id)}>
                    <HeartIcon className='text-white w-[16px] h-[15px] my-auto' />
                    <p className='mx-2 my-auto font-bold'>Saved</p>
                  </div>
                </div>
              </div>
                <p 
                  className='font-raleway font-bold px-2 text-[18px] my-1 text-primaryDark-0 '
                >
                  {resource.name}
                </p>
                <p className='px-2 font-raleway text-[14px] flex flex-row pt-1 text-[#8A8A8A] mb-4'>
                  <LocationIcon className="mr-1 mt-[1px] w-[16px] h-[16px] text-gray" />
                  {resource && resource.address ? `${resource.address}, `: ``} 
                  {resource && resource.city ? `${resource.city}, `: ``} 
                  {resource && resource.state ? `${resource.state} `: ``}  
                  {resource && resource.zipcode ? `${resource.zipcode} `: ``}  
    
                  {
                    (resource && 
                      !(resource.address && resource.city && resource.state && resource.zipcode)
                    ) ? `Nationwide`: ``
                  }
                </p>
            </div>
         
            <button 
              onClick={() => openInNewTab(resource?.website ? resource.website : '')}
              className={`text-primaryDark-0 bg-white text-[14px] h-[51px] rounded-lg font-bold border-[1px] border-[#E9E9E9] w-full mb-3 'h-auto'}`}
            >
              Visit Website
            </button>

            <button onClick={handleCardClick} className={`text-white bg-primaryDark-0 text-[14px] h-[51px] rounded-lg font-bold border-0 w-full mb-3 ${(resource?.name && resource.name?.length <= 35) ? 'h-[51px]': 'h-auto'}`}>
              View {capitalizeString(resource.name ? resource.name : resourceType)}
            </button>
        </div>
        )
  } 
  else {
    return (
      <div
        className={`flex flex-col ${resource.active ? 'h-[140px] ' : 'h-[120px] sm:h-[112px]'} sm:w-[1152px]  object-cover rounded-xl border-2  mx-auto text-start p-4 cursor-pointer bg-gradient-to-a hover:bg-gradient-to-b from-[#f9f9f9] mt-5 `}
        style={{ backgroundColor: getBgColor(), opacity: 0.9 }}
      >
      
          <div className='flex flex-row '>
            {
              resource.active && (
                <div className='flex my-2 w-[86px] rounded-2xl bg-[#E7F5EC] mt-auto'>
                  <p className='m-auto text-[#0F973D] text-[14px] font-raleway font-normal'>Available</p>
                </div>
              )
            }
          </div>

          <div className='flex flex-row my-auto'>
            <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md'>
              {
                getDirectLinkFromGoogleDriveSharedLink(resource?.logo) ? (
                  <img className=' h-10 aspect-auto object-contain' src={getDirectLinkFromGoogleDriveSharedLink(resource.logo)} alt={resource?.id} /> 
                  ) : (
                    <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                    )
              }
            </div>

            <div className="flex flex-col w-2/3 m-2">
              <p 
                className="font-open-sans font-bold px-2 text-sm sm:text-md"
                style={{ color: getPrimaryTextColor() }}
              >
                {resource.name}
              </p>
              <p
                className="px-2 font-open-sans text-xs sm:text-sm flex flex-row pt-1"
                style={{ color: getSecondaryTextColor(), opacity: 0.8 }}
              >
                <LocationImage className="mr-1 mt-[1px] w-[16px] h-[16px] text-gray" />
                {resource && resource.address ? `${resource.address}, ` : ``}
                {resource && resource.city ? `${resource.city}, ` : ``}
                {resource && resource.state ? `${resource.state} ` : ``}
                {resource && resource.zipcode ? `${resource.zipcode} ` : ``}

                {!resource ||
                (resource && !(
                  resource.address ||
                  resource.city ||
                  resource.state ||
                  resource.location ||
                  resource.zipcode
                ))
                  ? `Nationwide`
                  : ``}
              </p>
          
            </div>
      
            <div className="flex flex-row gap-2 ml-auto">
              <div className='flex flex-row text-primaryDark-400 px-3 cursor-pointer' onClick={() => handleRemoveSavedResource(resource.id)}>
                <HeartIcon className='text-primaryDark-400 w-[16px] h-[15px] my-auto' />
                <p className='mx-2 my-auto'>Saved</p>
              </div>
              <button onClick={handleCardClick} className="text-primaryDark-0 font-bold border-[1px] border-grayDark-100 w-[148px] text-[14px]">
                View {capitalizeString(resourceType)}
              </button>
            </div>
          </div>
      </div>
    );
  }
};

export default SavedResourceCard;
