import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import {  OrderItemSchema } from "../data/types";

import { ReactComponent as KitchenIcon } from '../icons/KitchenIcon.svg'
import { ReactComponent as CredentialIcon } from '../icons/credentialIcon.svg'
import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'
import { ReactComponent as DocumentationIcon } from '../icons/documentationIcon.svg'
import { ReactComponent as FundingIcon } from '../icons/fundingIcon.svg'
import { ReactComponent as ExpertIcon } from '../icons/expertIcon.svg'
import { capitalizeString, getDirectLinkFromGoogleDriveSharedLink } from '../utils/helpers';
import { AddCircleOutline, CheckCircle, Delete, RemoveCircleOutline } from '@mui/icons-material';


type CartItemProps = {
  index: number
  item: OrderItemSchema 
  setIsEditingQty: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const CartItem: React.FC<CartItemProps> = ({index, item, setIsEditingQty})=> {

  var ResourceIcon = OrderIcon
  const resourceType = item?.resource_type ? item.resource_type : ""

  switch(resourceType){
    case 'kitchen':
      ResourceIcon = KitchenIcon
      break;

    case 'documentation':
      ResourceIcon = DocumentationIcon
      break;

    case 'training':
      ResourceIcon = CredentialIcon
      break;

    case 'funding':
      ResourceIcon = FundingIcon
      break;

    case 'expert':
      ResourceIcon = ExpertIcon
      break;

    case 'farm':
      ResourceIcon = OrderIcon
      break;

    default:
      ResourceIcon = OrderIcon
  }

  const getPrimaryTextColor = () => {
    return "#202124";
  };


  return (
    <div className='flex flex-row my-2 mx-1 w-full text-[14px] font-open-sans'>
      <div className='w-1/12 mr-1'>{index+1}.</div>
      {/* <div className='w-3/12 mx-1'>{capitalizeString(item.item_name)}</div> */}
      <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md my-auto'>
        {
          getDirectLinkFromGoogleDriveSharedLink(item?.image_url) ? (
            <img className=' h-10 aspect-auto object-contain my-auto' src={getDirectLinkFromGoogleDriveSharedLink(item.image_url)} alt={item?.id} /> 
            ) : (
              <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 my-auto'} />
              )
        }
      </div>

      <div className="flex flex-col w-1/3 m-2 my-auto">
        <p 
          className="font-bold px-2 text-sm sm:text-md my-auto"
          style={{ color: getPrimaryTextColor() }}
        >
          {item.item_name}
        </p>
  
    
      </div>
      <div className=' w-3/12 my-auto text-gray-400'>{capitalizeString(item.category)}</div>
      <div className='flex w-3/12 my-auto'>{item.total_quantity} (Qty)</div>
      <div className='flex w-3/12 my-auto'>${item?.unit_price ? Number((item.total_quantity * item.unit_price).toFixed(2)) : 0}</div>
      <div className=' mx-1 text-gray-400 cursor-pointer my-auto' onClick={()=>setIsEditingQty(item?.id)} >Edit</div>
    </div>
  )
}




type EditCartItemProps = {
  index: number
  item: OrderItemSchema 
  setIsEditingQty: React.Dispatch<React.SetStateAction<string | undefined>>
  handleUpdateCartItem: (item: OrderItemSchema, quantity: number) => void
}


export const EditCartItem: React.FC<EditCartItemProps> = ({index, item, setIsEditingQty, handleUpdateCartItem})=> {
  const [qty, setQty] = useState<number>(item.total_quantity)

  return (
    <div className='flex flex-row my-2 mx-1 w-full text-[14px] '>
      <div className='w-1/12 mr-1 my-auto'>{index+1}.</div>
      <div className='w-3/12 mx-1 my-auto'>{capitalizeString(item.item_name)} (${item?.unit_price ? Number((item.total_quantity * item.unit_price).toFixed(2)) : 0})</div>
      <div className=' w-3/12 my-auto'>{capitalizeString(item.category)}</div>
      <div className='flex w-3/12 my-auto '>
        <label className='my-auto'>
          <input 
            name="total_quantity"
            value={qty}
            type='number' 
            min={0} 
            placeholder='0' 
            className='border-[1px] border-[#E9E9E9] rounded-lg w-1/3 mr-1' 
            onChange={e=>setQty(parseFloat(e.target.value))}
          />
          (Qty)
        </label>
      </div>
      <div 
        className=' mx-1 text-red-400 cursor-pointer my-auto ml-auto'
        onClick={()=> {
          handleUpdateCartItem(item, 0)
          setIsEditingQty(undefined)
        }}
      >
          <Delete />
      </div>
      <div 
        className=' mx-1 text-primaryDark-200 cursor-pointer my-auto' 
        onClick={()=> {
          handleUpdateCartItem(item, qty)
          setIsEditingQty(undefined)
        }}
      >
          <CheckCircle />
      </div>
    </div>
  )
}



type CartItemCardProps = {
  index: number
  item: OrderItemSchema 
  // setIsEditingQty: React.Dispatch<React.SetStateAction<string | undefined>>
  handleUpdateCartItem: (item: OrderItemSchema, quantity: number) => void
}

export const CartItemCard: React.FC<CartItemCardProps> = ({item, handleUpdateCartItem})=> {

  var ResourceIcon = OrderIcon
  const resourceType = item?.resource_type ? item.resource_type : ""
  // const [qty, setQty] = useState<number>(item.total_quantity)

  const handleChangeQty = (quantity: number) => {
    if (quantity && item.available_quantity && (quantity <= item.available_quantity)){
      handleUpdateCartItem(item, quantity)
    }
    
  }

  const handleRemoveItem = () => {
      handleUpdateCartItem(item, 0)  
  }

  switch(resourceType){
    case 'kitchen':
      ResourceIcon = KitchenIcon
      break;

    case 'documentation':
      ResourceIcon = DocumentationIcon
      break;

    case 'training':
      ResourceIcon = CredentialIcon
      break;

    case 'funding':
      ResourceIcon = FundingIcon
      break;

    case 'expert':
      ResourceIcon = ExpertIcon
      break;

    case 'farm':
      ResourceIcon = OrderIcon
      break;

    default:
      ResourceIcon = OrderIcon
  }

  const getPrimaryTextColor = () => {
    return "#202124";
  };

  const currentQuantity = item?.total_quantity ? item.total_quantity : 0


  return (
    <div className='flex flex-row my-2 mx-1 w-full h-[250px] text-[14px] font-open-sans border-[#E9E9E9] border-[1px] rounded-xl p-2 '>


      <div className='w-2/12  my-auto'>
        {
          getDirectLinkFromGoogleDriveSharedLink(item?.image_url) ? (
            <img className=' h-[200px] aspect-auto object-contain my-auto' src={getDirectLinkFromGoogleDriveSharedLink(item.image_url)} alt={item?.id} /> 
            ) : (
              <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 my-auto'} />
              )
        }
      </div>

      <div className="flex flex-col w-9/12 m-2 my-auto">
        {
          (item.resource_name) && (
            <p className='font-raleway text-[14px] text-[#808080]'>
              <b>Resource: </b>{item.resource_name}
            </p>
          )
        }
        <p 
          className="font-bold font-raleway text-[20px] my-auto"
          style={{ color: getPrimaryTextColor() }}
        >
          {item.item_name}
        </p>

        <div className=' my-auto text-[16px] text-[#4D4D4D] font-open-sans'>{capitalizeString(item.category)}</div>
        
        <hr className='text-[#E9E9E9] h-[1px] my-2'/>
        <div className='flex flex-row flex-start w-full'>
          <div className='flex text-[20px] font-semibold font-raleway my-auto'>${item?.unit_price ? Number((item.total_quantity * item.unit_price).toFixed(2)) : 0}</div>
          <div className='mx-4 text-[16px] text-[#0F973D] font-open-sans font-semibold my-auto'>{item.available_quantity} Unit{(item.available_quantity === 1) ? '' : 's'} Left</div>
          
          <label className='my-auto ml-auto w-3/12'>
            <RemoveCircleOutline className=' text-[#E9E9E9] cursor-pointer mx-2' onClick={()=>handleChangeQty(currentQuantity - 1)} />
            <input 
              name="total_quantity"
              value={currentQuantity}
              type='number' 
              min={0} 
              placeholder='0' 
              className='border-[1px] border-[#E9E9E9] rounded-lg w-1/3 mx-1' 
              onChange={e=>handleChangeQty(parseFloat(e.target.value))}
            />
            <AddCircleOutline className=' text-[#E9E9E9] cursor-pointer mx-2' onClick={()=>handleChangeQty(currentQuantity + 1)} />
          </label>
  
        </div>
        <hr className='text-[#E9E9E9] h-[1px] my-2'/>
      </div>

      <span onClick={handleRemoveItem} className="ml-auto flex cursor-pointer m-2">
        <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
      </span>
    </div>
  )
}


export default CartItemCard