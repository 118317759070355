import {useEffect, useState} from 'react'
//import { useCompleteSignIn } from '../../hooks/authhooks'
import { ReactComponent as CreatePostCircle } from "../../icons/createPostCircle.svg";
import { ReactComponent as PostLike } from "../../icons/PostLike.svg";
import { ReactComponent as PostComment } from "../../icons/PostComment.svg";
import { ReactComponent as PostShare } from "../../icons/PostShare.svg";
import { ReactComponent as PostExit } from "../../icons/PostModalExit.svg";
import { ReactComponent as UploadImage } from "../../icons/feedUploadImage.svg";
import { useFeedPosts, useCompleteSignIn, useAddPost } from '../../hooks/authhooks'
import { FeedPostSchema } from '../../data/types';
import moment from 'moment-timezone';



const Feed = () => {
  const { user } = useCompleteSignIn()
  const loggedinUsername = user && user?.username ? user.username : "";
  const { feedPosts } = useFeedPosts();
  const { addNewPost } = useAddPost(loggedinUsername);

  const [width, setWidth] = useState(window.innerWidth);
  const [isPostModalOpen, setPostModal] = useState(false);
  const [newPost, setNewPost] = useState<FeedPostSchema>({content:'', resource_type:"kitchen"});

  //const [postContent, setPostContent] = useState<string>('');

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `HomeCook | Feed`
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const togglePostModal = () => {
    setPostModal((prev) => !prev);
  };

  const handleSubmitPost = () => {
    addNewPost(newPost);
    setPostModal(false);
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    e.preventDefault();
    const { name , value } = e.target;
    setNewPost(
      prev => (
        {
          ...prev,
          [name]:value
        }
      )
    );

  }


  const isMobile = width < 1024;

  if (isMobile){
    return (
      <>
      <div className={`w-full left-0 background-flex font-open-sans h-screen overflow-y-scroll relative top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
          <div className="flex flex-col mx-auto sm:h-screen w-1/5 bg-[#FFFFFF] rounded-xl mt-24">
            
          </div>
      </div>
      </>
    )
  } else {
    return (
      <>
      <div className={`w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative top-0 bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
        <div className={`flex flex-col w-[282px] h-[520px] left-32 top-20 relative bg-white rounded-lg`}>
          <div className="flex justify-center">
          <button
            onClick={togglePostModal}
            className="w-5/6 mt-5 h-[44px] flex items-center justify-center gap-2 font-raleway rounded-md font-bold text-white bg-[#013847]"
          >
            <CreatePostCircle className="w-[20px] h-[20px]" />
            Create Post
          </button>
          </div>
          <hr className="h-px mt-5 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <h1 className="font-raleway text-[#808080] text-[16px] ml-6 mt-5">Resource Categories</h1>
          
          <div className="flex flex-col justify-center items-center">
            <button
              className="w-5/6 mt-5 h-[44px] pl-0 text-sm flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">All</h1>
            </button>
            <button
              className="w-5/6 mt-1.5 h-[44px] pl-0 text-sm flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">Local Kitchen Spaces</h1>
            </button>
            <button
              className="w-5/6 mt-1.5 h-[44px] pl-0 text-sm flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">Local Fresh Food</h1>
            </button>
            <button
              className="w-5/6 mt-1.5 h-[44px] pl-0 text-xs flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">Required Documentation</h1>
            </button>
            <button
              className="w-5/6 mt-1.5 h-[44px] pl-0 text-sm flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">Culinary Training</h1>
            </button>
            <button
              className="w-5/6 mt-1.5 h-[44px] pl-0 text-sm flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">Industry Experts</h1>
            </button>
            <button
              className="w-5/6 mt-1.5 h-[44px] pl-0 text-sm flex items-center font-raleway rounded-md font-bold text-[#4D4D4D] border-2 border-[#E9E9E9]"
            >
              <h1 className="pl-6">Funding Opportunities</h1>
            </button>
          </div>
        </div>

        {isPostModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-[478px] h-[700px] p-8 relative">
            <h2 className="text-lg font-black mb-4 font-raleway mt-[20px]">Create a Post</h2>
            <h3 className="text-sm font-light mb-2 font-open-sans text-[#333333] mt-[-10px]">Fill out the details below to create a post.</h3>

            <PostExit
              onClick={togglePostModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer"
            />
            
            <div>
              <h3 className="text-sm font-semibold mb-2 font-raleway text-[#333333] mt-[30px]">Resource Category</h3>
              <select name="resource_type" value={newPost.resource_type || ""} onChange={handleChangeInput} className="w-full h-[40px] mb-[-10px] p-2 border font-open-sans text-sm rounded-md border-[#E9E9E9]">
                <option value="" className="font-open-sans p-3 text-sm text-[#E9E9E9]">Select a resource category</option>
                <option value="farm">Local Fresh Food</option>
                <option value="kitchen">Local Kitchen Spaces</option>
                <option value="training">Culinary Training</option>
                <option value="expert">Industry Mentors & Experts</option>
                <option value="funding">Funding & Financial Resources</option>
                <option value="documentation">Required Documentation & Compliance</option>
              </select>
              <h3 className="text-sm font-semibold mb-2 font-raleway text-[#333333] mt-[30px]">Post Description</h3>
              <textarea
                name="content"
                value={newPost.content || ""}
                placeholder="Enter your post title"
                className="w-full h-[120px] mb-[-10px] p-3 border font-open-sans text-sm rounded-md border-[#E9E9E9]"
                onChange = {handleChangeInput}
              />
              <h3 className="text-sm font-semibold mb-2 font-raleway text-[#333333] mt-[30px]">Post Image (Optional)</h3>
              <div className="flex space-x-0">
                <div
                  className="w-1/2 h-[150px] border-2 rounded-md border-dotted border-[#E9E9E9] bg-[#f5f3f387] mb-5 flex flex-col items-center justify-center"
                >
                  <UploadImage className="w-[64px] h-[64px] mt-2" />
                  <h1 className="font-raleway font-semibold text-md mt-2 text-center">
                    Drag and Drop or tap to Browse
                  </h1>
                </div>
                <div className="w-1/2 h-[150px] rounded-md border-dotted mb-5 flex flex-col items-start mt-2 pl-5">
                  <h1 className="font-open-sans text-md mt-2 text-left">
                    No image added
                  </h1>
                  <button className="w-3/4 font-raleway font-black text-sm mt-2 bg-[#013847] text-white py-2 rounded">Upload Image</button>
                </div>
              </div>
              <div className="w-full bg-[#E9E9E9] h-[1px] mb-6"></div>
              <button
                type="submit"
                onClick={handleSubmitPost}
                className="w-full font-raleway font-black font-md bg-[#013847] text-white py-2 rounded"
              >

                Create Post
              </button>
            </div>
          </div>
        </div>
      )}
  

        <div className='flex flex-col w-full'>
        <div className="ml-36 mt-16"> 
          {feedPosts && feedPosts.length > 0 ? (
            [...feedPosts]
              .sort((a, b) => {
                const dateA = a.created_at ? new Date(a.created_at).getTime() : 0; 
                const dateB = b.created_at ? new Date(b.created_at).getTime() : 0; 
                return dateB - dateA; 
              })
              .map((post, i) => (
                <FeedPostCard key={i} postData={post} />
              ))
          ) : (
            <div>No posts</div> 
          )}
        </div>

        </div>


      </div>
      </>
    )
  }
}


type FeedPostCardProps = {
  postData: FeedPostSchema
}

const FeedPostCard: React.FC<FeedPostCardProps> = ({ postData }) => {
  return (
    <div className="flex flex-col my-4">
      <div className="flex flex-col w-4/5 h-[250px] bg-white hover:bg-[#fafafa] rounded-xl p-4 cursor-pointer">
       
        <div className="flex items-center">
          <div className="bg-gray-400 rounded-full w-12 h-12" />
          <div className="ml-4">
            <h1 className="text-black font-raleway">{postData?.username || 'Cant find user'}</h1>
            <h3 className="text-[#4D4D4D] text-sm font-raleway">
            {postData?.last_updated
              ? moment.utc(postData.last_updated).tz('America/New_York').startOf('second').fromNow()
              : 'Can’t find last updated'}
            </h3>
          </div>
        </div>

        
        <p className="mt-4 p-2">{postData?.content || 'Cant find content'}</p>

        
        <div className="flex justify-around items-center mt-12 border-t pt-7">
          
          <div className="flex items-center ml-4">
            <PostLike className="w-[25px] h-[25px]" />
            <p className="ml-2 text-[16px] font-raleway text-[#4D4D4D]">Like (0)</p>
          </div>

          <div className="flex h-[30px] w-[1px] bg-[#E9E9E9]" />
          
          <div className="flex items-center">
            <PostComment className="w-[25px] h-[25px]" />
            <p className="ml-2 text-[16px] font-raleway text-[#4D4D4D]">Comment (0)</p>
          </div>

          <div className="flex h-[30px] w-[1px] bg-[#E9E9E9]" />
         
          <div className="flex items-center">
            <PostShare className="w-[25px] h-[25px]" />
            <p className="ml-2 text-[16px] font-raleway text-[#4D4D4D]">Share (0)</p>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Feed;