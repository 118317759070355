// import { useState } from "react";
import { Skeleton } from "@mui/material";


const NavbarSkeleton = () => {

  return (
    <div className="flex flex-row">
        <Skeleton variant="circular" width={35} height={35} />
        <div className="flex flex-col gap-1">
            <Skeleton variant="rounded" width={30} height={15} />
            <Skeleton variant="rounded" width={120} height={15} />
        </div>
    </div>
  );
};

export default NavbarSkeleton;
