import React from "react";
// import { useNavigate } from "react-router-dom";
// import { ReactComponent as KitchenImage } from "../icons/kitchenImage.svg";
// import { ReactComponent as LocationImage } from "../icons/locationIcon.svg";

import { ReactComponent as KitchenIcon } from '../icons/KitchenIcon.svg'
import { ReactComponent as CredentialIcon } from '../icons/credentialIcon.svg'
import { ReactComponent as OrderIcon } from '../icons/OrderIcon.svg'
import { ReactComponent as DocumentationIcon } from '../icons/documentationIcon.svg'
import { ReactComponent as FundingIcon } from '../icons/fundingIcon.svg'
import { ReactComponent as ExpertIcon } from '../icons/expertIcon.svg'
// import { ReactComponent as HeartIcon } from "../icons/HeartIcon.svg";
import { ReactComponent as LocationIcon } from "../icons/locationIcon.svg";

// import { useUpdateAccountDetails } from "../hooks/authhooks";

import { BookedResourceCardProps } from "../data/types";
import { capitalizeWords, getBookingStatusColor, getDirectLinkFromGoogleDriveSharedLink } from '../utils/helpers';
// import moment from "moment";
import moment from 'moment-timezone';




const BookedResourceCard: React.FC<BookedResourceCardProps> = ({
  booking,
  // handleRemoveSavedResource,
  isMobile
  // resourceIcon: ResourceIcon,
}) => {

  // const navigate = useNavigate();


  var ResourceIcon = OrderIcon
  const resourceType = booking?.resource_type ? booking.resource_type : ""

  switch(resourceType){
    case 'kitchen':
      ResourceIcon = KitchenIcon
      break;

    case 'documentation':
      ResourceIcon = DocumentationIcon
      break;

    case 'training':
      ResourceIcon = CredentialIcon
      break;

    case 'funding':
      ResourceIcon = FundingIcon
      break;

    case 'expert':
      ResourceIcon = ExpertIcon
      break;

    case 'farm':
      ResourceIcon = OrderIcon
      break;

    default:
      ResourceIcon = OrderIcon
  }


  const getBgColor = () => {
    return "#fff";
  };

  const getPrimaryTextColor = () => {
    return "#202124";
  };

  // const getSecondaryTextColor = () => {
  //   return "#8A8A8A";
  // };


  // const handleCardClick = async () => {
  //   if (resourceType && booking?.resource_id){
  //     navigate(`/${resourceType}/${booking.resource_id}`)
  //   }
  // }

  // const openInNewTab = (url: string) => {
  //   if (url){
  //     window.open(url, "_blank");
  //   }
  // };

  var dateFieldName = "Applied"
  switch(resourceType){
    case 'documentation':
      dateFieldName = "Submitted On"
      break
    case 'kitchen':
      dateFieldName = "Date"
      break
    case 'funding':
      dateFieldName = "Applied"
      break
    case 'farm':
      dateFieldName = "Ordered On"
      break
    case 'training':
      dateFieldName = "Training Date"
      break
    case 'expert':
      dateFieldName = "Session Date"
      break
    default:
      dateFieldName = "Applied"
      break
  }


  var dateFieldValue = booking.created
  switch(resourceType){
    case 'documentation':
      dateFieldValue = booking.created
      break
    case 'kitchen':
      dateFieldValue = booking.start_time
      break
    case 'funding':
      dateFieldValue = booking.created
      break
    case 'farm':
      dateFieldValue = booking.created
      break
    case 'training':
      dateFieldValue = booking.start_time
      break
    case 'expert':
      dateFieldValue = booking.start_time
      break
    default:
      dateFieldValue = booking.created
      break
  }


  var buttonName = "Application"
  switch(resourceType){
    case 'documentation':
      buttonName = "Submission"
      break
    case 'kitchen':
      buttonName = "Booking"
      break
    case 'funding':
      buttonName = "Application"
      break
    case 'farm':
      buttonName = "Order"
      break
    case 'training':
      buttonName = "Registration"
      break
    case 'expert':
      buttonName = "Appointment"
      break
    default:
      buttonName = "Application"
      break
  }

  var bookingStatusColor: any = getBookingStatusColor(booking?.status ? booking.status : '')
  const bookingStatusBgColor = bookingStatusColor.bgColor
  bookingStatusColor = bookingStatusColor.primaryTextColor

  if (isMobile){
    return (
        <div className={`flex flex-col min-h-[200px] object-cover rounded-xl border-[1px] border-[#E9E9E9] w-full shadow-sm items-center text-start p-4 cursor-pointer bg-gradient-to-a hover:bg-gradient-to-b from-[#f9f9f9] mt-5`} 
            style={{backgroundColor: getBgColor(), opacity: 0.9}} >

            <div className='flex flex-col m-2 w-full'>
              <div className='flex flex-row'>
                <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md mr-auto my-2'>
                  {
                    getDirectLinkFromGoogleDriveSharedLink(booking?.resource_logo) ? (
                      <img className=' h-10 aspect-auto object-contain' src={getDirectLinkFromGoogleDriveSharedLink(booking.resource_logo)} alt={booking?.id} /> 
                      ) : (
                        <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                        )
                  }
                </div>
                <div className='flex border-0  bg-[#5DB6CE] text-[14px] h-[44px] ml-auto rounded-xl text-white'>
                  {/* <div className='flex flex-row text-white px-3 cursor-pointer' onClick={() => handleRemoveSavedResource(booking.id)}>
                    <HeartIcon className='text-white w-[16px] h-[15px] my-auto' />
                    <p className='mx-2 my-auto font-bold'>Saved</p>
                  </div> */}
                </div>
              </div>
                <p 
                  className='font-raleway font-bold px-2 text-[18px] my-1 text-primaryDark-0 '
                >
                  {booking.resource_name}
                </p>
                <p className='px-2 font-raleway text-[14px] flex flex-row pt-1 text-[#8A8A8A] mb-4'>
                  <LocationIcon className="mr-1 mt-[1px] w-[16px] h-[16px] text-gray" />
                  {/* {resource && resource.address ? `${resource.address}, `: ``}  */}
                  {booking && booking.resource_city ? `${booking.resource_city}, `: ``} 
                  {booking && booking.resource_state ? `${booking.resource_state} `: ``}  
                  {/* {resource && resource.zipcode ? `${resource.zipcode} `: ``}   */}

                  {
                    (booking && 
                      !(booking.resource_city && booking.resource_state)
                    ) ? `Nationwide`: ``
                  }
                </p>
            </div>
         

        </div>
        )
  } 
  else {
    return (
      <div
        className={`flex flex-row h-[120px] sm:w-[1152px] sm:h-[112px] object-cover rounded-xl border-2  w-10/12 mx-auto items-center justify-between text-start p-4 cursor-pointer bg-gradient-to-a hover:bg-gradient-to-b from-[#f9f9f9] mt-5 `}
        style={{ backgroundColor: getBgColor(), opacity: 0.9 }}
      >
      

          <div className='p-2 border-[#E9E9E9] w-16 border-[1px] rounded-md'>
            {
              getDirectLinkFromGoogleDriveSharedLink(booking?.resource_logo) ? (
                <img className=' h-10 aspect-auto object-contain' src={getDirectLinkFromGoogleDriveSharedLink(booking.resource_logo)} alt={booking?.id} /> 
                ) : (
                  <ResourceIcon className={'relative m-1 w-10 aspect-square p-1 text-[#5DB6CE]/60 '} />
                  )
            }
          </div>

          <div className="flex flex-col w-2/3 m-2">
  
            <p 
              className="font-open-sans font-bold px-2 text-sm sm:text-md"
              style={{ color: getPrimaryTextColor() }}
            >
              {booking.resource_name}
            </p>
            <p className='px-2 font-raleway text-[14px] flex flex-row pt-1 text-[#8A8A8A] mb-4'>
              <LocationIcon className="mr-1 mt-[1px] w-[16px] h-[16px] text-gray" />
              {booking && booking.resource_city ? `${booking.resource_city}, `: ``} 
              {booking && booking.resource_state ? `${booking.resource_state} `: ``}  

              {
                (booking && 
                  !(booking.resource_city && booking.resource_state)
                ) ? `Nationwide`: ``
              }
            </p>
            <p className="font-open-sans font-bold px-2 text-sm text-primaryDark-300">
              <b className="text-[#8A8A8A]">{dateFieldName}: </b>{
                moment(dateFieldValue).tz('America/New_York').format('ddd MMM DD, YYYY | hh:mm a')
              }
            </p>
          </div>
    
          <div className="flex flex-col  ml-auto ">
            {
              (booking.status) && (
                <div 
                  className={`ml-auto font-raleway text-[14px] font-bold border-[1px] rounded-md p-1 my-2`}
                  style={{
                    borderColor: bookingStatusColor,
                    color: bookingStatusColor,
                    backgroundColor: bookingStatusBgColor
                  }}
                >
                  {capitalizeWords(booking.status.replace(/_/g, ' '))}
                </div>
              )
            }

            { (booking.status !== 'canceled' && booking.status !== 'completed') && (
              <div className="flex flex-row gap-2">
                <button className={`text-white bg-primaryDark-0 text-[14px]  font-bold border-0 `}>
                  Modify
                </button>

                  <button className="text-primaryDark-0 text-[14px] font-bold border-[1px] border-grayDark-100 ">
                    Cancel {buttonName}
                  </button>
                  
                
              </div>
              )
            }

          </div>
      </div>
    );
  }
};

export default BookedResourceCard;
