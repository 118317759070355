import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { ResourceSchema, ResourceType,  ResourceBookingSchema, ResourceInventorySchema, InventorySchema, OrderItemSchema } from "../data/types";
import { useCompleteSignIn } from '../hooks/authhooks'
import { useGetPaymentMethods } from '../hooks/paymenthooks'
import { capitalizeString, formatDateString } from '../utils/helpers';
// import { CheckCircle, Delete } from '@mui/icons-material';
import { CartItem, EditCartItem } from '../components/CartItemCard';


type OrderInventoryFormProps = {
    resourceDetail: ResourceSchema
    handleClose: () => void;
    handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
};
  
const OrderInventoryForm: React.FC<OrderInventoryFormProps> = (props) => {
    var { resourceDetail, handleClose } = props;

    const { user, cartItems, updateCart } = useCompleteSignIn();
    const loggedin_username = (user && user?.username) ? user.username : ''

    var { paymentMethods } = useGetPaymentMethods(loggedin_username)

    const [selectedPayMethod, setSelectedPayMethod] = useState<string>("");
    const [isEditingQty, setIsEditingQty] = useState<string>()

    const inventoryCatalogItems = (resourceDetail  && resourceDetail?.resource_inventory) ? resourceDetail.resource_inventory :  {} as ResourceInventorySchema
    const catalogOptions: string[] = Object.keys(inventoryCatalogItems)
    const catalogOption: string = catalogOptions ? catalogOptions[0] : ""
    const availableItems = inventoryCatalogItems[catalogOption]
  
    const handleSubmitOrder = () => {
      // if (application) {
        handleClose();
      // }
    };

    const isAdded = (inventoryItem: InventorySchema): boolean => {
      if (availableItems && cartItems){
        return cartItems.some(item => {
          item = item as OrderItemSchema
          return (
            item.item_name === inventoryItem.item_name
          )
        })
      }
      return false
    }

    const handleAddToCart = (inventoryItem: any, quantity: number = 1)=> {
      if (inventoryItem && quantity){
        const orderItem = inventoryItem as OrderItemSchema
        orderItem.total_quantity = quantity
        orderItem.available_quantity = inventoryItem.available_quantity
        orderItem.inventory_id = inventoryItem.id
        orderItem.resource_name = resourceDetail.name
        updateCart(orderItem)
      }
    }


    const handleUpdateCartItem = (item: OrderItemSchema, quantity: number = 0)=> {
      updateCart(item, quantity)
    }

  
    return (
      <div className="flex flex-col justify-evenly items-center h-full">
        <span onClick={handleClose} className="ml-auto w-full flex cursor-pointer">
            <CancelIcon className="text-[#A1A1A1] hover:text-[#777] h-[18px] w-[18px] ml-auto" />
        </span>

        <div className='h-[4px] border-0 bg-primaryDark-400 w-11/12 my-4' />
        
        <p className='text-black font-medium text-[24px] font-raleway text-left w-11/12'>
          Get Order from <span className='font-bold'>{resourceDetail.name}</span> 
        </p>
        <p className="text-left text-[16px] my-2 font-open-sans font-medium text-[#333333] w-11/12">Fill out the details below to order fresh produce</p>

        <div className='border-[1px] border-[#E9E9E9] p-1 rounded-lg w-11/12'>
          <div className="flex flex-col mx-auto w-11/12 my-4">
            <InventoryItems availableItems={availableItems as InventorySchema[]} handleAddToCart={handleAddToCart} isAdded={isAdded} />
          </div>
         
          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                {(cartItems && cartItems.length > 0) ? 'Added Items' : 'Add Items to Cart'}
              </label>
              <div className='flex flex-col'>
              {
                cartItems && cartItems.map((item, i)=>
                  (
                    (isEditingQty && isEditingQty === item.id) ? (
                      <EditCartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} handleUpdateCartItem={handleUpdateCartItem} />
                    ) : ( 
                      <CartItem key={i} index={i} item={item} setIsEditingQty={setIsEditingQty} />
                    )
                  )
                )
              }
              </div>
          </div>

          <div className="flex flex-col mx-auto w-11/12 my-4">
              <label className='text-black font-semibold text-[16px] font-raleway text-left'>
                Payment Method
              </label>
              
              {(paymentMethods && paymentMethods.length > 0) && (<div className='border-[#E9E9E9] border-[1px] rounded-md my-2'>
                  <select
                      className="formInput m-auto w-full h-[59px] mx-auto bg-inherit p-3 border-0"
                      name="payment_options"
                      value={selectedPayMethod}
                      onChange={(e) => {
                        const {name, value} = e.target
                        if (name && value){
                          setSelectedPayMethod(value)
                        }
                        
                      }}
                  >
                    <option value="">-- Select Existing Card --</option>

                    {
                      paymentMethods.map((paymentMethod: any, i: number)=>
                      (
                        (paymentMethod && paymentMethod?.card) && (
                          <option value={i} key={i}>
                            <>
                              <>
                                {capitalizeString(paymentMethod?.card?.brand)}
                              </> &nbsp;&nbsp;
                              <>
                                ****{paymentMethod?.card?.last4 || ''}
                              </> &nbsp;&nbsp;
                              <>
                                Expires {formatDateString(paymentMethod?.card?.exp_month, paymentMethod?.card?.exp_year)}
                              </>
                            </>
                          </option>
                        )
                      ))
                    }
                  </select>
              </div>)}
          </div>
        </div>

        <div className='flex flex-row my-2 w-11/12'>
          <button
              className="py-2 w-[217px] mr-auto font-bold text-[18px] font-raleway text-primaryDark-0 border-[#E9E9E9] border-[1px]"
              data-testid="cancel-button"
              onClick={handleClose}
            > 
                  Cancel  
          </button>

          <button
              className="py-2 w-[217px] ml-auto font-bold text-[18px] font-raleway border-0 bg-primaryDark-0 text-white"
              onClick={handleSubmitOrder}
              data-testid="submit-button"> 
                  Submit  
          </button>
        </div>
      </div>
    );
};



type InventoryItemsProps = {
  availableItems: InventorySchema[]
  isAdded: (inventoryItem: InventorySchema)=> boolean
  handleAddToCart: (inventoryItem: InventorySchema, quantity: number) => void
}

const InventoryItems: React.FC<InventoryItemsProps> = ({availableItems, isAdded, handleAddToCart}) => {
  const [isAddingQty, setIsAddingQty] = useState<string>()
  const [qty, setQty] = useState<number>(0)
  return (
    <>
      <div className='flex flex-row space-x-2 w-full'>
        {
          (availableItems && availableItems.length > 0) && availableItems.map((item, i)=> (
            <div key={i} className='flex flex-col p-1 border-[#E9E9E9] border-[1px] h-[200px] rounded-xl w-1/3'>
                
                {
                  (item?.image_url) ? (
                    <img className='h-[80px] rounded-t-xl object-contain' src={item.image_url} alt={item.item_name} />
                  ) : (
                    <div className='h-[80px] rounded-t-xl bg-[#eee]' />
                  )
                }
                <div className='mx-auto w-11/12 '>
                  <p className='font-bold font-raleway text-black'>
                    {item.item_name}
                  </p>
                  <div className='flex flex-row w-full'>
                    <span className='text-[14px] text-black font-open-sans mr-auto font-semibold'>
                      ${item.unit_price} per {item.measure_unit} 
                    </span>
                    <span className='text-[14px] text-[#4D4D4D] font-open-sans ml-auto'>
                      {item.available_quantity} units left
                    </span>
                  </div>
                </div>

                {
                  isAdded(item) ? (
                    <button 
                      className={`border-[1px] border-[#E9E9E9] bg-white text-primaryDark-0  m-auto text-[14px] w-full h-[51px] font-bold font-raleway`}
                      // onClick={() => handleAddToCart(item)}
                      disabled={isAdded(item)}
                      >
                      Added to Cart
                    </button>
                  ) : (
                    (isAddingQty && isAddingQty === item?.id) ? (
                      <div className='flex flex-row w-full'>
                        <input 
                          name="total_quantity"
                          value={qty}
                          type='number' 
                          min={0} 
                          placeholder='0' 
                          className='border-[1px] border-[#E9E9E9] rounded-lg w-2/3 mr-1' 
                          onChange={e=>setQty(parseFloat(e.target.value))}
                        />
                        <button 
                          className={`bg-primaryDark-0 text-white m-auto text-[14px] h-[40px] font-bold font-raleway w-1/3`}
                          onClick={() => handleAddToCart(item, qty)}
                          disabled={isAdded(item)}
                          >
                          Add
                        </button>
                      </div>
                    ) : (
                      <button 
                        className={`bg-primaryDark-0 text-white m-auto text-[14px] w-full h-[51px] font-bold font-raleway`}
                        onClick={() => setIsAddingQty(item.id)}
                        disabled={isAdded(item)}
                        >
                        Add to Cart
                      </button>
                    )
                  )
                }
            </div>
          ))
        
        }
      </div>
    </>
  )
}


export default OrderInventoryForm