import { useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ReactComponent as LocationIcon } from "../../icons/locationIcon.svg";
import { getChangedAttributes, requirementCodes, fetchRequirementsLookUp } from '../../utils/helpers'
import { usStates, USStatesEnum } from "../../utils/enums"
import { useCompleteSignIn, useUpdateAccountDetails, useAddCredential, useGetCredentials } from '../../hooks/authhooks'

import { ReactComponent as ProfileIcon } from "../../icons/ProfileIcon.svg";
import { AccountSchema, CurrentRequirementsSchema, CredentialSchema } from '../../data/types';

import { useGetPaymentMethods } from '../../hooks/paymenthooks'
import { AddCredentialModal } from '../../molecules/AppModal';


type AddRequirementStatus = {status: boolean, requirement?: CurrentRequirementsSchema}


const Profile = () => {

  useEffect(() => {
    document.title = `HomeCook | Resource Pantry`
  }, [])

  const { user } = useCompleteSignIn();
  const loggedin_username = (user && user?.username) ? user.username : ''
  const { updateAccount } = useUpdateAccountDetails(loggedin_username)

  const { addMemberCredential  } = useAddCredential()
  const { memberCredentials } = useGetCredentials(loggedin_username)

  const [profileDetails, setProfileDetails] = useState<AccountSchema>(user ? user : {} as AccountSchema)
  const [userAvatar, setUserAvatar] = useState<string>((user && user?.avatar) ? user.avatar : "")
  const [addingCredential, toggleAddingCredential] = useState<AddRequirementStatus>({status: false})

  const handleUpdateAccount = () => {
    let originalData = user ? user : {} as AccountSchema
    let  changedData = getChangedAttributes(originalData, profileDetails)
    if(profileDetails && profileDetails !== user && changedData){
      updateAccount(changedData)
    }
  }

  const handleChangeInput  = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    const { name, value } = e.target
    if (name && (value || value === "")){
      setProfileDetails(
        prev => {
         return {...prev, [name]: value}
        }
      )
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, files } = event.target;
      if (name && files && files.length > 0) {
          let file_data = files[0];
          setProfileDetails(prev => ({
              ...prev,
              [name]: file_data,
          }))
      }
  }

  const handleImageChange = ( event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const imgType = event.target.name
    if (file) {
      // Read the selected image file as a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        if (imgType === "avatar") {
          setUserAvatar(e.target?.result as string);
        } 
      };
      reader.readAsDataURL(file);
    } else {
      if (imgType === "avatar") {
        setUserAvatar("");
      } 
    }
    handleFileChange(event);
  };

  const handleRemoveAvatar = () => {
    setUserAvatar("");
    setProfileDetails(prev => ({
      ...prev,
      'avatar': '',
  }))
  }

  const first_name = profileDetails?.first_name ? profileDetails.first_name : ''
  const last_name = profileDetails?.last_name ? profileDetails.last_name : ''
  const address = profileDetails?.address ? profileDetails.address : ''
  const city = profileDetails?.city ? profileDetails.city : ''
  const state = profileDetails?.state ? profileDetails.state : ''
  const zipcode = profileDetails?.zipcode ? profileDetails.zipcode : ''

  const { paymentMethods } = useGetPaymentMethods(loggedin_username)
  const requirementsLookUp =  fetchRequirementsLookUp(memberCredentials ? memberCredentials : [], paymentMethods)


  const handleSubmitCredential = (credential: CredentialSchema) => {
    if (loggedin_username && credential) {
      credential.username = loggedin_username;
      addMemberCredential(loggedin_username, credential);
    }
  }

  const totalRequirementsCount = requirementsLookUp.filter((item) => (item.isRequired === true)).length;
  const metRequirementsCount = requirementsLookUp.filter((item) => (item.isRequired === true && item.isMet === true)).length;


  return (
    <>
    <div className={`'w-full left-0 flex font-open-sans h-screen overflow-y-scroll relative  top-0 justify-center bg-gradient-to-b from-[#F5F5F5] pb-6 pt-8 backdrop-blur-2xl bg-[#F5F5F5]`}>
      <div className={`flex flex-col w-11/12 sm:w-10/12 h-[1300px] sm:h-[2000px] relative items-start py-6 bg-white`}>
          <div className='w-10/12 mx-auto my-5'>
            <p className='font-raleway text-[20px] text-black font-extrabold my-5'>My Profile</p>
            <div className='flex flex-col sm:flex-row border-[1px] border-[#E9E9E9] rounded-xl sm:h-[210px] my-5 p-10'>
              <div className='w-1/12'>
                {
                  (userAvatar) ? (
                    <div className='flex border-[#E9E9E9] border-[1px] rounded-full h-[85px] w-[85px]'>
                      <img
                        id="image-input-avatar"
                        src={userAvatar}
                        alt="User Avatar"
                        className="h-[72px] w-[72px] m-auto rounded-full"
                      />
                    </div>
                  ) : (
                    <ProfileIcon className='text-[#E6E6E6] h-[72px] w-[72px]' />
                  )
                }
              </div>

              <div className='w-8/12 flex flex-col'>
                <p className='text-[18px] text=black font-medium font-raleway'>Hi, {(user && user?.first_name) ? user.first_name : ''}</p>
                {
                  (user && user?.state) ? (
                    <p className='flex flex-row text-[12px] text=black font-medium font-raleway text-gray-400'>
                      <LocationIcon className='h-3 my-auto ' />
                      {USStatesEnum[user.state]?.fullName}
                    </p>
                  ) : (<></>)
                }
                <p className='text-[14px] font-open-sans text-[#4D4D4D] mb-2'>
                  {loggedin_username}
                </p>

                <label htmlFor="image-input-avatar" className='text-[14px] font-raleway text-[#013847] hover:text-primaryDark-300 font-bold my-2 cursor-pointer'>
                  Update your profile picture
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    id="image-input-avatar"
                    name="avatar"
                  />
                </label>

                <p onClick={handleRemoveAvatar} className={`text-[14px] font-raleway ${userAvatar ? 'text-[#D42620] cursor-pointer' : 'text-[#B3B3B3]'} font-medium my-2`}>
                  Remove your profile picture
                </p>
              </div>

            </div>
            <div className='border-[1px] border-[#E9E9E9] rounded-xl sm:h-[810px] my-5 p-10'>
              <div className=''>
                <p className='text-[18px] font-raleway text-black font-bold'>Profile Information</p>
                <p className='text-[14px] font-open-sans text-[#808080] mb-6 mt-2'>
                  Add your first and last name to keep your profile up-to-date.
                </p>

                <div className='flex flex-col text-[14px] font-raleway text-black my-4 font-semibold'>
                  First Name
                  <input type='text' name='first_name' value={first_name} onChange={handleChangeInput} placeholder='Enter your first name' className='border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#808080] font-normal font-open-sans' />
                </div>


                <div className='flex flex-col  text-[14px] font-raleway text-black my-4 font-semibold'>
                  Last Name
                  <input type='text' name='last_name' value={last_name} onChange={handleChangeInput} placeholder='Enter your last name' className='border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#808080] font-normal font-open-sans' />
                </div>

                <div className='flex flex-col  text-[14px] font-raleway text-black my-4 font-semibold'>
                  Address
                  <input type='text' name='address' value={address} onChange={handleChangeInput} placeholder='Enter your address' className='border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#808080] font-normal font-open-sans' />
                </div>
                <div className='flex flex-col  text-[14px] font-raleway text-black my-4 font-semibold'>
                  City
                  <input type='text' name='city' value={city} onChange={handleChangeInput} placeholder='Enter your city' className='border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#808080] font-normal font-open-sans' />
                </div>
                <div className='flex flex-col  text-[14px] font-raleway text-black my-4 font-semibold'>
                    State
                    <select 
                      name="state" 
                      value={state}  
                      onChange={handleChangeInput}
                      className="border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#808080] font-normal font-open-sans"
                    >
                        <option value="">-- Select your state --</option>
                        {
                            (usStates && usStates.length > 0) && usStates.map((state: string, i: number) => (
                                <option value={USStatesEnum[state]?.abbreviation} key={i}>{USStatesEnum[state]?.fullName}</option>
                            ))
                        }
                    </select>
                </div>

                <div className='flex flex-col  text-[14px] font-raleway text-black my-4 font-semibold'>
                  Zipcode
                  <input type='text' name='zipcode' value={zipcode} onChange={handleChangeInput} placeholder='Enter your zipcode' className='border-[1px] border-[#E9E9E9] rounded-md my-2 sm:h-[50px] text-[14px] text-[#808080] font-normal font-open-sans' />
                </div>

                <button onClick={handleUpdateAccount} className='bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 py-[8px] px-[18px]'>Update Profile Information</button>
              
              </div>
            </div>

            <div className='border-[1px] border-[#E9E9E9] rounded-xl h-auto my-5 p-10'>
              <div className=''>
                <p className='text-[18px] font-raleway text-black font-bold'>Verify Membership</p>
                <div className="absolute right-0 left-3/4">
                  <p className="mb-2">Progress completion</p>
                  
                  <div className="flex items-center">
                    <div className="relative w-[140px] h-2.5 rounded-md mt-1 mb-1">
                      <div className="absolute top-0 left-0 h-2.5 w-full bg-gray-300 rounded-md"></div>
                      <div
                        className="absolute top-0 left-0 h-2.5 bg-green-700 rounded-md"
                        style={{ width: `${(metRequirementsCount / totalRequirementsCount) * 100}%` }}
                      ></div>
                    </div>
                    <p className="text-sm pl-2">{Math.round((metRequirementsCount / totalRequirementsCount) * 100)}%</p>
                  </div>
                  
                  
                </div>
                

                <p className='text-[14px] font-open-sans text-[#808080] mb-6 mt-2'>
                  Complete your profile by adding the required details.
                </p>

                <div className='flex flex-col justify-evenly'>
                  {
                    requirementsLookUp && requirementsLookUp.map((requirement, i)=> {
                      return (requirement.isRequired) && (
                        <>
                          <div key={i} className='flex flex-row border-[1px] border-[#E9E9E9] rounded-xl sm:h-[90px] px-5 my-2'>
                            {
                              (requirement.isMet) ? (
                                <CheckBox className='my-auto text-[#0F973D]' />
                              ) : (
                                <CheckBoxOutlineBlankIcon className='my-auto text-[#CFCFCF]' />
                              )
                            }
                            
                            <div className='my-auto mx-4 w-full'>
                              <p className='text-[14px] font-raleway text-black my-2 font-semibold'>{requirement.requirementName}</p>
                              <p className='text-[12px] font-open-sans text-[#4D4D4D] my-1'>
                                {requirement.description}
                              </p>
                            </div>
                            <RequirementButton requirement={requirement} toggleAddingCredential={toggleAddingCredential} />
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <AddCredentialModal isOpen={addingCredential.status} closeAppModal={()=> toggleAddingCredential({status: false})} handleSubmitCredential={handleSubmitCredential} requirement={addingCredential.requirement as CurrentRequirementsSchema} />
    </>
  )
}



type RequirementButtonProps = {
  requirement: CurrentRequirementsSchema
  toggleAddingCredential: React.Dispatch<React.SetStateAction<AddRequirementStatus>>
}

const RequirementButton: React.FC<RequirementButtonProps> = ({requirement, toggleAddingCredential}) => {
  const navigate = useNavigate()
  return (
    <div className='flex flex-row w-full'>
      {
        (requirement.requirementCode === "PAY") ? (
          <button onClick={()=> navigate('/wallet')} className='bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 ml-auto h-[43px] my-auto mx-2'>Go to Payment Method</button>
            ) : (
            (requirementCodes && requirementCodes.includes(requirement.requirementCode)) ? (
              <div className='ml-auto my-auto'>
                {
                  (requirement.isMet) && (<button className='bg-white hover:bg-gray-300 border-[#E9E9E9] border-[1px] text-[#D42620] font-raleway font-bold text-[14px] rounded-md h-[43px] my-auto mx-2'>Remove Photo</button>)
                }
                <button onClick={()=> toggleAddingCredential({status: true, requirement})} className='bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 ml-auto h-[43px] my-auto mx-2'>Upload</button>
              </div>
            ) : (
              <>
                {/* <button className='bg-primaryDark-0 hover:bg-primaryDark-300 text-white font-raleway font-bold text-[14px] rounded-md border-0 h-[43px] my-auto'>Go to Payment Method</button> */}
              </>
            )
        )
      }
    </div>
  )
}


export default Profile