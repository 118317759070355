import React, {ReactNode, useState, useContext, createContext} from 'react'
// import { startNewAuth, createNewCommunity, getAuthUser, verifyUserOTP, fetchCommunity, signOut, updateUserData } from '../api/crud'

import { AccountSchema, AuthStateType, CommunitySchema, OrderItemSchema } from "../data/types";


// const AuthContext = createContext<AuthContextType | null>(null);
const AuthContext = createContext<AuthStateType | {}>({});


type PropsChildren = {children?: ReactNode}

const AuthProvider = (props: PropsChildren) => {
    const [userData, updateUserData] = useState<AccountSchema>()
    const [primaryCommunity, updatePrimaryCommunity] = useState<CommunitySchema>()
    const [isloggedin, updateLogin] = useState<boolean>(false)
    const [isverified, updateVerified] = useState<boolean>(false)
    const [cartItems, updateCartItems] = useState<OrderItemSchema[]>((userData && userData?.cart_items) ? userData?.cart_items : [])

    var communityId = (primaryCommunity && primaryCommunity.id) ? primaryCommunity.id : ""

    // console.log("cartItems: ", cartItems)
    // console.log("updateCartItems: ", updateCartItems)


    let data = { 
        primaryCommunity, communityId, 
        userData, isloggedin, isverified,  
        cartItems, updateCartItems,
        updateLogin, updateVerified,
        updateUserData, updatePrimaryCommunity,
    }
   
    return <AuthContext.Provider value={data} {...props} />
}

const useAuthState = () => {
    const context: AuthStateType = useContext(AuthContext)
    if (context === undefined ){
        throw new Error(`useAuthState must be used within AuthProvider`)
    }
    return context
}

export { AuthProvider, useAuthState }