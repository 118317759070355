// import React from "react";
import HomeCookRouter from "./routers";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
// import {APIProvider as GoogleMapsAPIProvider} from '@vis.gl/react-google-maps';

const queryClient = new QueryClient();
// const googleMapsApiKey = process.env.REACT_APP_GOOGLEMAPS_API_KEY_PROD;

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        {/* <GoogleMapsAPIProvider apiKey={googleMapsApiKey ? googleMapsApiKey : ''} onLoad={() => console.log('Maps API has loaded.')}> */}
          <HomeCookRouter />
        {/* </GoogleMapsAPIProvider> */}
      </div>
    </QueryClientProvider>
  );
}

export default App;