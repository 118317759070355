import React, { useState, useEffect } from "react";
import {
  startOfMonth,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  eachDayOfInterval,
  format,
} from "date-fns";
import moment, { Moment } from "moment";
import { BookingSchema, ResourceSchema, TimeBlock, TimeSlot } from "../data/types";
// import { getResourceOptions } from "../api/crud";
// import { darkenColor, getBookingStatusColor } from "../utils/helpers";

type MonthViewProps = {
  currentDate: Date;
  selectedDay: Date;
  resourceBookings: BookingSchema[];
  userBookings: BookingSchema[];
  nHours: number;
  setSelectedDay: any;
  handleConfirmBooking?: () => void;
  currentKitchen: ResourceSchema;
  setBlocks:React.Dispatch<React.SetStateAction<TimeBlock[] | undefined>>
};



const ReservationCalendar: React.FC<MonthViewProps> = ({
  userBookings,
  resourceBookings,
  currentDate,
  nHours,
  currentKitchen,
  setBlocks,
  selectedDay,
  setSelectedDay
}) => {


  // const [selectedBlock, setSelectedBlock] = useState<TimeBlock>();
  // const [selectedDay, setSelectedDay] = useState(new Date());
  const [bookings, setBookings] = useState<BookingSchema[]>();

  const firstDayOfMonth = startOfWeek(startOfMonth(currentDate));
  const lastDayOfMonth = endOfWeek(endOfMonth(currentDate));

  const daysInMonth: Date[] = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });
  

  useEffect(() => {
    const createCurrentBookings = () => {
      const combinedBookings = [...userBookings, ...resourceBookings].filter((booking, index, self) =>
        index === self.findIndex((b) => b.id === booking.id)
      );
      setBookings(combinedBookings);
    };
    createCurrentBookings();
  }, [userBookings, resourceBookings]); // Run when either booking array changes
  


  const findBookingsForDay = (day: Moment) => {
    const bookingsForDay = [];
    if (bookings && bookings.length > 0) {
      for (const booking of bookings) {
        const startTime = moment(booking.start_time);
        const endTime = moment(booking.end_time);

        if (
          startTime.isSameOrBefore(day, "day") &&
          endTime.isSameOrAfter(day, "day")
        ) {
          bookingsForDay.push(booking);
        }
      }
    }
    return bookingsForDay;
  };

  const areDatesInSameMonth = (date1: Date, date2: Date) => {
    // Extract year and month components from both dates
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    // Compare the year and month components
    return year1 === year2 && month1 === month2;
  };

  const areBookingsLessThan12Hours = (day: Date) => {
    // const startOfDay = moment(day).startOf("day");
    // const endOfDay = moment(day).endOf("day");
    let totalTime = 0;
    const bookingsInDay = findBookingsForDay(moment(day));
    for (const booking of bookingsInDay) {
      totalTime += moment(booking.end_time).diff(booking.start_time);
    }
    const test = totalTime < 12 * 60 * 60 * 1000;

    return test;
  };

  const calculateAvailableTimeBlocks = (
    openTime: string,
    closeTime: string,
    requestedHours: number,
    bookings: BookingSchema[]
  ): TimeBlock[] => {
    const blocks: TimeBlock[] = [];
    const startMoment = moment(openTime, "HH:mm A");
    const endMoment = moment(closeTime, "HH:mm A");

    const today = moment().startOf('day');
    startMoment.year(today.year()).month(today.month()).date(today.date());
    endMoment.year(today.year()).month(today.month()).date(today.date());
    
    
    let currentTime = startMoment.clone();
    
    while (currentTime.clone().add(requestedHours, 'hours').isSameOrBefore(endMoment)) {
      const blockEnd = currentTime.clone().add(requestedHours, 'hours');
      let isAvailable = true;
      for (const booking of bookings) {
        const bookingStart = moment(booking.start_time);
        const bookingEnd = moment(booking.end_time);
        
        if (currentTime.isBefore(bookingEnd) && blockEnd.isAfter(bookingStart)) {
          isAvailable = false;
          break;
        }
      }
      
      if (isAvailable) {
        blocks.push({
          start: currentTime.clone(),
          end: blockEnd.clone()
        });
      }
      
      currentTime.add(30, 'minutes');
    }
    
    return blocks;
  };

  const isTimeSlot = (value: TimeSlot): boolean => {
    // return value && typeof value === 'object' && 'open_time' in value && 'close_time' in value;
    return (
      value && Object(value).hasOwnProperty('open_time') 
      && value?.open_time  && Object(value).hasOwnProperty('close_time') 
      && value?.close_time);
  };

  const dayAvailability = (day: Date) => {
    const dayOfWeek = moment(day).format("ddd").toLowerCase();
    const dayOptions: TimeSlot = currentKitchen.resource_options?.[dayOfWeek] as TimeSlot
    if (!dayOptions || !isTimeSlot(dayOptions as TimeSlot)) {
      return false;
    }

    const availableBlocks = calculateAvailableTimeBlocks(
      dayOptions.open_time,
      dayOptions.close_time,
      nHours,
      findBookingsForDay(moment(day))
    );
    return availableBlocks.length > 0;
  };

  const handleCalendarClick = async (day: Date) => {
    // console.log("handleCalendarClick day", day);
    if (
      areDatesInSameMonth(day, currentDate) &&
      areBookingsLessThan12Hours(day) &&
      nHours > 0
    ) {
      const dayOfWeek = moment(day).format("ddd").toLowerCase();
      const dayOptions = currentKitchen.resource_options?.[dayOfWeek] as TimeSlot;
      if (dayOptions && isTimeSlot(dayOptions)) {
        const availableBlocks = calculateAvailableTimeBlocks(
          dayOptions.open_time,
          dayOptions.close_time,
          nHours,
          findBookingsForDay(moment(day))
        );
        if (availableBlocks.length > 0) {
          setSelectedDay(day);
          setBlocks(availableBlocks);
          // updateStartTime(availableBlocks[0].start);
          // updateEndTime(availableBlocks[0].end);
        }
      }
    }
    // console.log("handleCalendarClick selectedDay", selectedDay);
  };

  

  return (
    <div className="flex flex-col w-full justify-center place-items-center">
      <div className="grid grid-cols-7 gap-1 gap-y-3 grid-flow-dense w-full">
        {(daysInMonth && daysInMonth?.length > 0) && daysInMonth.slice(0, 7)?.map((day) => (
          <div
            key={day.toISOString()}
            className=" text-[#4D4D4D] text-[16px] font-raleway  p-2 relative col-span-1 text-left md:px-5  "
          >
            <div>{format(day, "E")}</div>
          </div>
        ))}

        {(daysInMonth && daysInMonth?.length > 0) ? daysInMonth.map((day, i) => (
          <div 
            key={i} 
            className={`font-medium px-[14px] py-[2px] pb-2 relative col-span-1 border-[1px] border-[#E9E9E9] rounded-md h-[45px] w-[65px]  
              ${(day && selectedDay && moment(day).isSame(moment(selectedDay))) ? "bg-primaryDark-100 text-white" : (
                  ((day && !dayAvailability(day))) ? "!text-gray-300 cursor-not-allowed bg-transparent" : (
                    (day && (!areBookingsLessThan12Hours(day) || moment().isSameOrAfter(moment(day))))
                        ? "bg-transparent text-gray-300 cursor-not-allowed"
                        : "hover:bg-primaryDark-400 cursor-pointer hover:text-white"
                  )
                )
              }
            `}
          >
            <div
              className={`
                
                ${areDatesInSameMonth(day, currentDate) ? "" : "!text-transparent"}
                justify-center md:m-2 text-center md:w-1/4 rounded-full md:pt-1
              `}
              onClick={() => handleCalendarClick(day)}
            >
              {format(day, "dd")}
            </div>
          </div>
        )) : (<></>)}
      </div>
    </div>
  );
};



export default ReservationCalendar;
