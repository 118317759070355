import { useEffect, useState } from "react";
import Login from "./Login";
import FilterOptions from "./FilterOptions";
import { AddCredentialForm, ApplyFundingForm } from "./Forms";
import RequestKitchen from "./RequestKitchen";

import  OrderInventoryForm from "./OrderInventoryForm";
import RegisterTrainingForm  from './RegisterTrainingForm'
import ObtainDocumentationForm from './ObtainDocumentationForm'
import ChatWindow from "./chatWindow";
import UserListWindow from "./chatUsers";

import left_arrow from "../icons/LeftArrow.svg";

import { FilterType, CredentialSchema, CurrentRequirementsSchema, ResourceSchema, ResourceType, ResourceBookingSchema, MembershipStatusType } from "../data/types";
import { useCompleteSignIn, useLoadMember } from "../hooks/authhooks";
import ScheduleExpertForm from "./ScheduleExpertForm";
import RequestMembershipForm from "./RequestMembershipForm";
import LoadingScreen from "../components/LoadingScreen";
import { capitalizeString } from "../utils/helpers";


type LoginModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeAppModal: () => void;
};

export const LoginModal: React.FC<LoginModalProps> = (props) => {
  var { isOpen, setIsOpen, closeAppModal } = props;

  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div
        className="fixed inset-0 bg-white opacity-90"
        onClick={() => closeAppModal()}
      ></div>

      <dialog
        data-testid="view-kitchen-modal"
        className="z-50 top-20 sm:w-[478px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <Login setIsOpen={setIsOpen} />
      </dialog>
    </div>
  );
};


type FilterModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeAppModal: () => void;
  currentFilter: FilterType;
  // setFilterSetting: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentFilter: React.Dispatch<React.SetStateAction<FilterType>>;
};

export const FilterModal: React.FC<FilterModalProps> = (props) => {
  var { isOpen, closeAppModal, currentFilter, setCurrentFilter } = props;

  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div
        className="fixed inset-0 bg-white opacity-90"
        onClick={() => closeAppModal()}
      ></div>

      <dialog
        data-testid="view-kitchen-modal"
        className="z-50 top-20 w-10/12 sm:w-[478px] h-auto md:h-[500px] bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <FilterOptions
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          closeFilterSetting={closeAppModal}
        />
      </dialog>
    </div>
  );
};


type MessageModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MessageModal: React.FC<MessageModalProps> = (props) => {

  const { user } = useCompleteSignIn();
  const loggedin_username = user && user?.username ? user.username : "";
  var { isOpen, setIsOpen } = props;
  const [choosingChat, setChoosingChat] = useState(true);
  const [selectedChat, setSelectedChat] = useState<"chatbot" | "staff">("chatbot");
  // let {  currentChatCommunity: selectedChat, changeChatCommunity: setSelectedChat} = useMessageState()
 const toggleChooseChat = () => {
  setChoosingChat(prev => !prev);
 }

  const closeAppModal = () => {
    setIsOpen(false);
    toggleChooseChat();
    // setSelectedChat("");
  };
  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}  fixed right-0`}>
      <div className="fixed inset-0 " onClick={() => closeAppModal()}></div>
      <dialog
        data-testid="view-kitchen-modal"
        // className="z-50 top-20 sm:w-[478px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        className="z-50 bottom-32 w-[438px] h-auto md:h-[346px]  md:mr-10 rounded-[16px] shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <div className="bg-primaryDark-0 h-[72px] w-full rounded-t-[16px]  p-5 flex flex-row gap-2">
          {!choosingChat ? <img src={left_arrow} alt="left-arrow" className="h-[24px] w-[24px] mt-1 hover:cursor-pointer" onClick={() => toggleChooseChat()}/> : ""}
          <div className="text-white text-xl font-bold mt-[1px]">
            {choosingChat ? "How Can We Assist You?" : selectedChat === "chatbot" ? "Chatbot" :"Chat"}
          </div>
        </div>
        <div>
          {choosingChat ? (
            <div className={`  w-auto  bg-[#fff] rounded-md`}>
              <UserListWindow username={loggedin_username} choosingChat={choosingChat} setChoosingChat={() => toggleChooseChat()} selectedChat={selectedChat} setSelectedChat={setSelectedChat} />
            </div>
          ) : (
            <div>
              <ChatWindow currentChatCommunity={selectedChat} username={loggedin_username} />
            </div>
          )}
        </div>
      </dialog>
    </div>
  );
};


type AddCredentialModalProps = {
  isOpen: boolean;
  requirement: CurrentRequirementsSchema
  closeAppModal: () => void;
  handleSubmitCredential: (credential: CredentialSchema) => void
}

export const AddCredentialModal: React.FC<AddCredentialModalProps> = (props) => {
  var { isOpen, requirement, handleSubmitCredential, closeAppModal } = props

  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-10/12 sm:w-[478px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <AddCredentialForm requirement={requirement} handleSubmitCredential={handleSubmitCredential} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}





type ApplyFundingModalProps = {
  isOpen: boolean;
  resourceDetail: ResourceSchema
  closeAppModal: () => void;
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
}

export const ApplyFundingModal: React.FC<ApplyFundingModalProps> = (props) => {
  var { isOpen, handleSubmitRequest, closeAppModal, resourceDetail } = props

  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-auto sm:w-[810px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <ApplyFundingForm resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}

type RequestKitchenModalProps = {
  isOpen: boolean;
  resourceDetail: ResourceSchema
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
  closeAppModal: () => void;
}

export const RequestKitchenModal: React.FC<RequestKitchenModalProps> = (props) => {
  var { isOpen, handleSubmitRequest, closeAppModal, resourceDetail } = props
  // const communityId = resourceDetail?.community_id ? resourceDetail?.community_id : "resource_pantry"
  const communityId = resourceDetail?.community_id ? resourceDetail?.community_id : ""
  const { currentMember, reloadCurrentMember } = useLoadMember(communityId)

  useEffect(() => {
    reloadCurrentMember()
  }, [reloadCurrentMember, currentMember])

  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-auto sm:w-[810px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        {
          (currentMember && currentMember?.status) ? (
            (['approved', 'verified', 'active'].includes(currentMember?.status as MembershipStatusType)) ? (
              <RequestKitchen currentMember={currentMember} resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} setIsSubmitting={() => null} />
            ) : (
              <span className="flex flex-col justify-evenly">
                {/* <img src={resourceDetail?.logo ? resourceDetail.logo : "/logo192.png"} alt="homecook-logo" className="h-[60px] self-center rounded-lg"/> */}
                <LoadingScreen height="h-fit" bgColor="inherit" circleDiameter="40" />
                <p className="text-primaryDark-0 font-raleway text-[24px] text-center my-2">
                   <b>{resourceDetail.name} </b>Membership is {capitalizeString(currentMember?.status)}
                </p>

                <p className='text-primaryDark-300 hover:text-primaryDark-200 cursor-pointer mx-auto my-4'>View Next Steps</p>
              </span>
            )
          ) : (
            <RequestMembershipForm resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} />
          )
        }
      </dialog>
    </div>
  );
}


type OrderInventoryModalProps = {
  isOpen: boolean;
  resourceDetail: ResourceSchema
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
  closeAppModal: () => void;
}

export const OrderInventoryModal: React.FC<OrderInventoryModalProps> = (props) => {
  var { isOpen, handleSubmitRequest, closeAppModal, resourceDetail } = props
  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-auto sm:w-[810px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <OrderInventoryForm resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}

type RegisterTrainingModalProps = {
  isOpen: boolean;
  resourceDetail: ResourceSchema
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
  closeAppModal: () => void;
}

export const RegisterTrainingModal: React.FC<RegisterTrainingModalProps> = (props) => {
  var { isOpen, handleSubmitRequest, closeAppModal, resourceDetail } = props
  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-auto sm:w-[810px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <RegisterTrainingForm resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}


type ScheduleExpertModalProps = {
  isOpen: boolean;
  resourceDetail: ResourceSchema
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
  closeAppModal: () => void;
}

export const ScheduleExpertModal: React.FC<ScheduleExpertModalProps> = (props) => {
  var { isOpen, handleSubmitRequest, closeAppModal, resourceDetail } = props
  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-auto sm:w-[810px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <ScheduleExpertForm resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}


type ObtainDocumentationModalProps = {
  isOpen: boolean;
  resourceDetail: ResourceSchema
  handleSubmitRequest: (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => void
  closeAppModal: () => void;
}

export const ObtainDocumentationModal: React.FC<ObtainDocumentationModalProps> = (props) => {
  var { isOpen, handleSubmitRequest, closeAppModal, resourceDetail } = props
  return (
    <div className={`inset-0 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-white opacity-90" onClick={() => closeAppModal()} ></div>
      <dialog
        data-testid="add-credential-modal"
        className="z-50 top-20 w-auto sm:w-[810px] h-auto bg-[#fff] p-4 rounded-xl shadow-[#00000029] shadow-[0_0px_8px_rgba(0,0,0,0.16)]"
        open={isOpen}
        onClose={() => closeAppModal()}
      >
        <ObtainDocumentationForm resourceDetail={resourceDetail} handleSubmitRequest={handleSubmitRequest} handleClose={closeAppModal} />
      </dialog>
    </div>
  );
}
