import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
// import { auth } from '../../auth/firebase';
import { useGetAuthUser, useCompleteSignIn } from "../../hooks/authhooks";
// import { useAuthState } from "../../contexts/authcontext";
import LoadingScreen from "./LoadingScreen"
// import {  } from '../../hooks/authhooks'
// import { getEmailFromToken } from '../../utils/helpers';
// import { signInWithCustomToken } from 'firebase/auth'; 

const FinishSignIn: React.FC = () => {
  const navigate = useNavigate();
  const { authUser, isLoadingAuthUser } = useGetAuthUser()
  var error = ""
  const email = authUser?.email

  // if (!email && !isLoadingAuthUser) {
  //   // console.log('Please provide your email for confirmation');
  //   error = `Please provide your email for confirmation`
  //   // setError("Please provide your email for confirmation")
  // } else 

  const {completeSignIn, user, isLoading} = useCompleteSignIn()
  
  if (email) {
    completeSignIn(email).then(()=>{
      navigate('/')
    })
    // if (user && !isLoading){
      
    // }
  }


  // console.log('user', user);
  // console.log('error', error);

   if ((isLoadingAuthUser || isLoading) && !user){
    return (
      <LoadingScreen />
    )
  } else if (error) {
    return (
      <div className='items-center h-full w-full my-auto p-auto text-primaryDark-200 text-lg flex flex-col align-middle text-center justify-center pt-40'>
        <p className='font-bold'>Error with sign-in</p>
        <p className='text-md'>{error ? error : 'Error with sign-in, please provide valid email for confirmation'}</p>
      </div>
    )
  } else if (user){
    return (
      <div className='items-center h-full w-full my-auto p-auto text-primaryDark-200 text-lg flex flex-col align-middle text-center justify-center pt-40'>
        Completed sign-in
      </div>)
  } else if (!isLoading || !isLoadingAuthUser){
    return (
      <div className='items-center h-full w-full my-auto p-auto text-primaryDark-200 flex flex-col align-middle text-center justify-center pt-40'>
        {/* <LoadingScreen /> */}
        <p className='font-bold text-lg'>Cannot complete sign-in with link</p>
        {/* Completing sign-in... */}
        {/* <p className='text-sm'>Cannot Complete sign-in</p> */}
        <p className='text-md'>Please provide valid email</p>
        <LoadingScreen />
      </div>)
  }

  return (
    <div className='items-center h-full w-full my-auto p-auto text-primaryDark-200 flex flex-col align-middle text-center justify-center pt-40'>
      Completing sign-in...
    </div>)
  
};

export default FinishSignIn;