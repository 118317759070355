// DEV & TEST
export const testFirebaseConfig = {
    apiKey: "AIzaSyCo1XcQH3nE0PsVS6ZHBCYKQhbhDrF8ubs",
    authDomain: "homecook-events.firebaseapp.com",
    databaseURL: "https://homecook-events.firebaseio.com",
    projectId: "homecook-events",
    storageBucket: "homecook-events.appspot.com",
    messagingSenderId: "354223782583",
    appId: "1:354223782583:web:617d1e5527fb6b0c4a2b32",
    measurementId: "G-D4QK5G5K9H"
  };


  // PROD
  export const prodFirebaseConfig = {
    apiKey: "AIzaSyBy70LPfDFA3rgjkcOWFoeUvZzaHk7yrOk",
    authDomain: "resource-pantry.firebaseapp.com",
    projectId: "resource-pantry",
    storageBucket: "resource-pantry.appspot.com",
    messagingSenderId: "858848484036",
    appId: "1:858848484036:web:a6d2925cd685bf27794791",
    measurementId: "G-KJ3Q4J3XD1"
  };


  // PROD
//   // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBy70LPfDFA3rgjkcOWFoeUvZzaHk7yrOk",
//   authDomain: "resource-pantry.firebaseapp.com",
//   projectId: "resource-pantry",
//   storageBucket: "resource-pantry.appspot.com",
//   messagingSenderId: "858848484036",
//   appId: "1:858848484036:web:a6d2925cd685bf27794791",
//   measurementId: "G-KJ3Q4J3XD1"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);