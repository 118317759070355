
import { useQuery, useMutation} from '@tanstack/react-query'
import { 
        // fetchMemberships,
        addPaymentSetupIntent,
        // addPayIntent,
        deletePaymentMethod,
        fetchPaymentMethods,
     } from '../api/crud'
import {AccountSchema } from "../data/types";


const staleTime = 1000
const isEnabled = true

export const useGetPaymentMethods = (username: string) => {
    const {data: paymentMethods, refetch: reloadPaymentMethods, isLoading: isLoadingPaymentMethods} = useQuery({ 
        queryKey: ['payMethods', username], 
        queryFn: async () => {
            console.log("fetching payMethods")
            const res = await fetchPaymentMethods(username)
            return res ? res : []
        },
        staleTime,
        enabled: isEnabled
    })

    const getPaymentMethod = (account: AccountSchema) => {
        let paymentMethodId = account.payment_method
        return paymentMethods.find((obj: any) => obj['id'] === paymentMethodId);
    }

    return { isLoadingPaymentMethods, paymentMethods, getPaymentMethod, reloadPaymentMethods}
}


export const useAddPaySetupIntent = (username: string) => {
    const { reloadPaymentMethods } = useGetPaymentMethods(username)

    const mutation = useMutation({ 
        mutationFn: async () => {
            console.log("add setup intent")
            const res = await addPaymentSetupIntent(username)
            // console.log("addPaymentSetupIntent res: ", res)
            const {client_secret} = res
            // await reloadPaymentMethods()
            return client_secret
        },
        // cacheTime: 10000
    })

    const addSetupIntent = () => {
        mutation.mutate()
        return mutation.data
    }

    const deletePayMethodMutation = useMutation({ 
        mutationFn: async (paymentMethodId: string) => {
            console.log("delete payment method")
            const res = await deletePaymentMethod(paymentMethodId)
            const { deleted } = res
            await reloadPaymentMethods()
            return deleted ?  deleted : false
        },
        // cacheTime: 10000
    })

    const removePaymentMethod = (paymentMethodId: string) => {
        deletePayMethodMutation.mutate(paymentMethodId)
        return deletePayMethodMutation?.data ? mutation.data : ''
    }


    const isLoadingSetupIntent = mutation.isPending || deletePayMethodMutation.isPending
    return { addSetupIntent, removePaymentMethod, isLoadingSetupIntent }
}