import { useNavigate } from 'react-router-dom';
import {useState, useEffect} from 'react'
import moment from 'moment-timezone';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ResourceCard from '../../components/ResourceCard'

import kitchen_bg from '../../assets/img/kitchen_bg.jpeg'
import documentation_bg from '../../assets/img/documentation_bg.jpeg'
import expert_bg from '../../assets/img/expert_bg.jpeg'
import farm_bg from '../../assets/img/farm_bg.jpeg'
import funding_bg from '../../assets/img/funding_bg.jpeg'
import training_bg from '../../assets/img/training_bg.jpeg'

import { ResourceBookingSchema, ResourceSchema, ResourceType, SearchFilterOptionType } from '../../data/types';
import { capitalizeString, getEmailFromToken } from '../../utils/helpers';
import { USStatesEnum } from '../../utils/enums';

import FilterOptions from '../../molecules/FilterOptions';
import { ApplyFundingModal, ObtainDocumentationModal, OrderInventoryModal, RegisterTrainingModal, RequestKitchenModal, ScheduleExpertModal } from '../../molecules/AppModal';



import { ReactComponent as KitchenIcon } from '../../icons/KitchenIcon.svg'
import { ReactComponent as CredentialIcon } from '../../icons/credentialIcon.svg'
import { ReactComponent as OrderIcon } from '../../icons/OrderIcon.svg'
import { ReactComponent as DocumentationIcon } from '../../icons/documentationIcon.svg'
import { ReactComponent as FundingIcon } from '../../icons/fundingIcon.svg'
import { ReactComponent as ExpertIcon } from '../../icons/expertIcon.svg'

import { useLoadResourcesFilter, resourcesPerPage } from '../../hooks/apphooks';
import { useAddBooking } from '../../hooks/authhooks';


type ResourceListingProps = {
  resourceType: ResourceType
  searchFilterOptions: SearchFilterOptionType
}

const ResourceListing: React.FC<ResourceListingProps> = ({resourceType, searchFilterOptions}) => {
  const navigate = useNavigate()
  const [filterSetting, setFilterSetting] = useState<boolean>(false)
  const {searchTerm, currentFilter, setCurrentFilter} = searchFilterOptions
  const [currentResourcePage, setCurrentResourcePage] = useState(1)

  var { filteredResources, getPage, searchResources } = useLoadResourcesFilter(resourceType, currentFilter.state)

  if (searchTerm){
    const { searchedResources } = searchResources(searchTerm)
    filteredResources = searchedResources
  }

  var { paginatedResources, numResources, numAllResources, startIndex, endIndex} = getPage(filteredResources, currentResourcePage)

  const [width, setWidth] = useState(window.innerWidth);
  const [applyModal, toggleApplyModal] = useState<ResourceType | undefined>()
  const [applyResource, setApplyResource] = useState<ResourceSchema>({} as ResourceSchema)

  const loggedin_username = getEmailFromToken(localStorage.getItem('accessToken'))

  const { addNewBooking} = useAddBooking()
  const handleSubmitRequest = (resource: ResourceSchema, resourceType: ResourceType, booking: ResourceBookingSchema) => {
    if (loggedin_username && resourceType && resource.id){
      booking.resource_id = resource.id
      booking.resource_type = resourceType
      booking.username = loggedin_username
      booking.resource_name = resource.name
      booking.resource_city = resource.city
      booking.resource_county = resource.county
      booking.resource_state = resource.state
      booking.resource_address = resource.address
      booking.resource_logo = resource.logo
      booking.resource_bgPhoto = resource.bgPhoto
      addNewBooking(booking)
    }
    
  }

  const closeAppModal = ()=>{
    toggleApplyModal(undefined)
    setApplyResource({} as ResourceSchema)
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    document.title = `HomeCook | ${capitalizeString(resourceType)} Listing`
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [resourceType]);

  const isMobile = width < 1024;


  var ResourceIcon = OrderIcon
  var resourceTitle = ""
  var resourceDescription = ""
  var resourceBgImage = farm_bg

  switch(resourceType){
    case 'kitchen':
      resourceTitle = "Local Kitchen Spaces"
      resourceDescription = "Discover kitchen communities and food incubators in your area."
      resourceBgImage = kitchen_bg
      ResourceIcon = KitchenIcon
      break;

    case 'documentation':
      resourceTitle = "Required Documentation & Compliance"
      resourceDescription = "Access essential legal documents and compliance guides."
      resourceBgImage = documentation_bg
      ResourceIcon = DocumentationIcon
      break;

    case 'training':
      resourceTitle = "Culinary Training"
      resourceDescription = "Enhance your skills with top culinary training programs."
      resourceBgImage = training_bg
      ResourceIcon = CredentialIcon
      break;

    case 'funding':
      resourceTitle = "Funding & Financial Resources"
      resourceDescription = "Discover financial options and resources for your business."
      resourceBgImage = funding_bg
      ResourceIcon = FundingIcon
      break;

    case 'expert':
      resourceTitle = "Industry Mentors & Experts"
      resourceDescription = "Discover organizations that offer mentorship and industry insights."
      resourceBgImage = expert_bg
      ResourceIcon = ExpertIcon
      break;

    case 'farm':
      resourceTitle = "Local Fresh Food"
      resourceDescription = "Source fresh, local ingredients for your culinary creations."
      resourceBgImage = farm_bg
      ResourceIcon = OrderIcon
      break;

    default:
      resourceTitle = ""
      resourceDescription = ""
      resourceBgImage = farm_bg
      ResourceIcon = OrderIcon
  }

  var lastUpdatedDate = ''
  if (filteredResources && filteredResources.length > 0){
    lastUpdatedDate = moment(filteredResources[0].last_updated).tz('America/New_York').startOf('second').fromNow()
  }

  if (isMobile){
    return (
      <>
      <div className={`w-full flex flex-row font-open-sans h-screen overflow-y-scroll top-0 bg-gradient-to-b bg-[#FFFFFF] pb-6 pt-8 backdrop-blur-2xl`}>
        
        <div className={`flex flex-col w-full h-full relative items-start py-4 sm:py-6 ${(filterSetting) ? 'invisible sm:visible' : ''}`}>
          <span className="text-[#8A8A8A] hover:text-[#5DB6CE] ml-[10px] mt-[140px] cursor-pointer text-[13px] font-bold" onClick={()=> navigate('/')}>
            <ArrowBackIosIcon style={{fontSize: 13}}   />  Go Back
          </span>
  
          {
              lastUpdatedDate && (
                <span className='font-raleway text-[#8A8A8A]  text-xs text-center my-2 ml-auto mx-4'>Last updated {lastUpdatedDate}</span>
              )
          }
   
          <div className='flex w-11/12 mx-auto bg-[#013847] text-center justify-center h-[180px]  rounded-xl text-[#fff] '>
            <img alt="howto"  className={`rounded-xl w-11/12 h-[180px] absolute opacity-40 object-cover`} src={resourceBgImage} />
            <div className='mt-14 h-[180px]'>
              <p className='text-white font-raleway text-[23px] font-extrabold  mx-4'>{capitalizeString(resourceTitle)}</p>
              <p className=' text-white text-[14px] font-open-sans mx-4 font-bold'>{resourceDescription}</p>
            </div>
          </div>
    
          {
            (currentFilter?.state) ? (
              <p className='text-black w-full text-[12px] text-center mx-auto my-4'>
                Showing <b className='font-bold text-primaryDark-0'>{resourceType}</b> results for <b className='font-bold text-primaryDark-0'>{currentFilter.county ? `${capitalizeString(currentFilter.county)}${currentFilter.state === 'DC' ? '' : ' County'}, ` : '' }{USStatesEnum[currentFilter.state].fullName}</b>
              </p>
            ) : (
              <p className='text-black w-full text-[12px] text-center mx-auto my-4'>
                Showing all <b className='font-bold text-primaryDark-0'>{resourceType}</b> results
              </p>
            )
          }

          {
            (numResources && numAllResources) ? (
              // <div className='flex mx-4 w-[360px]'>
                <div className='flex flex-row w-full text-[#013847] font-raleway text-[14px] font-bold my-auto'>

                  <div className='flex flex-row justify-center my-auto w-1/4' onClick={() => setCurrentResourcePage(prev => (prev > 1) ? prev - 1 : prev)}>
                      <span className={`text-[14px] my-auto ${(currentResourcePage > 1) ? 'visible cursor-pointer':'invisible'}`}><ArrowBackIosIcon style={{fontSize: 10}}  className='my-auto' />&nbsp;Previous</span>
                  </div>

                  <span className='flex flex-row justify-center text-[#8A8A8A] text-[14px] font-light text-center my-auto w-1/2 mx-auto'>
                    {`Showing ${startIndex+1} - ${endIndex} of ${numAllResources}`}
                  </span>

                  <div className='my-auto w-1/4 flex flex-row justify-center' onClick={() => setCurrentResourcePage(prev => (prev+1 <= Math.ceil(numAllResources/resourcesPerPage) ) ? prev+1 : prev)}>
                      <span className={`text-[14px] my-auto ${(currentResourcePage+1 <= Math.ceil(numAllResources/resourcesPerPage)) ? 'visible cursor-pointer' : 'invisible'}`}>Next&nbsp;<ArrowForwardIosIcon style={{fontSize: 10}} className='my-auto' /></span>
                  </div>

                </div>
            
            ) : (<></>)
          }

          <div className="flex flex-col mx-auto sm:h-screen  rounded-xl">
              {
                (
                  paginatedResources && paginatedResources.length > 0) ? paginatedResources.map((resource: ResourceSchema, i: number) => (
                    <ResourceCard 
                      isMobile={isMobile} 
                      key={i} 
                      resourceType={resourceType} 
                      resourceIcon={ResourceIcon} 
                      resource={resource} 
                      searchFilterOptions={searchFilterOptions} 
                      toggleApplyModal={toggleApplyModal}
                      setApplyResource={setApplyResource}
                    />
                  )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No {resourceType} to show
                  </div>
                )
              }
          </div>
        </div>
      </div>

      <RequestKitchenModal isOpen={!!loggedin_username && applyModal==="kitchen"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
      <RegisterTrainingModal isOpen={!!loggedin_username && applyModal==="training"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ObtainDocumentationModal isOpen={!!loggedin_username && applyModal==="documentation"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ApplyFundingModal isOpen={!!loggedin_username && applyModal==="funding"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ScheduleExpertModal isOpen={!!loggedin_username && applyModal==="expert"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <OrderInventoryModal isOpen={!!loggedin_username && applyModal==="farm"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal}  />
      </>
    )
  } else {
    return (
      <>
      {
        (filterSetting) && (
          <div className={`flex font-open-sans h-screen w-11/12 sm:w-3/12 mr-auto overflow-y-scroll fixed top-0 bottom-0 justify-center from-zinc-200 pb-6 pt-8 backdrop-blur-2xl bg-white/90`}>
            <FilterOptions currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} closeFilterSetting={() => setFilterSetting(false)} />
          </div>)
      }
      
      <div className={`${(filterSetting) ? 'w-1/12 sm:w-9/12 right-0' : 'w-full left-0'} flex font-open-sans h-screen overflow-y-scroll fixed top-0 justify-center bg-gradient-to-b bg-[#FFFFFF] pb-6 pt-8 backdrop-blur-2xl`}>
        {/* <img alt="howto"  className={`w-full ${(numResources <= 5) ? 'sm:h-[1048px] xs:h-[2048px] h-[1480px]' : `sm:h-[1480px]`}  object-cover absolute opacity-40`} src={bg_img} /> */}
        
        <div className={`flex flex-col w-full h-full relative items-start py-4 sm:py-6 ${(filterSetting) ? 'invisible sm:visible' : ''}`}>
          <span className="text-[#8A8A8A] hover:text-[#5DB6CE] ml-[10px] sm:ml-[250px] mt-6 cursor-pointer text-[14px] font-bold" onClick={()=> navigate('/')}>
            <ArrowBackIosIcon  />  Go Back
          </span>
  
          {
              lastUpdatedDate && (
                <span className=' font-raleway text-[#8A8A8A]  text-xs text-center lg:ml-[1300px] sm:ml-[1280px] ml-[250px] my-2 sm-my-0'>Last updated {lastUpdatedDate}</span>
              )
          }
   
          <div className=' bg-[#013847]/95 text-center justify-center w-[361px] sm:w-[1200px] h-[155px] sm:h-[200px] sm:mb-[80px] rounded-xl sm:text-xl text-[#fff] sm:my-4 mx-auto'>
            {/* <img alt="howto"  className={`rounded-xl w-[1200px] ${(numResources <= 5) ? 'sm:h-[348px] xs:h-[348px] h-[348px]' : `sm:h-[55px]`}  object-cover absolute opacity-40`} src={resourceBgImage} /> */}
        
            <img alt="howto"  className={`rounded-xl w-[361px] sm:w-[1200px] sm:h-[200px] h-[155px] absolute opacity-40 object-cover`} src={resourceBgImage} />
            <div className='mt-14 w-[1200px] sm:h-[200px] h-[155px]'>
              <p className='my-4 font-raleway text-[35px] font-extrabold sm:m-4 '>{capitalizeString(resourceTitle)}</p>
              <p className='my-4 text-white text-[15px] font-open-sans sm:m-4'>{resourceDescription}</p>
            </div>
  
            
            
          </div>
    
          {(currentFilter?.state) ? (<p className='text-[#013847] w-full text-xs sm:text-[14px] text-center mx-auto sm:mb-4'>Showing <b className='font-bold'>{resourceType}</b> results for <b className='font-bold'>{currentFilter.county ? `${capitalizeString(currentFilter.county)}${currentFilter.state === 'DC' ? '' : ' County'}, ` : '' }{USStatesEnum[currentFilter.state].fullName}</b></p>) : (<p className='text-primaryDark-300 w-full text-xs sm:text-sm text-center mx-auto my-4'>Showing all <b className='font-bold'>{resourceType}</b> results</p>)}
  
          {
            (numResources && numAllResources) ? (
              <div className='flex flex-row w-full justify-start ml-auto'>
                <span className=' text-[#8A8A8A] sm:w-5/12 text-xs text-center sm:mb-4 my-auto ml-2'>
                  {`Showing ${startIndex+1} - ${endIndex} of ${numAllResources}`}
                </span>
                <div className='grid grid-cols-4 sm:grid-cols-4 text-[#013847] font-raleway text-[16px] font-bold text-xs sm:mb-4 my-auto ml-[660px]'>
                  <div className='mr-1 my-auto col-span-2 sm:col-span-1' onClick={() => setCurrentResourcePage(prev => (prev > 1) ? prev - 1 : prev)}>
                    
                      <span className={`text-[12px] mx-auto my-auto ${(currentResourcePage > 1) ? 'visible cursor-pointer':'invisible'}`}><ArrowBackIosIcon style={{fontSize: 10}}  className='my-auto' />&nbsp;Previous</span>
                    
                  </div>
                  <div className='my-auto ml-1 col-span-2 sm:col-span-1' onClick={() => setCurrentResourcePage(prev => (prev+1 <= Math.ceil(numAllResources/resourcesPerPage) ) ? prev+1 : prev)}>
                    
                      <span className={`text-[12px] mx-auto my-auto ${(currentResourcePage+1 <= Math.ceil(numAllResources/resourcesPerPage)) ? 'visible cursor-pointer' : 'invisible'}`}>Next&nbsp;<ArrowForwardIosIcon style={{fontSize: 10}} className='my-auto' /></span>
                    
                  </div>
                </div>
              </div>
            ) : (<></>)
          }
          <div className="flex flex-col mx-auto sm:h-screen sm:w-[1200px] bg-[#F5F5F5] rounded-xl">
              {
                (
                  paginatedResources && paginatedResources.length > 0) ? paginatedResources.map((resource: ResourceSchema, i: number) => (
                    <ResourceCard 
                      key={i} 
                      resourceType={resourceType} 
                      resourceIcon={ResourceIcon} 
                      resource={resource} 
                      searchFilterOptions={searchFilterOptions} 
                      toggleApplyModal={toggleApplyModal}
                      setApplyResource={setApplyResource}
                    />
                  )
                ) : (
                  <div className="flex flex-row justify-evenly font-light text-center text-[#013847]/60 text-xs sm:text-sm">
                    No {resourceType} to show
                  </div>
                )
              }
          </div>
        </div>
      </div>

      <RequestKitchenModal isOpen={!!loggedin_username && applyModal==="kitchen"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={()=>toggleApplyModal(undefined)} />
      <RegisterTrainingModal isOpen={!!loggedin_username && applyModal==="training"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ObtainDocumentationModal isOpen={!!loggedin_username && applyModal==="documentation"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ApplyFundingModal isOpen={!!loggedin_username && applyModal==="funding"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <ScheduleExpertModal isOpen={!!loggedin_username && applyModal==="expert"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal} />
      <OrderInventoryModal isOpen={!!loggedin_username && applyModal==="farm"} resourceDetail={applyResource} handleSubmitRequest={handleSubmitRequest} closeAppModal={closeAppModal}  />

      </>
    )
  }


}


export default ResourceListing