// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';



import { SettingsOptionsType } from '../data/types';
import { useEffect, useState } from 'react';

// import bg_img from '../assets/img/bg_img.png'

// type  OptionType = {
//   name: string;
//   icon: React.FC;
//   path: string
// }

type ResourcesGridProps = {
  resourcesOptions: SettingsOptionsType[]
}


const ResourcesGrid:React.FC<ResourcesGridProps> = ({resourcesOptions}) => {
   // className='flex flex-col bg-[#5DB6CE]  hover:bg-[#5DB6CE]/85  text-[#013847] sm:text-2xl rounded-md sm:h-[180px] sm:w-[360px] h-[150px] w-[300px] m-5 justify-evenly items-center text-center cursor-pointer hover:scale-95 duration-1000 bg-gradient-to-a from-zinc-200 '>
   const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMD = width < 1400 && width > 950;
  // console.log(width,isMD);
  
  return (
        <div className={`grid  ${isMD? "grid-cols-2":"grid-cols-1 lg:grid-cols-3 "} grid-flow-dense w-full mx-auto`}>
          {
            resourcesOptions.map((resource, i) => (
              <ResourceOptionCard key={i} resource={resource} />
            ))
          }
        </div>
    )
}


type ResourceOptionCardProps = {
  resource: SettingsOptionsType
}

const ResourceOptionCard:React.FC<ResourceOptionCardProps> = ({resource}) => {
  const navigate = useNavigate();

  const { name, description, path, icon: Icon } = resource
  return (
            <div 
              onClick={() => navigate(`/${path}`)}
              className='flex flex-col bg-white hover:bg-zinc-100 border-[#E9E9E9] border-[1px] text-[#013847] sm:text-2xl rounded-md sm:h-[331px] sm:w-[360px] h-[313px] w-[305px] m-5 justify-evenly items-center cursor-pointer hover:scale-95 duration-1000 bg-gradient-to-a from-zinc-200 '>
              
                <div className="sm:mr-auto ml-10 border-[#E9E9E9] border-[1px] h-[40px] w-[40px] sm:h-[48px] sm:w-[48px] rounded-md mt-[12px]">
                  <Icon className="m-auto h-[24px] w-[24px] sm:w-[32px] sm:h-[32px] my-[8px]" />
                </div>
                <p className='w-10/12 mx-10 text-[20px] sm:text-[24px] mt-[29px] font-raleway font-extrabold '>{name}</p>
                <p className='mx-[24px] sm:mx-[30px] my-[27px] text-[14px] sm:text-[16px] text-[#1A1A1A] font-open-sans'>{description}</p>
                <button className='bg-primaryDark-200 text-white rounded-md p-2 sm:h-[50px] sm:w-[309px] w-[265px] mb-10 text-[16px] font-bold'>View More</button>
            </div>
       
  )
}

export default ResourcesGrid