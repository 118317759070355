import React, { useState } from "react";
import messages from "../icons/bxs_chat.svg";
import { SearchFilterOptionType } from "../data/types";
import { MessageModal } from "../molecules/AppModal";


type MessagesProps = {
  username: string
  searchFilterOptions: SearchFilterOptionType
}

const Messages: React.FC<MessagesProps> = ({username, searchFilterOptions}) => {
  const {setSignInModalIsOpen } = searchFilterOptions
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      {!isOpen ? (
        <div className=" border-0 fixed bottom-0 right-0 m-5 md:m-10 ">
          <button 
            className="flex flex-row z-30 w-[270px] h-[74px] hover:cursor-pointer border-0 shadow-3xl  bg-white rounded-[16px] " 
            onClick={() => {
              if (username){
                setIsOpen(true)
              } else {
                setSignInModalIsOpen(true)
              }
            }}
          >
            <img
              src={messages}
              alt="Kitchen Logo"
              className="w-22 h-12 p-2 my-auto cursor-pointer bg-primaryDark-0 rounded-[16px]"
            />
            <div className="flex flex-col text-start pl-1 pt-2">
              <div className="text-primaryDark-0 font-bold">Need Help?</div>
              <div className=" italic text-text-300">
                Tap here to chat with us!
              </div>
            </div>
          </button>
        </div>
      ) : (
        <div className=" border-0 fixed bottom-0 right-0 m-5 md:m-10 ">
          <button className="flex flex-row z-30 w-[270px] h-[74px] hover:cursor-pointer border-0 shadow-3xl  bg-white rounded-[16px] " onClick={() => setIsOpen(false)}>
            <img
              src={messages}
              alt="Kitchen Logo"
              className="w-22 h-12 p-2 my-auto cursor-pointer bg-primaryDark-0 rounded-[16px]"
            />
            <div className="flex flex-col text-start pl-1 pt-2 hover:cursor-pointer" >
              <div className="text-primaryDark-0 font-bold">Close</div>
              <div className=" italic text-text-300">
                Not what you needed?
              </div>
            </div>
          </button>
        </div>
      )}
      <MessageModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Messages;
